import React from "react";
import { useContext } from "react";
import Context from "../../Context";
import "./ContactFooter.css";
const ContactFooter = (props) => {
  const Language = useContext(Context);
  return (
    <div id="contact-footer-container">
      <h2
        data-aos="fade-right"
        className={Language == 1 ? "title hebrew" : "title"}
      >
        {props.title}
      </h2>

      <div
        className={Language == 1 ? "form-container hebrew" : "form-container"}
      >
        <div className="submit-div" data-aos="fade-up" data-aos-delay="100">
          <button
            onClick={() => {
              props.btn1Action();
            }}
            className="submit-btn"
          >
            {props.btn1}
          </button>
        </div>
        <div className="submit-div" data-aos="fade-up" data-aos-delay="200">
          <button
            onClick={() => {
              props.btn2Action();
            }}
            className="submit-btn"
          >
            {props.btn2}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ContactFooter;
