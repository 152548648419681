import React from "react";
import { useContext } from "react";
import Context from "../../../Context";
import "./GraphologyInstituteDescription.css";
const GraphologyInstituteDescription = () => {
  const Language = useContext(Context);
  return (
    <div data-aos="fade-up" id="institute-description-container">
      {Language == 1 ? (
        <p className="content hebrew">
          המכון לגרפולוגיה על שם אילן בן יוסף הוא אחד ממכוני הגרפולוגיה הטובים
          בעולם, ממוקם קרוב למרכז תל אביב, ישראל. המכון מספק מגוון שירותים
          ללקוחות פרטיים ועסקיים, כגון יעוץ, הכוונה, הרצאות ומופעים
        </p>
      ) : (
        <p className="content">
          The Graphology Institute of Eilon Ben Yosef is one of the top
          graphology institues worldwide, located close to the center of Tel
          Aviv, Israel. The Graphology Institute provides services for private
          and business clients; Consultation, guidance, lectures and shows
        </p>
      )}
    </div>
  );
};

export default GraphologyInstituteDescription;
