import React from "react";
import { useContext } from "react";
import Context from "../../../Context";
import "./ProjectsHP.css";

import Image from "react-image-webp";
import { isWebpSupported } from "react-image-webp/dist/utils";

import paperTextureWebp from "../../../media/webp/paper-wall-texture.webp";
import cafeMaktubLogoWebp from "../../../media/webp/HomePage/cafemactub.webp";
import writtenInHeavenLogoWebp from "../../../media/webp/HomePage/written-in-heaven-logo.webp";
import maktubTvLogoWebp from "../../../media/webp/HomePage/maktub-tv-logo.webp";

import paperTexture from "../../../media/img/paper-wall-texture.jpg";
import cafeMaktubLogo from "../../../media/img/HomePage/cafemactub.png";
import writtenInHeavenLogo from "../../../media/img/HomePage/written-in-heaven-logo.png";
import maktubTvLogo from "../../../media/img/HomePage/maktub-tv-logo.png";

import AnalyzeBanner from "../AnalyzeBanner/AnalyzeBanner";

const ProjectsHP = () => {
  const Language = useContext(Context);

  return (
    <div
      id="hp-projects-container"
      style={{
        backgroundImage: `linear-gradient(rgba(255,255,255,0.6), rgba(255,255,255,0.6)),url(${
          isWebpSupported() ? paperTextureWebp : paperTexture
        })`,
      }}
    >
      {Language == 1 ? (
        <h2 className="title hebrew">פרוייקטים</h2>
      ) : (
        <h2 className="title">Projects</h2>
      )}

      <div className="projects-imgs-container">
        <div
          onClick={() => {
            window.location.href = "/mactub-cafe";
          }}
          data-aos="fade-up"
          data-aos-delay="0"
          className="img-text-container"
        >
          <Image
            src={cafeMaktubLogo}
            webp={cafeMaktubLogoWebp}
            alt="Image with the logo of Cafe Maktub - a cup of coffee."
          ></Image>
          {Language == 1 ? <h4>קפה מכתוב</h4> : <h4>Mactub Café</h4>}
        </div>
        <div
          onClick={() => {
            window.location.href = "/written-from-heaven";
          }}
          data-aos="fade-up"
          data-aos-delay="100"
          className="img-text-container"
        >
          <Image
            alt="Image with our written in Heaven logo , text and embelishments."
            src={writtenInHeavenLogo}
            webp={writtenInHeavenLogoWebp}
          ></Image>
          {Language == 1 ? <h4>כתובה משמיים</h4> : <h4>Written From Heaven</h4>}
        </div>
        <div
          onClick={() => {
            window.location.href = "/mactub-tv";
          }}
          data-aos="fade-up"
          data-aos-delay="200"
          className="img-text-container"
        >
          <Image
            src={maktubTvLogo}
            webp={maktubTvLogoWebp}
            alt="Image of square with the writing Maktub TV on it with a background of youtube."
          ></Image>
          {Language == 1 ? <h4>TV מכתוב</h4> : <h4>Mactub TV</h4>}
        </div>
      </div>
      <AnalyzeBanner />
    </div>
  );
};

export default ProjectsHP;
