import React, { useContext } from "react";
import Context from "../../../Context";
import "./InstituteOfGraphologyHP.css";

import InstituteCarousel from "../../InstituteCarousel/InstituteCarousel";

import { isWebpSupported } from "react-image-webp/dist/utils";

import leavesBackground from "../../../media/img/leaves-bg.jpg";
import leavesBackgroundWebp from "../../../media/webp/leaves-bg.webp";

const InstituteOfGraphologyHP = () => {
  const Language = useContext(Context);
  return (
    <div
      id="hp-institute-container"
      style={{
        backgroundImage: `linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url(${isWebpSupported() ? leavesBackgroundWebp : leavesBackground})`,
      }}
    >
      {Language == 1 ? (
        <h2 className="title hebrew">המכון לגרפולוגיה</h2>
      ) : (
        <h2 className="title">Institute of Graphology</h2>
      )}

      <InstituteCarousel />
    </div>
  );
};

export default InstituteOfGraphologyHP;
