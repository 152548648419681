import React from "react";
import "../media/css/components/header.css";

import logo from "../media/img/eilonbenyosef-new.png";
import logoHe from "../media/img/eilonbenyosef-he.png";

import logoWebp from "../media/webp/eilonbenyosef-new.webp";
import logoHeWebp from "../media/webp/eilonbenyosef-he.webp";

import IL from "../media/img/il.svg";
import US from "../media/img/us.svg";
import { useContext } from "react";
import Context from "../Context";
import TextsBank from "../TextsBank";
import Burger from "./HamburgerMenu/Burger";
import Image from "react-image-webp";

const Header = (props) => {
  var styles = {
    bmBurgerBars: {
      background: "#373a47",
    },
    bmBurgerBarsHover: {
      background: "#a90000",
    },
    bmCrossButton: {
      height: "24px",
      width: "24px",
    },
    bmCross: {
      background: "#bdc3c7",
    },
    bmMenuWrap: {
      position: "fixed",
      height: "100%",
    },
    bmMenu: {
      background: "#373a47",
      padding: "2.5em 1.5em 0",
      fontSize: "1.15em",
    },
    bmMorphShape: {
      fill: "#373a47",
    },
    bmItemList: {
      color: "#b8b7ad",
      padding: "0.8em",
    },
    bmItem: {
      display: "inline-block",
    },
    bmOverlay: {
      background: "rgba(0, 0, 0, 0.3)",
    },
  };

  function changeLanguage(num) {
    props.setLanguage(num);
    localStorage.setItem("language", num);
  }
  const Language = useContext(Context);
  const headerBot = Language == 1 ? "header-bot-he" : "header-bot";
  const dropDowns = Language == 1 ? "dropdowns-he" : "dropdowns";
  const logoLang = Language == 1 ? logoHe : logo;
  const logoWebpCheck = Language == 1 ? logoHeWebp : logoWebp;

  return (
    <div className="header">
      <div className="header-top">
        <div className="wrapper">
          <p
            className="phone"
            onClick={() => {
              window.location.href = "tel:03-624-5555";
            }}
          >
            03-624-5555
          </p>
          <nav className="socials">
            <a href="https://www.facebook.com/eilonby/">
              <i className="fab social fa-facebook"></i>
            </a>
            <a href="https://www.youtube.com/channel/UCJIBEeFEjR_DSvW43wBQmbQ">
              <i className="fab social fa-youtube"></i>
            </a>
            <a href="https://www.instagram.com/eilon.benyosef/">
              <i className="fab social fa-instagram"></i>
            </a>
            <a href="https://il.linkedin.com/in/eilon-ben-yosef">
              <i className="fab social fa-linkedin"></i>
            </a>
            <a href="https://twitter.com/eilonbenyosef">
              <i className="fab social fa-twitter"></i>
            </a>
          </nav>
        </div>
      </div>
      <div className={headerBot}>
        <Image
          onClick={() => {
            window.location.href = "/";
          }}
          className="logo"
          alt="Logo of Eilon Ben Yosef written in caligraphy."
          src={logoLang}
          webp={logoWebpCheck}
        ></Image>

        <div className={dropDowns}>
          <div className="dropdown-head">
            <span onClick={() => (window.location.href = "/college")}>
              {Language == 1 ? "המכללה לגרפולוגיה" : "College"}
            </span>
            <i className="fas fa-caret-down"></i>
            <div className="options-wrapper">
              <div
                className="dropdown-option"
                onClick={() =>
                  (window.location.href = "/college-graphology-fundementals")
                }
              >
                {Language == 1
                  ? "יסודות הגרפולוגיה"
                  : "Graphology Fundamentals"}
              </div>
              <div
                className="dropdown-option do2"
                onClick={() =>
                  (window.location.href = "/college-graphology-advanced")
                }
              >
                {Language == 1 ? "גרפולוגיה מתקדמת" : "Advanced Graphology"}
              </div>
              <div
                className="dropdown-option do3"
                onClick={() =>
                  (window.location.href = "/college-intuition-course")
                }
              >
                {Language == 1 ? "קורס אינטואיציה" : "Intuition Course"}
              </div>
              <div
                className="dropdown-option do4"
                onClick={() =>
                  (window.location.href = "/college-drawing-analysis")
                }
              >
                {Language == 1 ? "אבחון ציורים" : "Drawing Analysis"}
              </div>
            </div>
          </div>

          <div className="dropdown-head">
            <span onClick={() => (window.location.href = "/institute")}>
              {Language == 1 ? "המכון לגרפולוגיה" : "Institute"}
            </span>
            <i className="fas fa-caret-down"></i>
            <div className="options-wrapper">
              <div
                className="dropdown-option"
                onClick={() =>
                  (window.location.href = "/institute-lectures-and-shows")
                }
              >
                {Language == 1 ? "הרצאות ומופעים" : "Lectures & Shows"}
              </div>
              <div
                className="dropdown-option do2"
                onClick={() =>
                  (window.location.href = "/institute-private-audience")
                }
              >
                {Language == 1 ? "לקוחות פרטיים" : "Private Audience"}
              </div>
              <div
                className="dropdown-option do3"
                onClick={() =>
                  (window.location.href = "/institute-business-sector")
                }
              >
                {Language == 1 ? "לקוחות עסקיים" : "Business Sector"}
              </div>
            </div>
          </div>

          <div className="dropdown-head">
            <span>{TextsBank.TextsBank.header.t05[Language]}</span>
            <i className="fas fa-caret-down"></i>
            <div className="options-wrapper">
              <div
                className="dropdown-option"
                onClick={() => (window.location.href = "/mactub-cafe")}
              >
                {TextsBank.TextsBank.header.t05c01[Language]}
              </div>
              <div
                className="dropdown-option do2"
                onClick={() => (window.location.href = "/mactub-tv")}
              >
                {TextsBank.TextsBank.header.t05c02[Language]}
              </div>
              <div
                className="dropdown-option do3"
                onClick={() => (window.location.href = "/written-from-heaven")}
              >
                {TextsBank.TextsBank.header.t05c03[Language]}
              </div>
            </div>
          </div>
          <div className="dropdown-head">
            <span onClick={() => (window.location.href = "/about")}>
              {Language == 1 ? "אודות" : "About"}
            </span>
            <i className="fas fa-caret-down"></i>
            <div className="options-wrapper">
              <div
                style={{ width: "15vw" }}
                onClick={() => (window.location.href = "/about")}
                className="dropdown-option"
              >
                {Language == 1 ? "על עצמי" : "About Me"}
              </div>
              <div
                style={{ width: "15vw" }}
                onClick={() => (window.location.href = "/about-you")}
                className="dropdown-option do2"
              >
                {Language == 1 ? "על עצמכם" : "About You"}
              </div>
              <div
                style={{ width: "15vw" }}
                onClick={() => (window.location.href = "/about-reactions")}
                className="dropdown-option do3"
              >
                {Language == 1 ? "תגובות וחוויות" : "Reactions"}
              </div>
              <div
                style={{ width: "15vw" }}
                onClick={() => (window.location.href = "/contact")}
                className="dropdown-option do4"
              >
                {Language == 1 ? "צרו קשר" : "Contact Me"}
              </div>
            </div>
          </div>

          <div className="dropdowns">
            <div className="dropdown-head">
              <span>{TextsBank.TextsBank.header.t09[Language]}</span>
              <i className="fas fa-caret-down"></i>
              <div className="options-wrapper">
                <div
                  onClick={() => {
                    changeLanguage(0);
                  }}
                  style={{
                    width: "12%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  className="dropdown-option"
                >
                  <span>ENGLISH</span>
                  <img
                    className="flag-icon"
                    src={US}
                    alt="American flag icon."
                  ></img>
                </div>
                <div
                  onClick={() => {
                    changeLanguage(1);
                  }}
                  style={{
                    width: "12%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  className="dropdown-option do2"
                >
                  <span>עברית</span>{" "}
                  <img
                    className="flag-icon"
                    alt="Israeli flag icon."
                    src={IL}
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header-action-container">
          <button
            onClick={() => {
              window.location.href = "/contact";
            }}
            className="header-action"
          >
            {Language == 1 ? "לקביעת פגישה" : "Book a Meeting"}
          </button>
        </div>
        <Burger></Burger>
      </div>
    </div>
  );
};

export default Header;
