import React, { useState, useContext } from "react";
import Hamburger from "hamburger-react";
import { MenuContext } from "react-flexible-sliding-menu";
import "./Burger.css";
const Burger = () => {
  const { toggleMenu } = useContext(MenuContext);
  const [isOpen, setOpen] = useState(false);
  return (
    <div className="burger" onClick={toggleMenu} animation="push">
      <Hamburger className="burger-btn" toggled={isOpen} toggle={setOpen} />
    </div>
  );
};

export default Burger;
