import React, { useContext } from "react";
import Context from "../../../Context";
import "./FundementalPage.css";

import syllabus from "../../../media/img/Courses/fundementals/Graphology_Fundamentals_course.pdf";

import NewHero from "../../../components/NewHero/NewHero";
import ContactFooter from "../../../components/ContactFooter/ContactFooter";

import Image from "react-image-webp";
import { isWebpSupported } from "react-image-webp/dist/utils";

import heroBackground from "../../../media/img/CoursesPage/header-bg.png";
import courseImage from "../../../media/img/HomePage/graphology-basics.png";

import heroBackgroundWebp from "../../../media/webp/CoursesPage/header-bg.webp";
import courseImageWebp from "../../../media/webp/HomePage/graphology-basics.webp";

const FundementalPage = () => {
  const Language = useContext(Context);
  return (
    <>
      <div id="fundemental-course-container">
        <NewHero
          engTitle={
            Language == 1 ? "המכללה לגרפולוגיה" : "College of Graphology"
          }
          background={isWebpSupported() ? heroBackgroundWebp : heroBackground}
        />
        {Language == 1 ? (
          <h1
            data-aos="fade-up"
            className="hebrew"
            className="course-title hebrew"
          >
            קורס יסודות הגרפולוגיה
          </h1>
        ) : (
          <h1 data-aos="fade-up" className="course-title">
            Graphology Fundamentals <br /> Course
          </h1>
        )}
        <Image
          data-aos="fade-up"
          className="course-img"
          src={courseImage}
          webp={courseImageWebp}
          alt="Hand with black and white magnifying glass looking at hebrew scriptures."
        />
        {Language == 1 ? (
          <div data-aos="fade-up" className="course-text-container">
            <p className="course-text padding-bot-s hebrew">
              קורס אינטראקטיבי מרתק וייחודי בן 10 מפגשים, בני שתי יח אקדמיות,
              בהם נלמדים יסודות הגרפולוגיה ומעניק לבוגריו כלים פרקטיים בסיסיים
              לגרפולוגיה שימושית המאפשרת להם לערוך אבחון לעצמם או לאחרים על בסיס
              יומיומי
            </p>
            <p className="course-text padding-bot-s hebrew">
              בין היתר ירכשו כלים שיאפשרו להם לאבחן השותפים ,בני זוג ילדים,
              עובדים כגון
            </p>
            <ul className="course-ul hebrew">
              <li data-aos="fade-up">
                תכונות מאפיינות של הכותב כולל יושרה, נאמנות, אמינות וכיוצ"ב
              </li>
              <li data-aos="fade-up" data-aos-delay="100">
                כישורים בולטים של הכותב ואילו מהם נתונים לחיזוק ושיפור
              </li>
              <li data-aos="fade-up" data-aos-delay="200">
                מצבים בריאותיים כולל משברים נפשיים ומצבי סטרס
              </li>
            </ul>
            <p
              data-aos="fade-up"
              data-aos-delay="300"
              className="course-text padding-bot-s hebrew"
            >
              הקורסים המתקיימים במכללה לגרפולוגיה בהנחיית אילון בן יוסף, נלמדים
              בקבוצות כוללים פעילות מעשית בניתוח כתבי יד של המשתתפים וכתבי יד
              נוספים
            </p>
          </div>
        ) : (
          <div data-aos="fade-up" className="course-text-container">
            <p className="course-text padding-bot-s">
              An interactive, fascinating and unique 10-session course, two
              academic units, teaching the basics of graphology; the course
              provides its graduates with basic practical tools for applied
              graphology, allowing them to diagnose themselves or other people
              on a daily basis.
            </p>
            <p className="course-text padding-bot-s">
              Participants will acquire, among other things, tools for
              diagnosing partners, mates, children, employees, and others, for:
            </p>
            <ul className="course-ul">
              <li data-aos="fade-up">
                Characteristic features of the writer including integrity,
                loyalty, reliability, etc.
              </li>
              <li data-aos="fade-up" data-aos-delay="100">
                Prominent skills of the writer and which of them can be
                reinforced and improved
              </li>
              <li data-aos="fade-up" data-aos-delay="200">
                Health conditions, including mental crises and stressful
                situations
              </li>
            </ul>
            <p
              data-aos="fade-up"
              data-aos-delay="300"
              className="course-text padding-bot-s"
            >
              The courses take place at the Institute of Graphology under the
              guidance of Eilon Ben Yosef. <br /> <br />
              The courses are taught in groups and include practical analysis of
              the participants’ handwriting and additional handwritten pieces.
            </p>
          </div>
        )}

        <ContactFooter
          btn1Action={() => {
            window.location.href = "/contact";
          }}
          btn2Action={() => {
            window.location.href = syllabus;
          }}
          btn1={Language == 1 ? "צרו איתי קשר" : "Contact Me"}
          btn2={Language == 1 ? "להורדת הסילבוס" : "Download Syllabus"}
          title={
            Language == 1
              ? "?תרצו לשמוע עוד על קורס יסודות הגרפולוגיה"
              : "Want to hear more about Graphology Fundamentals Course?"
          }
        />
      </div>
    </>
  );
};

export default FundementalPage;
