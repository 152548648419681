import React from "react";
import Context from "../../Context";
import { useState, useContext } from "react";
import "./Burger.css";

import { Collapse } from "react-collapse";

import IL from "../../media/img/il.svg";
import US from "../../media/img/us.svg";

const BurgerMenu = (props) => {
  const Language = useContext(Context);
  const [OpenCollapse, setOpenCollapse] = useState(0);
  return (
    <div>
      <div className="burger-menu">
        <div className="burger-collapses">
          {Language == 1 ? (
            <div className="burger-menu-content first hebrew">
              <h4
                onClick={() => {
                  if (OpenCollapse != 1) {
                    setOpenCollapse(1);
                  } else {
                    setOpenCollapse(0);
                  }
                }}
                className={
                  OpenCollapse == 1
                    ? "burgerTitles burgerTitles-active hebrew"
                    : "burgerTitles burgerTitles-inactive hebrew"
                }
              >
                <a>המכללה לגרפולוגיה</a>
              </h4>
              <Collapse
                key={"abcdef"}
                isOpened={OpenCollapse == 1 ? true : false}
              >
                <div>
                  <hr className="burger-hr" />
                  <h5 className="burger-subTitles hebrew">
                    <a href="/college-graphology-fundementals">
                      יסודות הגרפולוגיה
                    </a>
                  </h5>
                  <hr className="burger-hr" />
                  <h5 className="burger-subTitles hebrew">
                    <a href="/college-graphology-advanced">גרפולוגיה מתקדמת</a>
                  </h5>
                  <hr className="burger-hr" />
                  <h5 className="burger-subTitles hebrew">
                    <a href="/college-drawing-analysis">אבחון ציורים</a>
                  </h5>
                  <hr className="burger-hr" />
                  <h5 className="burger-subTitles hebrew">
                    <a href="/college-intuition-course">קורס אינטואיציה</a>
                  </h5>
                </div>
              </Collapse>
            </div>
          ) : (
            <div className="burger-menu-content first">
              <h4
                onClick={() => {
                  if (OpenCollapse != 1) {
                    setOpenCollapse(1);
                  } else {
                    setOpenCollapse(0);
                  }
                }}
                className={
                  OpenCollapse == 1
                    ? "burgerTitles burgerTitles-active"
                    : "burgerTitles burgerTitles-inactive"
                }
              >
                <a>Graphology College</a>
              </h4>
              <Collapse
                key={"abcdef"}
                isOpened={OpenCollapse == 1 ? true : false}
              >
                <div>
                  <hr className="burger-hr" />
                  <h5 className="burger-subTitles">
                    <a href="/college-graphology-fundementals">
                      Graphology Fundamentals
                    </a>
                  </h5>
                  <hr className="burger-hr" />
                  <h5 className="burger-subTitles">
                    <a href="/college-graphology-advanced">
                      Advanced Graphology
                    </a>
                  </h5>
                  <hr className="burger-hr" />
                  <h5 className="burger-subTitles">
                    <a href="/college-drawing-analysis">Drawing Analysis</a>
                  </h5>
                  <hr className="burger-hr" />
                  <h5 className="burger-subTitles">
                    <a href="/college-intuition-course">Intuition Course</a>
                  </h5>
                </div>
              </Collapse>
            </div>
          )}

          {Language == 1 ? (
            <div className="burger-menu-content hebrew">
              <h4
                onClick={() => {
                  if (OpenCollapse != 2) {
                    setOpenCollapse(2);
                  } else {
                    setOpenCollapse(0);
                  }
                }}
                className={
                  OpenCollapse == 2
                    ? "burgerTitles burgerTitles-active hebrew"
                    : "burgerTitles burgerTitles-inactive hebrew"
                }
              >
                <a>המכון לגרפולוגיה</a>
              </h4>
              <Collapse
                key={"abcde"}
                isOpened={OpenCollapse == 2 ? true : false}
              >
                <div>
                  <hr className="burger-hr" />
                  <h5 className="burger-subTitles hebrew">
                    <a href="/institute-lectures-and-shows">הרצאות ומופעים</a>
                  </h5>
                  <hr className="burger-hr" />
                  <h5 className="burger-subTitles hebrew">
                    <a href="/institute-private-audience">לקוחות פרטיים</a>
                  </h5>
                  <hr className="burger-hr" />
                  <h5 className="burger-subTitles hebrew">
                    <a href="/institute-business-sector">לקוחות עסקיים</a>
                  </h5>
                </div>
              </Collapse>
            </div>
          ) : (
            <div className="burger-menu-content">
              <h4
                onClick={() => {
                  if (OpenCollapse != 2) {
                    setOpenCollapse(2);
                  } else {
                    setOpenCollapse(0);
                  }
                }}
                className={
                  OpenCollapse == 2
                    ? "burgerTitles burgerTitles-active"
                    : "burgerTitles burgerTitles-inactive"
                }
              >
                <a>Graphology Institute</a>
              </h4>
              <Collapse
                key={"abcde"}
                isOpened={OpenCollapse == 2 ? true : false}
              >
                <div>
                  <hr className="burger-hr" />
                  <h5 className="burger-subTitles">
                    <a href="/institute-lectures-and-shows">Lectures & Shows</a>
                  </h5>
                  <hr className="burger-hr" />
                  <h5 className="burger-subTitles">
                    <a href="/institute-private-audience">Private Audience</a>
                  </h5>
                  <hr className="burger-hr" />
                  <h5 className="burger-subTitles">
                    <a href="/institute-business-sector">Business Sector</a>
                  </h5>
                </div>
              </Collapse>
            </div>
          )}
          {Language == 1 ? (
            <div className="burger-menu-content">
              <h4
                onClick={() => {
                  if (OpenCollapse != 3) {
                    setOpenCollapse(3);
                  } else {
                    setOpenCollapse(0);
                  }
                }}
                className={
                  OpenCollapse == 3
                    ? "burgerTitles burgerTitles-active hebrew"
                    : "burgerTitles burgerTitles-inactive hebrew"
                }
              >
                <a>פרוייקטים</a>
              </h4>
              <Collapse key={"abc"} isOpened={OpenCollapse == 3 ? true : false}>
                <div>
                  <hr className="burger-hr" />
                  <h5 className="burger-subTitles hebrew">
                    <a href="/mactub-cafe">קפה מכתוב</a>
                  </h5>
                  <hr className="burger-hr" />
                  <h5 className="burger-subTitles hebrew">
                    <a href="/mactub-tv">TV מכתוב</a>
                  </h5>
                  <hr className="burger-hr" />
                  <h5 className="burger-subTitles hebrew">
                    <a href="/written-from-heaven">כתובה משמיים</a>
                  </h5>
                </div>
              </Collapse>
            </div>
          ) : (
            <div className="burger-menu-content">
              <h4
                onClick={() => {
                  if (OpenCollapse != 3) {
                    setOpenCollapse(3);
                  } else {
                    setOpenCollapse(0);
                  }
                }}
                className={
                  OpenCollapse == 3
                    ? "burgerTitles burgerTitles-active"
                    : "burgerTitles burgerTitles-inactive"
                }
              >
                <a>Projects</a>
              </h4>
              <Collapse key={"abc"} isOpened={OpenCollapse == 3 ? true : false}>
                <div>
                  <hr className="burger-hr" />
                  <h5 className="burger-subTitles">
                    <a href="/mactub-cafe">Mactub Café</a>
                  </h5>
                  <hr className="burger-hr" />
                  <h5 className="burger-subTitles">
                    <a href="/mactub-tv">Mactub TV</a>
                  </h5>
                  <hr className="burger-hr" />
                  <h5 className="burger-subTitles">
                    <a href="/written-from-heaven">Written From Heaven</a>
                  </h5>
                </div>
              </Collapse>
            </div>
          )}

          {Language == 1 ? (
            <div className="burger-menu-content hebrew">
              <h4
                onClick={() => {
                  if (OpenCollapse != 4) {
                    setOpenCollapse(4);
                  } else {
                    setOpenCollapse(0);
                  }
                }}
                className={
                  OpenCollapse == 4
                    ? "burgerTitles burgerTitles-active hebrew"
                    : "burgerTitles burgerTitles-inactive hebrew"
                }
              >
                <a>אודות</a>
              </h4>
              <Collapse
                key={"abcde"}
                isOpened={OpenCollapse == 4 ? true : false}
              >
                <div>
                  <hr className="burger-hr" />
                  <h5 className="burger-subTitles hebrew">
                    <a href="/about">על עצמי</a>
                  </h5>
                  <hr className="burger-hr" />
                  <h5 className="burger-subTitles hebrew">
                    <a href="/about-you">על עצמכם</a>
                  </h5>
                  <hr className="burger-hr" />
                  <h5 className="burger-subTitles hebrew">
                    <a href="/about-reactions">תגובות וחוויות</a>
                  </h5>
                </div>
              </Collapse>
            </div>
          ) : (
            <div className="burger-menu-content">
              <h4
                onClick={() => {
                  if (OpenCollapse != 4) {
                    setOpenCollapse(4);
                  } else {
                    setOpenCollapse(0);
                  }
                }}
                className={
                  OpenCollapse == 4
                    ? "burgerTitles burgerTitles-active"
                    : "burgerTitles burgerTitles-inactive"
                }
              >
                <a>About</a>
              </h4>
              <Collapse
                key={"abcde"}
                isOpened={OpenCollapse == 4 ? true : false}
              >
                <div>
                  <hr className="burger-hr" />
                  <h5 className="burger-subTitles">
                    <a href="/about">About Me</a>
                  </h5>
                  <hr className="burger-hr" />
                  <h5 className="burger-subTitles">
                    <a href="/about-you">About You</a>
                  </h5>
                  <hr className="burger-hr" />
                  <h5 className="burger-subTitles">
                    <a href="/about-reactions">Reactions</a>
                  </h5>
                </div>
              </Collapse>
            </div>
          )}
          {Language == 1 ? (
            <div className="burger-buttons hebrew">
              <button
                onClick={() => {
                  window.location.href = "/contact";
                }}
              >
                לקביעת פגישה
              </button>
              <button
                onClick={() => {
                  window.location.href = "/institute-lectures-and-shows";
                }}
              >
                לקביעת הרצאה
              </button>
            </div>
          ) : (
            <div className="burger-buttons">
              <button
                onClick={() => {
                  window.location.href = "/contact";
                }}
              >
                Book A Meeting
              </button>
              <button
                onClick={() => {
                  window.location.href = "/institute-lectures-and-shows";
                }}
              >
                Book A Lecture
              </button>
            </div>
          )}
        </div>
        <div>
          <div className="burger-collapses">
            <div className="burger-flags">
              <img
                alt="American flag icon."
                src={US}
                onClick={() => {
                  props.setLanguage(0);
                }}
              />
              <img
                alt="Israeli flag icon."
                src={IL}
                onClick={() => {
                  props.setLanguage(1);
                }}
              />
            </div>
            <div className="burger-socials">
              <a href="https://www.facebook.com/eilonby/">
                <i className="fab social fa-facebook"></i>
              </a>
              <a href="https://www.youtube.com/channel/UCJIBEeFEjR_DSvW43wBQmbQ">
                <i className="fab social fa-youtube"></i>
              </a>
              <a href="https://www.instagram.com/eilon.benyosef/">
                <i className="fab social fa-instagram"></i>
              </a>
              <a href="https://il.linkedin.com/in/eilon-ben-yosef">
                <i className="fab social fa-linkedin"></i>
              </a>
              <a href="https://twitter.com/eilonbenyosef">
                <i className="fab social fa-twitter"></i>
              </a>
            </div>
          </div>
          <div className="hamburger-footer">
            <div className="footer-wrapper">
              <div className="footer-wrapper-inside">
                <i
                  onClick={() => {
                    window.location.href = "tel:03-624-5555";
                  }}
                  className="fas fa-phone"
                ></i>
                <p>03-6245555</p>
              </div>
              <div className="footer-wrapper-inside">
                <i className="fas fa-envelope"></i>
                <p>eilonby@gmail.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BurgerMenu;
