import React from "react";
import { useState, useContext, useRef } from "react";
import Context from "../../../Context";
import "./LecturesAndShowsBook.css";

import aboutPDF from "../../../media/img/LecturesAndShowsPage/about.pdf";
import offlinePDF from "../../../media/img/LecturesAndShowsPage/offline.pdf";

import Image from "react-image-webp";
import { isWebpSupported } from "react-image-webp/dist/utils";

import bg1 from "../../../media/img/LecturesAndShowsPage/notebook-bg-1.png";
import bg2 from "../../../media/img/LecturesAndShowsPage/notebook-bg-2.png";
import bg3 from "../../../media/img/LecturesAndShowsPage/notebook-bg-3.png";

import bg1Webp from "../../../media/webp/LecturesAndShowsPage/notebook-bg-1.webp";
import bg2Webp from "../../../media/webp/LecturesAndShowsPage/notebook-bg-2.webp";
import bg3Webp from "../../../media/webp/LecturesAndShowsPage/notebook-bg-3.webp";

const LecturesAndShowsBook = () => {
  const Language = useContext(Context);
  const [formPage, setFormPage] = useState(1);
  const [RequestedEvent, setRequestedEvent] = useState("");
  const [EventTime, setEventTime] = useState("");
  const [EventDate, setEventDate] = useState("");
  const [EventLocation, setEventLocation] = useState("");
  const [EventAudience, setEventAudience] = useState("");
  const [EventExpectations, setEventExpectations] = useState("");
  const [ClientCompany, setClientCompany] = useState("");
  const [ClientName, setClientName] = useState("");
  const [ClientPhone, setClientPhone] = useState("");
  const [ClientMail, setClientMail] = useState("");
  const [DateHovered, setDateHovered] = useState(true);
  const [TimeHovered, setTimeHovered] = useState(true);
  const [DrawingBoard, setDrawingBoard] = useState(false);
  const [Papers, setPapers] = useState(false);
  function NextFormPage() {
    setFormPage(formPage + 1);
  }
  function requiredEquipment() {
    let arr = [];
    if (DrawingBoard) {
      arr.push(Language == 1 ? "לוח מחיק" : "Drawing Board");
    }
    if (Papers) {
      arr.push(Language == 1 ? "דפי טיוטא ועטים" : "Papers and pens");
    }
    return arr.join();
  }

  const UtilityForm = useRef(null);
  const UtilityFormSubmit = useRef(null);
  const checkRequested =
    RequestedEvent != "Select one:" && RequestedEvent != ":בחרו אחד"
      ? RequestedEvent
      : "";
  return (
    <div id="lectures-book-container">
      <div className="utility-form">
        <form
          action="https://formsubmit.co/eilonby@gmail.com"
          method="POST"
          target="_blank"
          ref={UtilityForm}
        >
          <input
            name={Language == 1 ? "Requested_Event" : "האירוע_המבוקש"}
            value={checkRequested}
          />
          <input name={Language == 1 ? "Date" : "תאריך"} value={EventDate} />
          <input name={Language == 1 ? "Time" : "שעה"} value={EventTime} />
          <input
            name={Language == 1 ? "Location" : "מיקום"}
            value={EventLocation}
          />
          <input
            name={Language == 1 ? "Audience" : "פרטי_קהל"}
            value={EventAudience}
          />

          <input
            name={Language == 1 ? "Expectations" : "ציפיות_מהאירוע"}
            value={EventExpectations}
          />
          <input
            name={Language == 1 ? "Client_Company" : "ממסד/חברה"}
            value={ClientCompany}
          />
          <input
            name={Language == 1 ? "Client_Name" : "שם_לקוח"}
            value={ClientName}
          />
          <input
            name={Language == 1 ? "Client_Phone" : "טלפון_לקוח"}
            value={ClientPhone}
          />
          <input
            name={Language == 1 ? "Client_Mail" : "מייל_לקוח"}
            value={ClientMail}
          />
          <input
            name={Language == 1 ? "Required_Equipment" : "הציוד_הנדרש"}
            value={requiredEquipment()}
          />
          <button ref={UtilityFormSubmit} type="submit"></button>
        </form>
      </div>
      <div className="form-images-loader">
        <Image webp={bg1Webp} src={bg1} alt=""></Image>
        <Image webp={bg2Webp} src={bg2} alt=""></Image>
        <Image webp={bg3Webp} src={bg3} alt=""></Image>
      </div>
      {Language == 1 ? (
        <h2 className="sub-header hebrew">קבעו אירוע</h2>
      ) : (
        <h2 className="sub-header"> BOOK AN EVENT</h2>
      )}
      <div className="form-book-wrapper">
        <div
          className="form-container"
          style={{
            backgroundImage: `url(${isWebpSupported() ? bg1Webp : bg1})`,
            zIndex: formPage === 1 ? "1" : "0",
            display: formPage === 1 ? "block" : "none",
            filter: formPage === 1 ? "opacity(1)" : "opacity(0)",
          }}
        >
          <div className="top-btn-container">
            <button>
              <a href={aboutPDF} target="_blank">
                {Language == 1 ? "פרטים" : "About"}
              </a>
            </button>
            <button style={{ backgroundColor: "gray" }}>
              {Language == 1 ? "אונליין" : "Online"}
            </button>
            <button>
              <a href={offlinePDF} target="_blank">
                {Language == 1 ? "הדפסה" : "Offline"}
              </a>
            </button>
          </div>
          <div className={Language == 1 ? "event-form hebrew" : "event-form"}>
            {Language == 1 ? (
              <p className="form-header hebrew">:האירוע המבוקש</p>
            ) : (
              <p className="form-header">Requested Event:</p>
            )}

            {Language == 1 ? (
              <select
                class="form-select hebrew"
                aria-label="Default select example"
                onClick={(e) => {
                  setRequestedEvent(e.target.value);
                }}
              >
                <option selected disabled>
                  :בחרו אחד
                </option>
                <option value="הרצאה">הרצאה</option>
                <option value="קורס">קורס</option>
                <option value="סמינר">סמינר</option>
              </select>
            ) : (
              <select
                required
                class="form-select"
                aria-label="Default select example"
                onClick={(e) => {
                  setRequestedEvent(e.target.value);
                }}
              >
                <option selected disabled>
                  Select one:
                </option>
                <option value="Lecture">Lecture</option>
                <option value="Course">Course</option>
                <option value="Seminar">Seminar</option>
              </select>
            )}

            <div
              className={
                Language == 1
                  ? "event-time-input-container hebrew"
                  : "event-time-input-container"
              }
            >
              <input
                className="lecture-input-style"
                type={DateHovered ? "date" : "text"}
                onChange={(e) => {
                  setEventDate(e.target.value);
                }}
                placeholder={Language == 1 ? "תאריך האירוע" : "Date of Event"}
              />
              <input
                className="lecture-input-style"
                type={TimeHovered ? "time" : "text"}
                onChange={(e) => {
                  setEventDate(e.target.value);
                }}
                onChange={(e) => {
                  setEventTime(e.target.value);
                }}
                placeholder={Language == 1 ? "שעת האירוע" : "Time of Event"}
              />
            </div>
            <input
              className={
                Language == 1
                  ? "lecture-input-style input-fs input-padding hebrew"
                  : "lecture-input-style input-fs input-padding"
              }
              onChange={(e) => {
                console.log(e.target.value);
                setEventLocation(e.target.value);
              }}
              type="text"
              placeholder={Language == 1 ? "מיקום האירוע" : "Location of Event"}
            />
            <textarea
              onChange={(e) => {
                console.log(e.target.value);
                setEventAudience(e.target.value);
              }}
              rows="4"
              className={
                Language == 1
                  ? "lecture-textarea-style input-fs hebrew"
                  : "lecture-textarea-style input-fs"
              }
              placeholder={
                Language == 1
                  ? "פרטי קהל היעד"
                  : "Definition of intended audience"
              }
            ></textarea>
            <textarea
              onChange={(e) => {
                console.log(e.target.value);
                setEventExpectations(e.target.value);
              }}
              rows="4"
              className={
                Language == 1
                  ? "lecture-textarea-style input-fs hebrew"
                  : "lecture-textarea-style input-fs"
              }
              placeholder={
                Language == 1
                  ? "כתבו את ציפיותכם מהאירוע המבוקש"
                  : "Write your expectations from the requested event"
              }
            ></textarea>
          </div>
          <button
            className={
              Language == 1 ? "lecture-submit-btn hebrew" : "lecture-submit-btn"
            }
            onClick={() => {
              if (EventTime != "" && EventDate != "" && EventLocation != "") {
                NextFormPage();
              } else {
                let message =
                  Language != 1
                    ? "Please fill out the fields"
                    : "אנא מלאו את השדות";
                window.alert(message);
              }
            }}
          >
            {Language == 1 ? "הבא" : "NEXT"}
          </button>
        </div>
        <div
          className="form-container-2"
          style={{
            backgroundImage: `url(${isWebpSupported() ? bg2Webp : bg2})`,
            zIndex: formPage === 2 ? "2" : "0",
            display: formPage === 2 ? "block" : "none",
            filter: formPage === 2 ? "opacity(1)" : "opacity(0)",
          }}
        >
          <div className="top-btn-container">
            <button>
              <a href={aboutPDF} target="_blank">
                {Language == 1 ? "פרטים" : "About"}
              </a>
            </button>

            <button style={{ backgroundColor: "gray" }}>
              {Language == 1 ? "אונליין" : "Online"}
            </button>
            <button>{Language == 1 ? "הדפסה" : "Offline"}</button>
          </div>
          <div className={Language == 1 ? "event-form hebrew" : "event-form"}>
            <input
              className={
                Language == 1
                  ? "lecture-input-style input-fs input-padding hebrew"
                  : "lecture-input-style input-fs input-padding"
              }
              type="text"
              onChange={(e) => {
                setClientCompany(e.target.value);
              }}
              placeholder={
                Language == 1
                  ? "שם הממסד / חברה"
                  : "Name of Establishment / Company"
              }
            />
            <div
              className={
                Language == 1
                  ? "event-time-input-container hebrew"
                  : "event-time-input-container"
              }
            >
              <input
                onChange={(e) => {
                  setClientName(e.target.value);
                }}
                className="lecture-input-style"
                type="text"
                placeholder={Language == 1 ? "שם" : "Name"}
              />
              <input
                onChange={(e) => {
                  setClientPhone(e.target.value);
                }}
                className="lecture-input-style"
                type="number"
                placeholder={Language == 1 ? "טלפון" : "Telephone"}
              />
            </div>
            <input
              onChange={(e) => {
                setClientMail(e.target.value);
              }}
              className={
                Language == 1
                  ? "lecture-input-style input-fs input-padding hebrew"
                  : "lecture-input-style input-fs input-padding"
              }
              type="text"
              placeholder={Language == 1 ? "אימייל" : "Email"}
            />
            <hr />
            {Language == 1 ? (
              <h3 className="checkbox-title hebrew">?קיים לוח מחיק</h3>
            ) : (
              <h3 className="checkbox-title">Is there an erasable board?</h3>
            )}
            <div
              className={
                Language == 1
                  ? "checkbox-container hebrew"
                  : "checkbox-container"
              }
            >
              <input
                onClick={() => {
                  setPapers(!Papers);
                }}
                type="checkbox"
                id="papers"
              />
              <label
                onClick={() => {
                  console.log(!Papers);
                  setPapers(!Papers);
                }}
                for="papers"
              >
                {Language == 1
                  ? "יש דפי טיוטא ועטים למשתתפים"
                  : "There are draft pages and pens available for participants"}
              </label>
            </div>
            <div
              className={
                Language == 1
                  ? "checkbox-container hebrew"
                  : "checkbox-container"
              }
            >
              <input
                onClick={() => {
                  setDrawingBoard(!DrawingBoard);
                }}
                type="checkbox"
                id="board"
              />
              <label
                onClick={() => {
                  console.log(!Papers);
                  setDrawingBoard(!DrawingBoard);
                }}
                for="board"
              >
                {Language == 1 ? "יש לוח מחיק" : "There is an erasable board"}
              </label>
            </div>
            <hr />
            {Language == 1 ? (
              <p className="hebrew">תנאי תשלום: שוטף + 30*</p>
            ) : (
              <p>*Payment terms: current + 30</p>
            )}
            {Language == 1 ? (
              <p className="hebrew">
                ההזמנה תקפה רק לאחר אישור ההזמנה ע"י המכון לגרפולוגיה*
              </p>
            ) : (
              <p>
                *The booking is valid only after order confirmation by the
                Graphology Institute
              </p>
            )}
          </div>

          <button
            className={
              Language == 1 ? "lecture-submit-btn hebrew" : "lecture-submit-btn"
            }
            onClick={() => {
              console.log({
                RequestedEvent,
                EventDate,
                EventTime,
                EventLocation,
                EventAudience,
                EventExpectations,
                ClientCompany,
                ClientName,
                ClientMail,
                ClientPhone,
              });
              if (ClientName != "" && ClientPhone != "" && ClientMail != "") {
                UtilityFormSubmit.current.click();
                NextFormPage();
              } else {
                let message =
                  Language != 1
                    ? "Please fill out the fields"
                    : "אנא מלאו את השדות";
                window.alert(message);
              }
            }}
          >
            {Language == 1 ? "הבא" : "NEXT"}
          </button>
        </div>
        <div
          className="form-container-3"
          style={{
            backgroundImage: `url(${isWebpSupported() ? bg3Webp : bg3})`,
            zIndex: formPage === 3 ? "1" : "0",
            display: formPage === 3 ? "block" : "none",
            filter: formPage === 3 ? "opacity(1)" : "opacity(0)",
          }}
        >
          <div className="top-btn-container">
            <button>
              <a href={aboutPDF} target="_blank">
                {Language == 1 ? "פרטים" : "About"}
              </a>
            </button>

            <button style={{ backgroundColor: "gray" }}>
              {Language == 1 ? "אונליין" : "Online"}
            </button>
            <button>
              <a href={offlinePDF} target="_blank">
                {Language == 1 ? "הדפסה" : "Offline"}
              </a>
            </button>
          </div>
          <div
            className={
              Language == 1
                ? "event-form-final-container hebrew"
                : "event-form-final-container"
            }
          >
            {Language == 1 ? (
              <p className="final-form-header hebrew">
                תודה שמילאתם
                <br />
                !את הטופס
              </p>
            ) : (
              <p className="final-form-header">
                Thank you for <br />
                reaching out!
              </p>
            )}
            {Language == 1 ? (
              <p className="final-form-text hebrew">
                אני אצור איתכם
                <br /> ברגע שאקרא את הבקשה
              </p>
            ) : (
              <p className="final-form-text">
                I will be sure to contact <br /> you once I have a looked over
                your
                <br /> request.
              </p>
            )}
            {Language == 1 ? (
              <p className="final-form-text hebrew">!מקווים לראותכם בקרוב</p>
            ) : (
              <p className="final-form-text">Hoping to see you soon!</p>
            )}
            <div
              className={
                Language == 1
                  ? "eilon-span-container hebrew"
                  : "eilon-span-container"
              }
            >
              {Language == 1 ? (
                <span className="eilon-span hebrew">.אילון-</span>
              ) : (
                <span className="eilon-span">- Eilon.</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LecturesAndShowsBook;
