import React, { useContext } from "react";
import Context from "../../Context";

import LecturesAndShowsBook from "../../components/LecturesAndShows/LecturesAndShowsBook/LecturesAndShowsBook";
import LecturesAndShowsDescription from "../../components/LecturesAndShows/LecturesAndShowsDescription/LecturesAndShowsDescription";
import NewHero from "../../components/NewHero/NewHero";
import ContactFooter from "../../components/ContactFooter/ContactFooter";

import { isWebpSupported } from "react-image-webp/dist/utils";

import heroBackgroundWebp from "../../media/webp/LecturesAndShowsPage/header-bg.webp";
import heroBackground from "../../media/img/LecturesAndShowsPage/header-bg.png";

const LecturesAndShowsPage = () => {
  const Language = useContext(Context);
  return (
    <div id="lectures-and-shows-container">
      <NewHero
        engTitle={Language == 1 ? "הרצאות ומופעים" : "Lectures & Shows"}
        background={isWebpSupported() ? heroBackgroundWebp : heroBackground}
      />
      <LecturesAndShowsDescription />
      <LecturesAndShowsBook />
      <ContactFooter
        btn1Action={() => {
          window.href.location = "/contact#contact-me";
        }}
        btn2Action={() => {
          window.href.location = "tel:03-624-5555";
        }}
        title={Language == 1 ? "?לא בטוחים במשהו" : "Not Sure Of Something?"}
        btn1={Language == 1 ? "לשליחת אימייל" : "Email Us"}
        btn2={Language == 1 ? "התקשרו עכשיו" : "Call Me"}
      />
    </div>
  );
};

export default LecturesAndShowsPage;
