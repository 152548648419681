import React from "react";
import { useContext, useState } from "react";
import Context from "../../Context";
import "./NormalCarousel.css";

import Slider from "react-slick";

import Image from "react-image-webp";

import videoPicStephWebp from "./stephanPoster.webp";
import videoPicYaelWebp from "./yaelPoster.webp";
import videoPicGilatWebp from "./gilat.webp";
import videoPicBuzagloWebp from "./buzagloPic.webp";
import videoPicBenelWebp from "./benelVid.webp";

import videoPicSteph from "./stephanPoster.png";
import videoPicYael from "./yaelPoster.png";
import videoPicGilat from "./gilat.png";
import videoPicBuzaglo from "./buzagloPic.png";
import videoPicBenel from "./benelVid.png";

import videoStephan from "./Stephan.mp4";
import videoYael from "./Yael.mp4";
import videoGilat from "./GILAT.mp4";
import videoBuzaglo from "./BUZAGLO.mp4";
import videoBenel from "./BEN_EL.mp4";

import VideoModal from "../VideoModal/VideoModal";

function NormalCarousel() {
  const Language = useContext(Context);
  const [PlayVid, setPlayVid] = useState(-1);
  const [VideoIndex, setVideoIndex] = useState(0);
  const settings = {
    centerMode: true,
    infinite: true,
    lazyLoad: false,
    speed: 300,
    slidesToShow: 3,
    centerMode: true,
    centerPadding: 0,
    arrows: false,
    dots: false,
    focusOnSelect: true,
    autoplay: false,
    autoplaySpeed: 5000,
    beforeChange: (current, next) => {
      setVideoIndex(next);
    },
  };
  const videoReviews = [
    {
      pic: videoPicSteph,
      webp: videoPicStephWebp,
      engTitle: Language == 1 ? "סטפן לגאר" : "Stephane Legar",
      engDesc:
        Language == 1 ? "זמר, רקדן ודוגמן" : "Singer, dancer, and runway model",
      src: videoStephan,
      isMobile: false,
    },
    {
      pic: videoPicYael,
      webp: videoPicYaelWebp,
      engTitle: Language == 1 ? "יעל גולדמן" : "Yael Goldman",
      engDesc:
        Language == 1
          ? "מגישה, שחקנית ודוגמנית"
          : "Television host, actress, and model",

      src: videoYael,
      isMobile: false,
    },
    {
      pic: videoPicGilat,
      webp: videoPicGilatWebp,

      engTitle: Language == 1 ? "גילת אנקורי" : "Gilat Ankori",
      engDesc: Language == 1 ? "שחקנית" : "Actress",
      src: videoGilat,
      isMobile: false,
    },
    {
      pic: videoPicBuzaglo,
      webp: videoPicBuzagloWebp,
      engTitle: Language == 1 ? "מאור בוזגלו" : "Maor Buzaglo",
      engDesc:
        Language == 1 ? "שחקן כדורגל לשעבר" : "Former professional footballer",
      src: videoBuzaglo,
      isMobile: true,
    },
    {
      pic: videoPicBenel,
      webp: videoPicBenelWebp,
      engTitle: Language == 1 ? "בן אל תבורי" : "Ben El Tavori",
      engDesc: Language == 1 ? "זמר" : "Singer",
      src: videoBenel,
      isMobile: true,
    },
  ];
  return (
    <>
      {videoReviews.map((e, idx) => (
        <VideoModal
          engTitle={e.engTitle}
          engDesc={e.engDesc}
          close={setPlayVid}
          current={e.pic}
          currentVideo={PlayVid}
          show={PlayVid == idx ? true : false}
          src={e.src}
          isMobile={e.isMobile}
          pic={e.pic}
          webp={e.webp}
        />
      ))}
      <div data-aos="fade-up" className="NormalCarousel">
        <Slider {...settings}>
          {videoReviews.map((e, idx) => (
            <div
              key={`${e.pic}${idx}`}
              className={` ${
                idx === VideoIndex
                  ? `slide activeSlide reaction-video-thumbnail`
                  : `slide inactiveSlide reaction-video-thumbnail`
              }`}
            >
              <Image
                src={e.pic}
                webp={e.webp}
                alt={e.engTitle + " thumbnail image closeup on face"}
                style={{ position: "absoulte" }}
                className={`reaction-binder ${
                  idx === VideoIndex ? "binder-active" : ""
                }`}
              />
              <i
                className="fas fa-play-circle play-button play-button-homepage"
                onClick={() => {
                  console.log("carousel index: ", idx);
                  setPlayVid(idx);
                }}
              ></i>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
}

export default NormalCarousel;
