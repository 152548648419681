import React, { useContext } from "react";
import Context from "../../../Context";
import "./HowItAllStarted.css";

import { isWebpSupported } from "react-image-webp/dist/utils";

import paperTexture from "../../../media/img/paper-wall-texture.jpg";
import paperTextureWebp from "../../../media/webp/paper-wall-texture.webp";

const HowItAllStarted = () => {
  const Language = useContext(Context);
  return (
    <div
      id="how-it-all-started-container"
      style={{
        backgroundImage: `linear-gradient(rgba(255,255,255,0.9), rgba(255,255,255,0.9)),url(${
          isWebpSupported() ? paperTextureWebp : paperTexture
        })`,
      }}
    >
      {Language == 1 ? (
        <h2 data-aos="fade-up" className="title hebrew">
          איך הכל התחיל
        </h2>
      ) : (
        <h2 data-aos="fade-up" className="title">
          How It All Started
        </h2>
      )}

      <div
        className={
          Language == 1 ? "content-container hebrew" : "content-container"
        }
      >
        <iframe
          className="youtube-iframe-about"
          data-aos="fade-up"
          src="https://www.youtube.com/embed/Q_Wp5xHm9p0"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <div className="text-btn-container">
          {Language == 1 ? (
            <p data-aos="fade-right" className="content hebrew">
              כילד בבית הספר נהגתי לשער השערות על תכונותיהן של המורים כשכתבו על
              הלוח. מתוך האותיות והסימנים הצצתי לנבכי אישיותם ומה שמתחולל בנפשם
              ואולם, כל המידע הזה שנגלה לי היה מבחינתי שעשוע ולא יותר
              <br />
              ...עד לאותו יום
            </p>
          ) : (
            <p data-aos="fade-right" className="content">
              As a schoolboy, I used to speculate on the characters of teachers
              while they were writing on the blackboard. The letters and marks
              they were writing gave me a glimpse into the depths of their
              personalities and mindset. However, I perceived all the
              information revealed to me like a game and nothing more. One
              fateful day...
            </p>
          )}

          <div className="btn-container">
            <button
              onClick={() => {
                window.location.href = "/about";
              }}
              className="fullstory-button hebrew"
            >
              {Language == 1 ? "לסיפור המלא" : "FULL STORY"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItAllStarted;
