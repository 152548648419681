import React, { useContext } from "react";
import Context from "../../../Context";
import "./AdvancedPage.css";

import syllabus from "../../../media/img/Courses/advanced/Advanced_Graphology_course.pdf";

import NewHero from "../../../components/NewHero/NewHero";
import ContactFooter from "../../../components/ContactFooter/ContactFooter";

import Image from "react-image-webp";
import { isWebpSupported } from "react-image-webp/dist/utils";

import heroBackgroundWebp from "../../../media/webp/CoursesPage/header-bg.webp";
import courseImageWebp from "../../../media/webp/HomePage/advanced-graphology.webp";

import heroBackground from "../../../media/img/CoursesPage/header-bg.png";
import courseImage from "../../../media/img/HomePage/advanced-graphology.png";

const AdvancedPage = () => {
  const Language = useContext(Context);
  return (
    <div id="advanced-course-container">
      <NewHero
        engTitle={Language == 1 ? "המכללה לגרפולוגיה" : "College of Graphology"}
        background={isWebpSupported() ? heroBackgroundWebp : heroBackground}
      />
      {Language == 1 ? (
        <h1 data-aos="fade-up" className="course-title hebrew">
          קורס גרפולוגיה מתקדמת
        </h1>
      ) : (
        <h1 data-aos="fade-up" className="course-title">
          Graphology Advanced <br /> Course
        </h1>
      )}
      <Image
        data-aos="fade-up"
        className="course-img"
        src={courseImage}
        webp={courseImageWebp}
        alt="Flower pattles on an envelope with black cursive caligraphy writing."
      />
      {Language == 1 ? (
        <div data-aos="fade-up" className="course-text-container hebrew">
          <p className="course-text padding-bot-s">
            קורס עתיר ידע וכלים שימושיים בן 25 מפגשים של שתי יחידות אקדמיות,
            שבועיים המיועד לתלמידים מעמיקים ,המעוניינים ללמוד באופן יסודי את
            תורת הכתב ובו בעת להרחיב ברכישת כלים נוספים בין היתר
          </p>
          <ul className="course-ul hebrew">
            <li data-aos="fade-up">
              גרפולוגיה מתקדמת אבחון וניתוח מעמיק של כתבי יד וחתימות
            </li>
            <li data-aos="fade-up" data-aos-delay="100">
              אינטואיציה, פיתוח הכלי האינטואיטיבי האישי והשילוב בין גרפולוגיה
              לאינטואיציה
            </li>
            <li data-aos="fade-up" data-aos-delay="200">
              ציורים, כלים בסיסיים לניתוח ציורים, שירבוטים ושירטוטים של ילדים
              למבוגרים ככלי עזר
            </li>
            <li data-aos="fade-up" data-aos-delay="300">
              חוות דעת מקצועיות, רכישת מיומנות מקצועית לכתיבת חוות דעת
              גרפולוגיות למעסיקים
            </li>
            <li data-aos="fade-up" data-aos-delay="400">
              גרפו תרפיה, כיצד ניתן באמצעות שינוי כתב היד לטפל בבעיות הניכרות
              בכתב היד
            </li>
            <li data-aos="fade-up" data-aos-delay="500">
              גרפולוגיה משפטית, מקומה של הגרפולוגיה בעדויות בבתי המשפט כולל
              היכולת לזהות זיופים
            </li>
            <li data-aos="fade-up" data-aos-delay="600">
              בריאות הגוף והנפש אבחון מחלות ,מצוקות נפשיות, לקויות למידה והפרעות
              רגשיות ואישיות
            </li>
          </ul>
        </div>
      ) : (
        <div data-aos="fade-up" className="course-text-container">
          <p className="course-text padding-bot-s">
            This course of two weeks, 25 sessions, two academic units, provides
            extensive knowledge and useful tools. It is intended for in-depth
            students who are interested in thorough study of graphology while
            acquiring additional tools, including:
          </p>
          <ul className="course-ul">
            <li data-aos="fade-up">
              Advanced graphology, diagnosis and deep analysis of handwriting
              and signatures
            </li>
            <li data-aos="fade-up" data-aos-delay="100">
              Intuition: developing the personal intuitive tool and integrating
              graphology with intuition
            </li>
            <li data-aos="fade-up" data-aos-delay="200">
              Drawings: basic tools for analyzing drawings, scribbles and
              sketches of children and adults as aiding tools
            </li>
            <li data-aos="fade-up" data-aos-delay="300">
              Professional diagnosis: acquiring professional skills in writing
              graphological analysis to employers
            </li>
            <li data-aos="fade-up" data-aos-delay="400">
              Grapho-therapy: how problems apparent from the handwriting can be
              handled by changing the handwriting
            </li>
            <li data-aos="fade-up" data-aos-delay="500">
              Forensic graphology: the role and function of graphology in court
              testimonies, including the ability to detect forgeries
            </li>
            <li data-aos="fade-up" data-aos-delay="600">
              Physical and mental health: diagnosis of illnesses, mental
              distress, learning disabilities and emotional, and personal
              disorders
            </li>
          </ul>
        </div>
      )}
      <ContactFooter
        btn1Action={() => {
          window.location.href = "/contact";
        }}
        btn2Action={() => {
          window.location.href = syllabus;
        }}
        title={
          Language == 1
            ? "?תרצו לשמוע עוד על קורס גרפולוגיה מתקדמת"
            : "Want to hear more about Graphology Advanced Course?"
        }
        btn1={Language == 1 ? "צרו איתי קשר" : "Contact Me"}
        btn2={Language == 1 ? "להורדת הסילבוס" : "Download Syllabus"}
      />
    </div>
  );
};

export default AdvancedPage;
