import React, { useContext } from "react";
import Context from "../../Context";
import "./GraphologyInstitutePage.css";

import NewHero from "../../components/NewHero/NewHero";
import ContactFooter from "../../components/ContactFooter/ContactFooter";
import GraphologyInstituteDescription from "../../components/GraphologyInstitute/GraphologyInstituteDescription/GraphologyInstituteDescription";
import GraphologyInstituteContent from "../../components/GraphologyInstitute/GraphologyInstituteContent/GraphologyInstituteContent";

import { isWebpSupported } from "react-image-webp/dist/utils";

import heroBackgroundWebp from "../../media/webp/InstitutePage/header-bg.webp";
import heroBackground from "../../media/img/InstitutePage/header-bg.png";

const GraphologyInstitutePage = () => {
  const Language = useContext(Context);
  return (
    <div id="graphology-institute-container">
      <NewHero
        engTitle={
          Language == 1 ? "המכון לגרפולוגיה" : "Institute of Graphology"
        }
        background={isWebpSupported() ? heroBackgroundWebp : heroBackground}
      />
      <GraphologyInstituteDescription />
      <GraphologyInstituteContent />
      <ContactFooter
        btn1Action={() => {
          window.location.href = "/contact";
        }}
        btn2Action={() => {
          window.href.location = "tel:03-624-5555";
        }}
        title={
          Language == 1
            ? "?תרצו לדעת עוד על המכללה"
            : "Want to learn more about the institute?"
        }
        btn1={Language == 1 ? "צרו קשר" : "Contact Me"}
        btn2={Language == 1 ? "התקשרו עכשיו" : "Call Now"}
      ></ContactFooter>
    </div>
  );
};

export default GraphologyInstitutePage;
