import React, { useContext } from "react";
import Context from "../../../Context";
import "./BusinessSector.css";

import NewHero from "../../../components/NewHero/NewHero";
import ContactFooter from "../../../components/ContactFooter/ContactFooter";

import Image from "react-image-webp";
import { isWebpSupported } from "react-image-webp/dist/utils";

import heroBackgroundWebp from "../../../media/webp/InstitutePage/business-header.webp";
import writingPenWebp from "../../../media/webp/InstitutePage/idea.webp";
import writingPen2Webp from "../../../media/webp/InstitutePage/bulb.webp";

import heroBackground from "../../../media/img/InstitutePage/business-header.png";
import writingPen from "../../../media/img/InstitutePage/idea.png";
import writingPen2 from "../../../media/img/InstitutePage/bulb.png";

const BusinessSector = () => {
  const Language = useContext(Context);
  return (
    <div id="private-sector-container">
      <NewHero
        engTitle={Language == 1 ? "לקוחות עסקיים" : "Business Sector"}
        background={isWebpSupported() ? heroBackgroundWebp : heroBackground}
      />
      {Language == 1 ? (
        <h3 data-aos="fade-up" className="hebrew">
          גרפולוגיה ללקוחות עסקיים
        </h3>
      ) : (
        <h3 data-aos="fade-up">Graphology For Businesses</h3>
      )}
      {Language == 1 ? (
        <div>
          <h4 data-aos="fade-up" className="hebrew">
            אבחון ,ייעוץ הכוונה וליווי לאנשי עסקים
          </h4>
          <div data-aos="fade-up" className="first-container hebrew">
            <p>
              מפגש אחד או חבילת מפגשים פיזיים או וירטואליים, עם אילון בן יוסף,
              בין היתר על מנת לסייע בתהליך קבלת החלטות עסקיות נבונות. אבחון של
              מעצורים וחסימות המעכבים תהליכים עסקיים, טעימות פרקטיות להסרתם.
              פענוח האסטרטגיה של הצד השני בעסקה ועצות כיצד להתמודד איתה ועם
              השותפים לדרך. ניתוח אישיות של שותפים פוטנציאליים ומידת התאמתם
            </p>
            <Image
              alt="Macbook with DO MORE. written on it with black background, sitting on a desk next to office supplies."
              src={writingPen}
              webp={writingPenWebp}
            ></Image>
          </div>
        </div>
      ) : (
        <div>
          <h4 data-aos="fade-up">
            Diagnosis, consultation and guidance to business people
          </h4>
          <div data-aos="fade-up" className="first-container">
            <p>
              A single session or a package of physical or virtual sessions with
              Eilon Ben Yosef to assist, among other things, in the process of
              making wise business decisions. Diagnosis of barriers and
              blockages that hinder business process, and a taste practical tips
              how to remove them. Deciphering the strategy of the other party to
              a transaction, and advice how to handle it and other partners in
              the process. Personality analysis of potential partners and the
              extent of their suitability.
            </p>
            <Image
              alt="Macbook with DO MORE. written on it with black background, sitting on a desk next to office supplies."
              src={writingPen}
              webp={writingPenWebp}
            ></Image>
          </div>
        </div>
      )}
      {Language == 1 ? (
        <div>
          <h4 data-aos="fade-up" className="hebrew">
            אבחון ייעוץ והכוונה לחברות בנושא השמת כ"א והעסקת עובדים
          </h4>
          <div
            data-aos="fade-up"
            className="first-container left-container hebrew"
          >
            <p>
              התקשרות ייעודית עם המכון והמכללה, בו אילון בין היתר ימליץ או יניח
              בפני המעסיק, את הקשיים והכשלים של השותף, העובד וימליץ על העסקה או
              אי העסקה של עובדים חדשים ,כמו כן ינתח אילון את ומידת התאמתם לתפקיד
              ספציפי באמצעות ניתוח כתב ידם. ויצביע על תכונות הדרושות לתפקיד
              ומידת יכול העובד להסתגל לתפקיד לעבודה או למנהל, כמו כן סיוע למנהל
              בתוכניות רה ארגון של החברה והמלצות על איוש נכון של בעלי תפקידים,
              איתור עובדים בעייתיים והכוונה מקצועית כיצד לטפל
            </p>
            <Image
              alt="Notebooks, computer and a phone laying on white marble desk with office supplies."
              src={writingPen2}
              webp={writingPen2Webp}
            ></Image>
          </div>
        </div>
      ) : (
        <div>
          <h4 data-aos="fade-up">
            Diagnosis, consultation and guidance to companies on the placement
            of human resources and employment of employees
          </h4>
          <div data-aos="fade-up" className="first-container left-container">
            <p>
              Dedicated engagement with the Institute and the College in which
              Eilon, among other things, will indicate to the employer the
              difficulties and failures with the partner or the employee and
              recommend how to overcome them. Recommendation on the employment
              or non-employment of new employees, and examination of the extent
              of their suitability for a specific position by analyzing their
              handwriting. Indication of the characteristics and traits required
              for the position, and the extent of the employee’s ability to
              adapt to the position, the job or the manager. Assistance to
              managers in company-reorganization plans and recommendations on
              correct placement of position holders. Locating problematic
              employees and professional guidance on how to handle it.
            </p>
            <Image
              alt="Notebooks, computer and a phone laying on white marble desk with office supplies."
              src={writingPen2}
              webp={writingPen2Webp}
            ></Image>
          </div>
        </div>
      )}
      <ContactFooter
        btn1Action={() => {
          window.location.href = "/contact";
        }}
        btn2Action={() => {
          window.href.location = "tel:03-624-5555";
        }}
        title={
          Language == 1
            ? "?תרצו לקבוע איתי פגישה"
            : "Want to Schedule a meeting with me?"
        }
        btn1={Language == 1 ? "צרו איתי קשר" : "Contact Me"}
        btn2={Language == 1 ? "התקשרו עכשיו" : "Call Now"}
      ></ContactFooter>
    </div>
  );
};

export default BusinessSector;
