import React from "react";
import { useContext } from "react";
import Context from "../../Context";
import "./NewHero.css";

import Image from "react-image-webp";

import engLogoWebp from "../../media/webp/logo-eng-eby.webp";

import engLogo from "../../media/img/logo-eng-eby.png";

const NewHero = (props) => {
  const Language = useContext(Context);
  return (
    <div
      id="hero-container"
      className={Language == 1 ? "hebrew" : "english"}
      style={{
        filter: "brightness(1.1)",
        backgroundImage: `linear-gradient(
        rgba(255, 255, 255, 0.5),
        rgba(255, 255, 255, 0.5)
      ),url(${props.background})`,
      }}
    >
      <Image
        data-aos="fade-right"
        data-aos-duration="800"
        className="main-logo"
        alt="EBY letters written in caligraphy."
        webp={engLogoWebp}
        src={engLogo}
      ></Image>

      <div data-aos="fade-up" data-aos-duration="1000">
        <h1 className={Language == 1 ? "logo-title hebrew" : "logo-title"}>
          {props.engTitle.toUpperCase()}
        </h1>
      </div>
    </div>
  );
};

export default NewHero;
