import React, { useContext } from "react";
import ContactFooter from "../../components/ContactFooter/ContactFooter";
import Context from "../../Context";
import "./About.css";

import { Carousel } from "react-bootstrap";
import NewHero from "../../components/NewHero/NewHero";

import Image from "react-image-webp";
import { isWebpSupported } from "react-image-webp/dist/utils";

import heroBackground from "../../media/img/About/1Header.png";
import heroBackgroundWebp from "../../media/webp/About/1Header.webp";

import Binder1 from "../../media/img/About/Binder1_Page_01.jpg";
import Binder2 from "../../media/img/About/Binder1_Page_02.jpg";
import Binder3 from "../../media/img/About/Binder1_Page_03.jpg";
import Binder4 from "../../media/img/About/Binder1_Page_04.jpg";
import Binder5 from "../../media/img/About/Binder1_Page_05.jpg";
import Binder6 from "../../media/img/About/Binder1_Page_06.jpg";
import Binder7 from "../../media/img/About/Binder1_Page_07.jpg";
import Binder8 from "../../media/img/About/Binder1_Page_08.jpg";
import Binder9 from "../../media/img/About/Binder1_Page_09.jpg";
import Binder10 from "../../media/img/About/Binder1_Page_10.jpg";
import Binder11 from "../../media/img/About/Binder1_Page_11.jpg";
import Binder12 from "../../media/img/About/Binder1_Page_12.jpg";
import Binder13 from "../../media/img/About/Binder1_Page_13.jpg";
import Binder14 from "../../media/img/About/Binder1_Page_14.jpg";
import Binder15 from "../../media/img/About/Binder1_Page_15.jpg";
import Binder16 from "../../media/img/About/Binder1_Page_16.jpg";
import Binder17 from "../../media/img/About/Binder1_Page_17.jpg";
import Binder18 from "../../media/img/About/Binder1_Page_18.jpg";
import Binder19 from "../../media/img/About/Binder1_Page_19.jpg";
import Binder20 from "../../media/img/About/Binder1_Page_20.jpg";
import Binder22 from "../../media/img/About/Binder1_Page_22.jpg";
import Binder23 from "../../media/img/About/Binder1_Page_23.jpg";
import Binder24 from "../../media/img/About/Binder1_Page_24.jpg";
import Binder25 from "../../media/img/About/Binder1_Page_25.jpg";
import Binder26 from "../../media/img/About/Binder1_Page_26.jpg";
import Binder27 from "../../media/img/About/Binder1_Page_27.jpg";

import Binder1Webp from "../../media/webp/About/Binder1_Page_01.webp";
import Binder2Webp from "../../media/webp/About/Binder1_Page_02.webp";
import Binder3Webp from "../../media/webp/About/Binder1_Page_03.webp";
import Binder4Webp from "../../media/webp/About/Binder1_Page_04.webp";
import Binder5Webp from "../../media/webp/About/Binder1_Page_05.webp";
import Binder6Webp from "../../media/webp/About/Binder1_Page_06.webp";
import Binder7Webp from "../../media/webp/About/Binder1_Page_07.webp";
import Binder8Webp from "../../media/webp/About/Binder1_Page_08.webp";
import Binder9Webp from "../../media/webp/About/Binder1_Page_09.webp";
import Binder10Webp from "../../media/webp/About/Binder1_Page_10.webp";
import Binder11Webp from "../../media/webp/About/Binder1_Page_11.webp";
import Binder12Webp from "../../media/webp/About/Binder1_Page_12.webp";
import Binder13Webp from "../../media/webp/About/Binder1_Page_13.webp";
import Binder14Webp from "../../media/webp/About/Binder1_Page_14.webp";
import Binder15Webp from "../../media/webp/About/Binder1_Page_15.webp";
import Binder16Webp from "../../media/webp/About/Binder1_Page_16.webp";
import Binder17Webp from "../../media/webp/About/Binder1_Page_17.webp";
import Binder18Webp from "../../media/webp/About/Binder1_Page_18.webp";
import Binder19Webp from "../../media/webp/About/Binder1_Page_19.webp";
import Binder20Webp from "../../media/webp/About/Binder1_Page_20.webp";
import Binder22Webp from "../../media/webp/About/Binder1_Page_22.webp";
import Binder23Webp from "../../media/webp/About/Binder1_Page_23.webp";
import Binder24Webp from "../../media/webp/About/Binder1_Page_24.webp";
import Binder25Webp from "../../media/webp/About/Binder1_Page_25.webp";
import Binder26Webp from "../../media/webp/About/Binder1_Page_26.webp";
import Binder27Webp from "../../media/webp/About/Binder1_Page_27.webp";

import arrowIcon from "../../media/img/About/arrow.png";
import arrowIconWebp from "../../media/webp/About/arrow.webp";

const AboutYou = () => {
  const Language = useContext(Context);
  const imageAr = [
    { src: Binder1, webp: Binder1Webp },
    { src: Binder2, webp: Binder2Webp },
    { src: Binder3, webp: Binder3Webp },
    { src: Binder4, webp: Binder4Webp },
    { src: Binder5, webp: Binder5Webp },
    { src: Binder6, webp: Binder6Webp },
    { src: Binder7, webp: Binder7Webp },
    { src: Binder8, webp: Binder8Webp },
    { src: Binder9, webp: Binder9Webp },
    { src: Binder10, webp: Binder10Webp },
    { src: Binder11, webp: Binder11Webp },
    { src: Binder12, webp: Binder12Webp },
    { src: Binder13, webp: Binder13Webp },
    { src: Binder14, webp: Binder14Webp },
    { src: Binder15, webp: Binder15Webp },
    { src: Binder16, webp: Binder16Webp },
    { src: Binder17, webp: Binder17Webp },
    { src: Binder18, webp: Binder18Webp },
    { src: Binder19, webp: Binder19Webp },
    { src: Binder20, webp: Binder20Webp },
    { src: Binder22, webp: Binder22Webp },
    { src: Binder23, webp: Binder23Webp },
    { src: Binder24, webp: Binder24Webp },
    { src: Binder25, webp: Binder25Webp },
    { src: Binder26, webp: Binder26Webp },
    { src: Binder27, webp: Binder27Webp },
  ];

  return (
    <div id="about-you">
      <NewHero
        engTitle={Language == 1 ? "על עצמכם" : "About You"}
        background={isWebpSupported() ? heroBackgroundWebp : heroBackground}
      />
      {Language == 1 ? (
        <h2 className="hebrew">אבחנו את עצמכם</h2>
      ) : (
        <h2>Diagnose Yourself</h2>
      )}
      <Carousel
        nextIcon={
          <Image
            style={{ width: "5vw" }}
            src={arrowIcon}
            webp={arrowIconWebp}
            alt="Right pointing arrow"
          />
        }
        prevIcon={
          <Image
            style={{ width: "5vw", transform: `scaleX(-1)` }}
            alt="Left pointing arrow"
            src={arrowIcon}
            webp={arrowIconWebp}
          />
        }
        controls={true}
        variant={"dark"}
      >
        {imageAr.map((e) => (
          <Carousel.Item className="about-you-slide">
            <Image
              src={e.src}
              webp={e.webp}
              alt="Page with clear instructions to draw image and explanation about how to analyze yourself."
            />
          </Carousel.Item>
        ))}
      </Carousel>
      <ContactFooter
        btn1Action={() => {
          window.location.href = "/contact";
        }}
        btn2Action={() => {
          window.href.location = "tel:03-624-5555";
        }}
        title={Language == 1 ? "?לא בטוחים במשהו" : "Not sure of something?"}
        btn1={Language == 1 ? "צרו איתי קשר" : "Contact Me"}
        btn2={Language == 1 ? "התקשרו עכשיו" : "Call Now"}
      />
    </div>
  );
};

export default AboutYou;
