import React from "react";
import "./AnalyzeBanner.css";
import Context from "../../../Context";
import { useContext } from "react";
const AnalyzeBanner = () => {
  const Language = useContext(Context);

  return (
    <div id="analyze-banner-container">
      {Language == 1 ? (
        <h2 data-aos="fade-right" className="title hebrew">
          אבחנו את עצמכם דרך <br /> כתב היד שלכם
        </h2>
      ) : (
        <h2 data-aos="fade-right" className="title">
          ANALYZE YOUR LIFE THROUGH YOUR <br /> OWN HANDWRITING
        </h2>
      )}
      {Language == 1 ? (
        <h3 data-aos="fade-right" className="sub-title">
          ?לא יודעים איפה מתחילים
        </h3>
      ) : (
        <h3 data-aos="fade-right" className="sub-title">
          Dont Know Where To Start?
        </h3>
      )}

      <form
        action="https://formsubmit.co/eilonby@gmail.com"
        method="POST"
        target="_blank"
        className={Language == 1 ? "form-container hebrew" : "form-container"}
      >
        <input
          data-aos="fade-up"
          data-aos-delay="0"
          type="text"
          placeholder={Language == 1 ? "שם מלא" : "Full Name"}
          name={Language == 1 ? "שם מלא" : "Full Name"}
        />
        <input
          data-aos="fade-up"
          data-aos-delay="100"
          type="email"
          placeholder={Language == 1 ? "אימייל" : "Email"}
          name={Language == 1 ? "אימייל" : "Email"}
        />
        <input
          data-aos="fade-up"
          data-aos-delay="200"
          type="number"
          name={Language == 1 ? "טלפון" : "Phone"}
          placeholder={Language == 1 ? "טלפון" : "Phone"}
        />
        <div className="submit-div" data-aos="fade-up" data-aos-delay="300">
          {Language == 1 ? (
            <button type="submit" className="submit-btn">
              שליחה
            </button>
          ) : (
            <button type="submit" className="submit-btn">
              Submit
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default AnalyzeBanner;
