import React from "react";
import "./GraphologyCollegeDescription.css";
import Context from "../../../Context";
import { useContext } from "react";
const GraphologyCollegeDescription = () => {
  const Language = useContext(Context);
  return (
    <>
      {Language == 1 ? (
        <div id="graphology-description-container">
          <p className="content hebrew">
            המכללה לגרפולוגיה על שם אילון בן יוסף היא אחת ממכללות הגרפולוגיה
            הטובות בעולם, ממש כאן בישראל. ממוקמת ליד מרכז תל אביב, המכללה
            לגרפולוגיה מכשירה סטודנטים מוצלחים שעוברים סינון בקפידה. היחידות
            האקדמיות הן בגרפולוגיה, אבחון ציורים ואינטואיציה
          </p>
        </div>
      ) : (
        <div id="graphology-description-container">
          <p className="content">
            The Graphology College of Eilon Ben Yosef is one of the top
            graphology colleges worldwide, with it’s mother-location in Israel.
            Located close to the center of Tel Aviv, the Graphology College
            qualifies students that are highly compatible and go through careful
            screening. The Academic Units in Graphology, Drawing Analysis and
            Intuition.
          </p>
        </div>
      )}
    </>
  );
};

export default GraphologyCollegeDescription;
