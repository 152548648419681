import React from "react";
import "./Cafe.css";
import { useContext } from "react";
import Context from "../../Context";
const CafeLocation = () => {
  const Language = useContext(Context);
  return (
    <div className="cafe-location-container">
      <div>
        <h2 data-aos="fade-up" style={{ textAlign: "center" }}>
          <mark className="cafe-location-title">
            {Language == 1 ? "מיקום" : "Location"}
          </mark>
        </h2>
        <div data-aos="fade-up" className="cafe-iframe">
          <iframe
            className="cafe-iframe-inside"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13524.263495318057!2d34.78566!3d32.067467!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151d4b77830b1321%3A0xd7995d7bb275c686!2sDerech%20Menachem%20Begin%2080%2C%20Tel%20Aviv-Yafo%2C%20Israel!5e0!3m2!1sen!2sus!4v1644348373233!5m2!1sen!2sus"
            width="80%"
            height="450"
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </div>
        <h2 style={{ textAlign: "center", padding: "2em" }}>
          <mark className="cafe-location-address">
            {Language == 1 ? "כתובת" : "Address"}
          </mark>
        </h2>
        {Language == 1 ? (
          <div className="address-p hebrew">
            <p data-aos="fade-up">מכללת בן יוסף</p>
            <p data-aos="fade-up" data-aos-delay="100">
              דרך מנחם בגין 80
            </p>
            <p data-aos="fade-up" data-aos-delay="200">
              תל אביב, ישראל
            </p>
          </div>
        ) : (
          <div className="address-p">
            <p data-aos="fade-up">Ben Yosef Institute</p>
            <p data-aos="fade-up" data-aos-delay="100">
              Derech Menachem Begin 80
            </p>
            <p data-aos="fade-up" data-aos-delay="200">
              Tel Aviv, Israel
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CafeLocation;
