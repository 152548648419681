import React from "react";
import { useState, useContext } from "react";
import Context from "../../Context";
import "./Reactions.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { Carousel } from "react-bootstrap";

import NewHero from "../../components/NewHero/NewHero";
import ContactFooter from "../../components/ContactFooter/ContactFooter";
import VideoModal from "../../components/VideoModal/VideoModal";

import Image from "react-image-webp";
import { isWebpSupported } from "react-image-webp/dist/utils";

import heroBackground from "../../media/img/About/header3.jpg";
import heroBackgroundWebp from "../../media/webp/About/header3.webp";

import arrowIcon from "../../media/img/About/arrow.png";
import arrowIconWebp from "../../media/webp/About/arrow.webp";

import press1Webp from "../../media/webp/Reactions/press/press1.webp";
import press2Webp from "../../media/webp/Reactions/press/press2.webp";
import press3Webp from "../../media/webp/Reactions/press/press3.webp";
import press4Webp from "../../media/webp/Reactions/press/press4.webp";
import press5Webp from "../../media/webp/Reactions/press/press5.webp";
import press6Webp from "../../media/webp/Reactions/press/press6.webp";
import press7Webp from "../../media/webp/Reactions/press/press7.webp";

import press1 from "../../media/img/Reactions/press/press1.jpg";
import press2 from "../../media/img/Reactions/press/press2.jpg";
import press3 from "../../media/img/Reactions/press/press3.jpg";
import press4 from "../../media/img/Reactions/press/press4.jpg";
import press5 from "../../media/img/Reactions/press/press5.jpg";
import press6 from "../../media/img/Reactions/press/press6.jpg";
import press7 from "../../media/img/Reactions/press/press7.jpg";

import videoPicSteph from "../../components/NormalCarousel/stephanPoster.png";
import videoPicYael from "../../components/NormalCarousel/yaelPoster.png";
import videoPicGilat from "../../components/NormalCarousel/gilat.png";
import videoPicBuzaglo from "../../components/NormalCarousel/buzagloPic.png";
import videoPicBenel from "../../components/NormalCarousel/benelVid.png";

import videoPicStephWebp from "../../components/NormalCarousel/stephanPoster.webp";
import videoPicYaelWebp from "../../components/NormalCarousel/yaelPoster.webp";
import videoPicGilatWebp from "../../components/NormalCarousel/gilat.webp";
import videoPicBuzagloWebp from "../../components/NormalCarousel/buzagloPic.webp";
import videoPicBenelWebp from "../../components/NormalCarousel/benelVid.webp";

import videoStephan from "../../components/NormalCarousel/Stephan.mp4";
import videoYael from "../../components/NormalCarousel/Yael.mp4";
import videoGilat from "../../components/NormalCarousel/GILAT.mp4";
import videoBuzaglo from "../../components/NormalCarousel/BUZAGLO.mp4";
import videoBenel from "../../components/NormalCarousel/BEN_EL.mp4";

import img1 from "../../media/img/Reactions/img/img1.jpg";
import img2 from "../../media/img/Reactions/img/img2.jpg";
import img3 from "../../media/img/Reactions/img/img3.jpg";
import img4 from "../../media/img/Reactions/img/img4.jpg";
import img5 from "../../media/img/Reactions/img/img5.jpg";
import img6 from "../../media/img/Reactions/img/img6.jpg";
import img7 from "../../media/img/Reactions/img/InstaTAGGED/img7.jpg";
import img8 from "../../media/img/Reactions/img/InstaTAGGED/img8.jpg";
import img9 from "../../media/img/Reactions/img/InstaTAGGED/img9.jpg";
import img10 from "../../media/img/Reactions/img/InstaTAGGED/img10.jpg";
import img11 from "../../media/img/Reactions/img/InstaTAGGED/img11.jpg";
import img12 from "../../media/img/Reactions/img/InstaTAGGED/img12.jpg";
import img13 from "../../media/img/Reactions/img/InstaTAGGED/img13.jpg";
import img14 from "../../media/img/Reactions/img/InstaTAGGED/img14.jpg";
import img15 from "../../media/img/Reactions/img/InstaTAGGED/img15.jpg";
import img16 from "../../media/img/Reactions/img/InstaTAGGED/img16.jpg";
import img17 from "../../media/img/Reactions/img/InstaTAGGED/img17.jpg";
import img18 from "../../media/img/Reactions/img/InstaTAGGED/img18.jpg";
import img19 from "../../media/img/Reactions/img/InstaTAGGED/img19.jpg";
import img20 from "../../media/img/Reactions/img/InstaTAGGED/img20.jpg";
import img21 from "../../media/img/Reactions/img/InstaTAGGED/img21.jpg";
import img22 from "../../media/img/Reactions/img/InstaTAGGED/img22.jpg";
import img23 from "../../media/img/Reactions/img/InstaTAGGED/img23.jpg";
import img24 from "../../media/img/Reactions/img/InstaTAGGED/img24.jpg";
import img25 from "../../media/img/Reactions/img/InstaTAGGED/img25.jpg";
import img26 from "../../media/img/Reactions/img/InstaTAGGED/img26.jpg";
import img27 from "../../media/img/Reactions/img/InstaTAGGED/img27.jpg";
import img28 from "../../media/img/Reactions/img/InstaTAGGED/img28.jpg";
import img29 from "../../media/img/Reactions/img/InstaTAGGED/img29.jpg";

import img1Webp from "../../media/webp/Reactions/img/img1.webp";
import img2Webp from "../../media/webp/Reactions/img/img2.webp";
import img3Webp from "../../media/webp/Reactions/img/img3.webp";

import img4Webp from "../../media/webp/Reactions/img/img4.webp";
import img5Webp from "../../media/webp/Reactions/img/img5.webp";
import img6Webp from "../../media/webp/Reactions/img/img6.webp";
import img7Webp from "../../media/webp/Reactions/img/InstaTAGGED/img7.webp";
import img8Webp from "../../media/webp/Reactions/img/InstaTAGGED/img8.webp";
import img9Webp from "../../media/webp/Reactions/img/InstaTAGGED/img9.webp";
import img10Webp from "../../media/webp/Reactions/img/InstaTAGGED/img10.webp";
import img11Webp from "../../media/webp/Reactions/img/InstaTAGGED/img11.webp";
import img12Webp from "../../media/webp/Reactions/img/InstaTAGGED/img12.webp";
import img13Webp from "../../media/webp/Reactions/img/InstaTAGGED/img13.webp";
import img14Webp from "../../media/webp/Reactions/img/InstaTAGGED/img14.webp";
import img15Webp from "../../media/webp/Reactions/img/InstaTAGGED/img15.webp";
import img16Webp from "../../media/webp/Reactions/img/InstaTAGGED/img16.webp";
import img17Webp from "../../media/webp/Reactions/img/InstaTAGGED/img17.webp";
import img18Webp from "../../media/webp/Reactions/img/InstaTAGGED/img18.webp";
import img19Webp from "../../media/webp/Reactions/img/InstaTAGGED/img19.webp";
import img20Webp from "../../media/webp/Reactions/img/InstaTAGGED/img20.webp";
import img21Webp from "../../media/webp/Reactions/img/InstaTAGGED/img21.webp";
import img22Webp from "../../media/webp/Reactions/img/InstaTAGGED/img22.webp";
import img23Webp from "../../media/webp/Reactions/img/InstaTAGGED/img23.webp";
import img24Webp from "../../media/webp/Reactions/img/InstaTAGGED/img24.webp";
import img25Webp from "../../media/webp/Reactions/img/InstaTAGGED/img25.webp";
import img26Webp from "../../media/webp/Reactions/img/InstaTAGGED/img26.webp";
import img27Webp from "../../media/webp/Reactions/img/InstaTAGGED/img27.webp";
import img28Webp from "../../media/webp/Reactions/img/InstaTAGGED/img28.webp";
import img29Webp from "../../media/webp/Reactions/img/InstaTAGGED/img29.webp";

const Reactions = () => {
  const Language = useContext(Context);
  const [ViewState, setViewState] = useState("Press");
  const [PlayVid, setPlayVid] = useState(-1);
  const MediaBank = () => {
    switch (ViewState) {
      case "Pictures":
        return {
          firstCarousel: [
            { pic: img1, webp: img1Webp },
            { pic: img2, webp: img2Webp },
            { pic: img3, webp: img3Webp },
          ],
          secondCarousel: [
            { pic: img1, webp: img1Webp },
            { pic: img2, webp: img2Webp },
            { pic: img3, webp: img3Webp },
            { pic: img4, webp: img4Webp },
            { pic: img5, webp: img5Webp },
            { pic: img6, webp: img6Webp },
            { pic: img7, webp: img7Webp },
            { pic: img8, webp: img8Webp },
            { pic: img9, webp: img9Webp },
            { pic: img10, webp: img10Webp },
            { pic: img11, webp: img11Webp },
            { pic: img12, webp: img12Webp },
            { pic: img13, webp: img13Webp },
            { pic: img14, webp: img14Webp },
            { pic: img15, webp: img15Webp },
            { pic: img16, webp: img16Webp },
            { pic: img17, webp: img17Webp },
            { pic: img18, webp: img18Webp },
            { pic: img19, webp: img19Webp },
            { pic: img20, webp: img20Webp },
            { pic: img21, webp: img21Webp },
            { pic: img22, webp: img22Webp },
            { pic: img23, webp: img23Webp },
            { pic: img24, webp: img24Webp },
            { pic: img25, webp: img25Webp },
            { pic: img26, webp: img26Webp },
            { pic: img27, webp: img27Webp },
            { pic: img28, webp: img28Webp },
            { pic: img29, webp: img29Webp },
          ],
        };
      case "Videos":
        return {
          firstCarousel: [
            {
              pic: videoPicSteph,
              webp: videoPicStephWebp,
              engTitle: Language == 1 ? "סטפן לגאר" : "Stephane Legar",
              engDesc:
                Language == 1
                  ? "זמר, רקדן ודוגמן"
                  : "Singer, dancer, and runway model",
              src: videoStephan,
              isMobile: false,
            },
            {
              pic: videoPicYael,
              webp: videoPicYaelWebp,
              engTitle: Language == 1 ? "יעל גולדמן" : "Yael Goldman",
              engDesc:
                Language == 1
                  ? "מגישה, שחקנית ודוגמנית"
                  : "Television host, actress, and model",
              src: videoYael,
              isMobile: false,
            },
            {
              pic: videoPicGilat,
              webp: videoPicGilatWebp,
              engTitle: Language == 1 ? "גילת אנקורי" : "Gilat Ankori",
              engDesc: Language == 1 ? "שחקנית" : "Actress",
              src: videoGilat,
              isMobile: false,
            },
            {
              pic: videoPicBuzaglo,
              webp: videoPicBuzagloWebp,
              engTitle: Language == 1 ? "מאור בוזגלו" : "Maor Buzaglo",
              engDesc:
                Language == 1
                  ? "שחקן כדורגל לשעבר"
                  : "Former professional footballer",
              src: videoBuzaglo,
              isMobile: true,
            },
            {
              pic: videoPicBenel,
              webp: videoPicBenelWebp,
              engTitle: Language == 1 ? "בן אל תבורי" : "Ben El Tavori",
              engDesc: Language == 1 ? "זמר" : "Singer",
              src: videoBenel,
              isMobile: true,
            },
          ],
          secondCarousel: [],
        };
      case "Press":
        return {
          firstCarousel: [
            { pic: press1, webp: press1Webp },
            { pic: press2, webp: press2Webp },
            { pic: press3, webp: press3Webp },
            { pic: press4, webp: press4Webp },
            { pic: press5, webp: press5Webp },
            { pic: press6, webp: press6Webp },
            { pic: press7, webp: press7Webp },
          ],
          secondCarousel: [],
        };
    }
  };
  if (ViewState == "Videos") {
    return (
      <div id="reactions-section">
        <NewHero
          engTitle={Language == 1 ? "תגובות וחוויות" : "Reactions"}
          background={isWebpSupported() ? heroBackgroundWebp : heroBackground}
        />
        {MediaBank().firstCarousel.map((e, idx) => (
          <VideoModal
            engTitle={e.engTitle}
            engDesc={e.engDesc}
            close={setPlayVid}
            current={e.pic}
            currentVideo={PlayVid}
            show={PlayVid == idx ? true : false}
            src={e.src}
            isMobile={e.isMobile}
            pic={e.pic}
          />
        ))}
        {Language == 1 ? (
          <div className="reactions-options hebrew">
            <button
              onClick={() => {
                setViewState("Pictures");
              }}
              className={ViewState == "Pictures" ? "selected" : ""}
            >
              תמונות
            </button>
            <button
              onClick={() => {
                setViewState("Videos");
              }}
              className={ViewState == "Videos" ? "selected" : ""}
            >
              סרטונים
            </button>
            <button
              onClick={() => {
                setViewState("Press");
              }}
              className={ViewState == "Press" ? "selected" : ""}
            >
              עיתונות
            </button>
          </div>
        ) : (
          <div className="reactions-options">
            <button
              onClick={() => {
                setViewState("Pictures");
              }}
              className={ViewState == "Pictures" ? "selected" : ""}
            >
              Pictures
            </button>
            <button
              onClick={() => {
                setViewState("Videos");
              }}
              className={ViewState == "Videos" ? "selected" : ""}
            >
              Videos
            </button>
            <button
              onClick={() => {
                setViewState("Press");
              }}
              className={ViewState == "Press" ? "selected" : ""}
            >
              Press
            </button>
          </div>
        )}
        <div className="carousels-container">
          <Carousel
            nextIcon={
              <Image
                style={{ width: "2vw" }}
                src={arrowIcon}
                webp={arrowIconWebp}
                alt="Right pointing arrow icon."
              />
            }
            prevIcon={
              <Image
                style={{ width: "2vw", transform: `scaleX(-1)` }}
                src={arrowIcon}
                webp={arrowIconWebp}
                alt="Left pointing arrow icon."
              />
            }
            controls={true}
            variant={"dark"}
          >
            {MediaBank().firstCarousel.map((e, idx) => (
              <Carousel.Item>
                <div className="reaction-video-thumbnail">
                  <Image
                    alt={e.engTitle + "closeup face icon image."}
                    src={e.pic}
                    webp={e.webp}
                    style={{ position: "absoulte" }}
                    className="reaction-binder"
                  />
                  <i
                    className="fas fa-play-circle play-button"
                    onClick={() => {
                      console.log("carousel index: ", idx);
                      setPlayVid(idx);
                    }}
                  ></i>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
        <ContactFooter
          btn1Action={() => {
            window.location.href = "/contact";
          }}
          btn2Action={() => {
            window.href.location = "tel:03-624-5555";
          }}
          title={
            Language == 1
              ? "?תרצו לקבוע איתי פגישה"
              : "Want to schedule a meeting with me?"
          }
          btn1={Language == 1 ? "צרו איתי קשר" : "Contact Me"}
          btn2={Language == 1 ? "התקשרו עכשיו" : "Call Now"}
        />
      </div>
    );
  } else {
    return (
      <div id="reactions-section">
        <NewHero
          engTitle={Language == 1 ? "תגובות וחוויות" : "Reactions"}
          background={isWebpSupported() ? heroBackgroundWebp : heroBackground}
        />
        {Language == 1 ? (
          <div className="reactions-options hebrew">
            <button
              onClick={() => {
                setViewState("Pictures");
              }}
              className={ViewState == "Pictures" ? "selected" : ""}
            >
              תמונות
            </button>
            <button
              onClick={() => {
                setViewState("Videos");
              }}
              className={ViewState == "Videos" ? "selected" : ""}
            >
              סרטונים
            </button>
            <button
              onClick={() => {
                setViewState("Press");
              }}
              className={ViewState == "Press" ? "selected" : ""}
            >
              עיתונות
            </button>
          </div>
        ) : (
          <div className="reactions-options">
            <button
              onClick={() => {
                setViewState("Pictures");
              }}
              className={ViewState == "Pictures" ? "selected" : ""}
            >
              Pictures
            </button>
            <button
              onClick={() => {
                setViewState("Videos");
              }}
              className={ViewState == "Videos" ? "selected" : ""}
            >
              Videos
            </button>
            <button
              onClick={() => {
                setViewState("Press");
              }}
              className={ViewState == "Press" ? "selected" : ""}
            >
              Press
            </button>
          </div>
        )}

        <div className="carousels-container">
          {ViewState == "Press" ? (
            <>
              <Carousel
                nextIcon={
                  <Image
                    style={{ width: "2vw" }}
                    alt="Right pointing arrow icon."
                    src={arrowIcon}
                    webp={arrowIconWebp}
                  />
                }
                prevIcon={
                  <Image
                    alt="Left pointing arrow icon."
                    style={{ width: "2vw", transform: `scaleX(-1)` }}
                    src={arrowIcon}
                    webp={arrowIconWebp}
                  />
                }
                controls={true}
                variant={"dark"}
              >
                {MediaBank().firstCarousel.map((e, idx) => (
                  <Carousel.Item key={("item", e, idx)}>
                    <div
                      key={("div", e, idx)}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Image
                        key={("image", e, idx)}
                        style={{ maxHeight: "800px", padding: "5vw" }}
                        alt="News page showing articles about Eilon Ben Yosef."
                        src={e.pic}
                        webp={e.webp}
                      />
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
              <hr />
            </>
          ) : (
            <></>
          )}
          {ViewState == "Press" ? (
            <></>
          ) : (
            <div className="img-gallery">
              {MediaBank().secondCarousel.map((e, idx) => (
                <Image
                  data-aos="fade-up"
                  data-aos-duration={100 * idx}
                  alt={
                    idx +
                    "closeup Eilon Ben Yosef and friends photo gallery picture."
                  }
                  src={e.pic}
                  webp={e.webp}
                />
              ))}
            </div>
          )}
        </div>
        <ContactFooter
          btn1Action={() => {
            window.location.href = "/contact";
          }}
          btn2Action={() => {
            window.href.location = "tel:03-624-5555";
          }}
          title={
            Language == 1
              ? "?תרצו לקבוע איתי פגישה"
              : "Want to schedule a meeting with me?"
          }
          btn1={Language == 1 ? "צרו איתי קשר" : "Contact Me"}
          btn2={Language == 1 ? "התקשרו עכשיו" : "Call Now"}
        />
      </div>
    );
  }
};

export default Reactions;
