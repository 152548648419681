import React from "react";
import { useContext } from "react";
import Context from "../../Context";
import "./ContactForm.css";

import Image from "react-image-webp";
import { isWebpSupported } from "react-image-webp/dist/utils";

import heroImg from "../../media/img/hero-alt.png";
import heroImgWebp from "../../media/webp/hero-alt.webp";

import paperTexture from "../../media/img/white-concrete-wall.jpg";
import paperTextureWebp from "../../media/webp/white-concrete-wall.webp";

const ContactForm = () => {
  const Language = useContext(Context);
  return (
    <div className="contact-form-container">
      <div
        id="contact-me"
        className="contact-form"
        style={{
          backgroundImage: `linear-gradient(rgba(255,255,255,0.6), rgba(255,255,255,0.6)),url(${
            isWebpSupported() ? paperTextureWebp : paperTexture
          })`,
        }}
      >
        {Language == 1 ? (
          <div className="title-container-contact">
            <h2 data-aos="fade-up" className="contact-form-title hebrew">
              השאירו פרטים ואחזור אליכם בהקדם
            </h2>
          </div>
        ) : (
          <div className="title-container-contact">
            <h2 data-aos="fade-up" className="contact-form-title">
              Leave Your Information Below, I’ll Get Back To You In Less Than 3
              Days
            </h2>
          </div>
        )}
        <div className="contact-form-content-container">
          <Image
            alt="Eilon Ben Yosef crossed hands in a buttoned shirt."
            src={heroImg}
            webp={heroImgWebp}
            className="contact-form-image"
          ></Image>
          {Language == 1 ? (
            <form
              className="contact-form-fields hebrew"
              action="https://formsubmit.co/eilonby@gmail.com"
              method="POST"
              target="_blank"
            >
              <select
                name="Subject"
                required
                data-aos="fade-right"
                class="form-select hebrew"
                aria-label="Default select example"
              >
                <option selected disabled>
                  נושא הפנייה
                </option>
                <option value="קורסים">קורסים</option>
                <option value="יעוץ פרטי">יעוץ פרטי</option>
                <option value="יעוץ עסקי">יעוץ עסקי</option>
                <option value="כתובה משמיים">כתובה משמיים</option>
                <option value="אחר">אחר</option>
              </select>
              <input
                required
                data-aos="fade-right"
                className="contact-form-input hebrew"
                placeholder="שם מלא"
                name="שם מלא"
              ></input>
              <input
                required
                data-aos="fade-right"
                data-aos-duration="600"
                className="contact-form-input hebrew"
                placeholder="אימייל"
                name="אימייל"
              ></input>
              <input
                data-aos="fade-right"
                data-aos-duration="700"
                required
                className="contact-form-input hebrew"
                placeholder="טלפון"
                name="טלפון"
              ></input>
              <textarea
                data-aos="fade-right"
                data-aos-duration="800"
                required
                className="contact-form-input contact-form-input-message hebrew"
                placeholder="הודעה"
                name="הודעה"
              ></textarea>
              <button
                type="submit"
                data-aos="fade-up"
                data-aos-duration="1000"
                className="submit-btn form-btn hebrew"
              >
                שליחה
              </button>
            </form>
          ) : (
            <form
              className="contact-form-fields"
              action="https://formsubmit.co/eilonby@gmail.com"
              method="POST"
              target="_blank"
            >
              <select
                name="Subject"
                required
                data-aos="fade-right"
                class="form-select"
                aria-label="Default select example"
              >
                <option selected disabled>
                  Request Subject
                </option>
                <option value="Courses">Courses</option>
                <option value="Private Consulting">Private Consulting</option>
                <option value="Business Consulting">Business Consulting</option>
                <option value="Written From Heaven">Written From Heaven</option>
                <option value="Other">Other</option>
              </select>
              <input
                required
                data-aos="fade-right"
                className="contact-form-input"
                placeholder="Full Name"
                name="Full Name"
              ></input>
              <input
                data-aos="fade-right"
                data-aos-duration="600"
                required
                className="contact-form-input"
                placeholder="Email"
                name="Email"
              ></input>
              <input
                required
                data-aos="fade-right"
                data-aos-duration="700"
                name="Phone"
                className="contact-form-input"
                placeholder="Phone"
              ></input>
              <textarea
                data-aos="fade-right"
                data-aos-duration="800"
                required
                name="Message"
                className="contact-form-input contact-form-input-message "
                placeholder="Message"
              ></textarea>
              <button
                data-aos="fade-up"
                data-aos-duration="1000"
                type="submit"
                className="submit-btn form-btn"
              >
                Submit
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
