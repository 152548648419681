import React, { useContext } from "react";
import Context from "../../Context";
import "./About.css";

import ContactFooter from "../../components/ContactFooter/ContactFooter";
import WhoIsEilon from "../../components/HomePage/WhoIsEilon/WhoIsEilon";
import NewHero from "../../components/NewHero/NewHero";

import Image from "react-image-webp";
import { isWebpSupported } from "react-image-webp/dist/utils";

import heroBackgroundWebp from "../../media/webp/About/header.webp";
import instituteWebp from "../../media/webp/About/institute.webp";

import heroBackground from "../../media/img/About/header.png";
import institutePNG from "../../media/img/About/institute.png";

const About = () => {
  const Language = useContext(Context);

  return (
    <section className="about-section">
      <NewHero
        engTitle={Language == 1 ? "אודות" : "About Me"}
        background={isWebpSupported() ? heroBackgroundWebp : heroBackground}
      />
      <WhoIsEilon carousel={false} />
      {Language == 1 ? (
        <h3 className="hebrew">?אז, איך הכל התחיל</h3>
      ) : (
        <h3>So, how did it all start?</h3>
      )}

      <div className="youtube-iframe-about-container">
        <iframe
          className="youtube-iframe-about"
          data-aos="fade-up"
          src="https://www.youtube.com/embed/Q_Wp5xHm9p0"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <br />
      {Language == 1 ? (
        <div className="about-p-container hebrew">
          <p data-aos="fade-up">
            כילד בבית הספר נהגתי להתעשר ההשערות על תכונותיהן של המורים כשכתבו על
            הלוח. מתוך האותיות והסימנים הצצתי לנבכי אישיותם ומה שמתחולל בנפשם
            ואולם, כל המידע הזה שנגלה לי היה מבחינתי שעשוע ולא יותר עד לאותו יום
            שאחת הבנות בכיתה הוזמנה לכתוב על הלוח ואני אבחנתי בה מחלה קשה. כילד,
            אמרתי את שראיתי ועוררתי מהומה
          </p>
          <p data-aos="fade-up">
            לצערי, צדקתי. הילדה אכן היתה חולה והלכה לעוצמה בטרם עת. מכאן הפכתי
            כמובן ליועץ המורים והחברים ואולם עדיין לא ראיתי בכך מקצוע לפחות כל
            עוד לא למדתי לעומק את נפלאות תורת כתב היד
          </p>
          <p data-aos="fade-up">
            כמתבגר ראיתי שליחות בעזרה לבני נוער ובכל עסקתי .ככל שלמדתי והעמקתי
            הבנתי שהשליחות האמיתית שלי היא לסייע לזולת להיחלץ ממשברים, להסיר
            מהמורות מדרכו, למצוא את מקומו וייעודו ולסייע לו לשפר את איכות חייו
            וחיי סביבתו
          </p>
          <p data-aos="fade-up">
            מאז אני עסוק מידי יום במשימה שהיצבתי לעצמי ואני מתבונן בגאווה ובנות
            על עשרות אלפי האנשים בהם פגשתי בדרכי המקצועית ואשר הצלחתי ולו במעט
            לסייע להם בהם אמנים, פוליטיקאים, אנשי עסקים לאנשי מפתח העשייה
            הציבורית בארץ ובעולם
          </p>
        </div>
      ) : (
        <div className="about-p-container">
          <p data-aos="fade-up">
            As a schoolboy, I used to speculate on the characters of teachers
            while they were writing on the blackboard. The letters and marks
            they were writing gave me a glimpse into the depths of their
            personalities and mindset. However, I perceived all the information
            revealed to me like a game and nothing more. One fateful day during
            class, one of my classmates was called to the blackboard to answer a
            question, and I immediately knew she had a serious illness. I
            verbalized what I had learned and caused quite a stir as a child
            that day.
          </p>
          <p data-aos="fade-up">
            Regrettably, I was right. That girl was ill and passed away
            prematurely. Naturally, since then, both teachers and friends have
            consulted with me. Yet, I did not consider it a profession, at least
            as long as I had not thoroughly studied the wonders of Graphology.
          </p>
          <p data-aos="fade-up">
            As a teenager, I felt it was just another vocation to assist young
            people. The more I studied and delved deeper into Graphology; I
            realized my true mission was to help others. With the aid of
            Graphology, I can help you get out of crises, overcome obstacles,
            find your place and vocation, improve the quality of your lives, and
            guide them in finding their purpose in terms of profession, couple
            relationship and wellbeing, essence and happiness.
          </p>
          <p data-aos="fade-up">
            Since my childhood, I have been engaged daily in the task I set for
            myself. And I watch with pride and satisfaction the hundreds of
            thousands of people I’ve met on my professional way and managed,
            even in the slightest, to assist. Among them are numerous artists,
            politicians, business people, kings, presidents, and key figures in
            public activity, both in Israel and worldwide.
          </p>
        </div>
      )}
      <div className="about-channels-container">
        <div className="about-channels-container-child">
          {Language == 1 ? (
            <h3 className="hebrew">אתם מוזמנים לערוצים שלי</h3>
          ) : (
            <h3>You're Welcome to My Channels</h3>
          )}
          <div className="channels">
            <i
              onClick={() => {
                window.location.href = "https://www.facebook.com/eilonby/";
              }}
              style={{ color: "#4267B2" }}
              className="fab social fa-facebook"
            ></i>
            <i
              onClick={() => {
                window.location.href =
                  "https://www.youtube.com/channel/UCJIBEeFEjR_DSvW43wBQmbQ";
              }}
              style={{ color: "#FF0000" }}
              className="fab social fa-youtube"
            ></i>
            <div
              onClick={() => {
                window.location.href =
                  "https://www.instagram.com/eilon.benyosef/";
              }}
              className="instagram-div social"
            >
              <i
                className="fab  fa-instagram"
                style={{ color: "white", fontSize: "4.6vw" }}
                key={"Instagram"}
              ></i>
            </div>
          </div>
        </div>
      </div>
      {Language == 1 ? (
        <h3 className="hebrew">?מהי גרפולוגיה</h3>
      ) : (
        <h3>What is Graphology?</h3>
      )}
      {Language == 1 ? (
        <div className="about-p-container hebrew">
          <p data-aos="fade-up">
            גרפולוגיה היא "תורת הכתב" שהיא הלכה למעשה ראי הנפש
          </p>
          <p data-aos="fade-up">
            בכתב היד, באותיותיו ובסימניו צפונים סודות הנפש הכי כמוסים של כל אחד
            מאתנו. אבחון מקצועי ומדוייק של הכתב חושף את מרכיבי האישיות של הכותב,
            כישוריו, יכולותיו, תכונותיו
          </p>
          <p data-aos="fade-up">
            כתב היד מלמד על מעצורים וחסימות אשר נוצרו שנים במערכת התת מודע ואשר
            הכותב לפרקים אף איננו מודע להן
          </p>
          <p data-aos="fade-up">
            ברזי כתב היד, ניתן לאבחן מעצורים ולבטים אשר תקועים בנפש האדם משחר
            ילדותו, מיחסו לחברה והאינטראקציה שהיתה לו עם הוריו, מכאן מצבים
            רגשיים נפשיים שמקורם בילדות משליכים על כאבים נפשיים ופיזיים המתאגדים
            ליידי מחלות פסיכוסומטיות וכרוניות. או כל הפרעות למיניהן כגון הפרעות
            אכילה פרנויה חרדות וכדומה
          </p>
          <p
            style={{ paddingTop: "5em", paddingBottom: "5em" }}
            data-aos="fade-up"
          >
            <b>המכון והמכללה לגרפולוגיה</b> אילון בן יוסף, הוקמה ע"י הגרפולוג
            המוערך אילון בן יוסף אשר מדהים ומפליא את לקוחותיו באבחון וניתוח
            מדוייק ובשלל עיצות פרקטיות לשיפור איכות החיים
          </p>
          <div className="about-bottom-pic">
            <Image
              alt="Pen writing blurry autograph on white paper with Eilon Ben Yosef graphollogys college name and logo."
              src={institutePNG}
              webp={instituteWebp}
            />
          </div>
        </div>
      ) : (
        <div className="about-p-container">
          <p data-aos="fade-up">
            Graphology is the “study of handwriting,” which is, in practice, the
            mirror of the soul.
          </p>
          <p data-aos="fade-up">
            The handwriting, letters, and marks hide the deepest secrets of the
            soul of each of us. A professional and accurate diagnosis of the
            script reveals the writer’s personality, skills, abilities, and
            characteristics.
          </p>
          <p data-aos="fade-up">
            The handwriting tells about inhibitions and blockages created in the
            subconscious over the years, information that the writer is
            sometimes unaware of.
          </p>
          <p data-aos="fade-up">
            In the secrets of handwriting, it is possible to diagnose hindrances
            and concerns that have weighed down the human psyche since early
            childhood, how the person treats society and the interaction with
            one’s parents. The childhood baggage has created emotional and
            mental states, radiating on psychological and physical pains, and
            sometimes expressing themselves in psychosomatic and chronic
            diseases, or any disorder such as eating disorders, paranoia,
            anxieties, etc.
          </p>
          <p
            style={{ paddingTop: "5em", paddingBottom: "5em" }}
            data-aos="fade-up"
          >
            The <b>Graphology Institute and College</b> – Eilon Ben Yosef, was
            established by the esteemed graphologist Eilon Ben Yosef, who amazes
            and astounds his clients with accurate diagnosis and analysis, and
            provides multiple practical tips to improve the quality of life.
          </p>
          <div className="about-bottom-pic">
            <Image
              alt="Pen writing blurry autograph on white paper with Eilon Ben Yosef graphollogys college name and logo."
              src={instituteWebp}
            />
          </div>
        </div>
      )}

      <ContactFooter
        btn1Action={() => {
          window.location.href = "/contact";
        }}
        btn2Action={() => {
          window.href.location = "tel:03-624-5555";
        }}
        title={
          Language == 1
            ? "?תרצו לקבוע איתי פגישה"
            : "Want to Schedule a meeting with me?"
        }
        btn1={Language == 1 ? "צרו איתי קשר" : "Contact Me"}
        btn2={Language == 1 ? "התקשרו עכשיו" : "Call Now"}
      ></ContactFooter>
    </section>
  );
};

export default About;
