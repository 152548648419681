import React from "react";
import "./InstituteCarousel.css";
import { useState } from "react";
import Slider from "react-slick";
import { Card } from "react-bootstrap";
import privateSector from "../../media/img/InstitutePage/img-private.png";
import businessSector from "../../media/img/InstitutePage/img-business.png";
import lecturesSector from "../../media/img/InstitutePage/img-lectures-and-shows.png";
import Context from "../../Context";
import { useContext } from "react";
const InstituteCarousel = () => {
  const [SlideIndex, setSlideIndex] = useState(0);
  const Language = useContext(Context);
  const slides = [
    {
      title: Language == 1 ? "הרצאות ומופעים" : "Lectures and Shows",
      href: "/institute-lectures-and-shows",
      img: lecturesSector,
    },
    {
      title: Language == 1 ? "לקוחות פרטיים" : "Private Audience",
      href: "/institute-private-audience",
      img: privateSector,
    },
    {
      title: Language == 1 ? "לקוחות עסקיים" : "Business Sector",
      href: "/institute-business-sector",
      img: businessSector,
    },
    {
      title: Language == 1 ? "הרצאות ומופעים" : "Lectures and Shows",
      href: "/institute-lectures-and-shows",
      img: lecturesSector,
    },
    {
      title: Language == 1 ? "לקוחות פרטיים" : "Private Audience",
      href: "/institute-private-audience",
      img: privateSector,
    },
    {
      title: Language == 1 ? "לקוחות עסקיים" : "Business Sector",
      href: "/institute-business-sector",
      img: businessSector,
    },
  ];
  const settings = {
    centerMode: true,
    infinite: true,
    lazyLoad: true,
    speed: 300,
    slidesToShow: 3,
    centerPadding: 0,
    variableWidth: false,
    arrows: false,
    dots: false,
    focusOnSelect: true,
    autoplay: false,
    autoplaySpeed: 5000,
    beforeChange: (current, next) => {
      setSlideIndex(next);
      console.log(current);
    },
  };

  return (
    <div data-aos="fade-up" className="InstituteCarousel">
      <Slider {...settings}>
        {slides.map((e, idx) => (
          <div
            onClick={() => {
              window.location.href = e.href;
            }}
            className={
              idx === SlideIndex
                ? "slidee active-Slide"
                : "slidee inactive-Slide"
            }
            style={{ height: "50vh !important" }}
          >
            <Card className="carousel-card">
              <Card.Img
                className="card-carousel-img"
                variant="top"
                src={e.img}
              />
              <Card.Body>
                <Card.Title className="card-carousel-title">
                  {e.title}
                </Card.Title>
              </Card.Body>
            </Card>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default InstituteCarousel;
