import React, { useContext } from "react";
import Context from "../../Context";
import "./TV.css";

import NewHero from "../../components/NewHero/NewHero";
import ContactFooter from "../../components/ContactFooter/ContactFooter";

import Image from "react-image-webp";
import { isWebpSupported } from "react-image-webp/dist/utils";

import tvWebp from "../../media/webp/TV/TV.webp";
import heroBackgroundWebp from "../../media/webp/TV/header-bg.webp";

import tv from "../../media/img/TV/TV.jpeg";
import heroBackground from "../../media/img/TV/header-bg.png";

const TV = () => {
  const Language = useContext(Context);
  return (
    <>
      <NewHero
        engTitle={Language == 1 ? "מכתוב TV" : "Mactub TV"}
        background={isWebpSupported() ? heroBackgroundWebp : heroBackground}
      />
      <section id="tvSection">
        <div data-aos="fade-up" className="tv-container">
          {Language == 1 ? (
            <h3 className="tv-title hebrew">
              ערוץ חדש מבית היוצר של אילון בן יוסף ובו תכנים רוחניים, טיפוליים
              ואיבחוניים במגוון תחומים
            </h3>
          ) : (
            <h3 className="tv-title">
              A new channel Eilon Ben Yosef with multiple Spiritual, Therapeutic
              and Diagnostic contents in a variety of areas
            </h3>
          )}
        </div>
        <div data-aos="fade-left" className="tv-container">
          <Image
            className="tv-img"
            alt="Maktub tv logo Matkub written in hebrew on black board with hand drawings on it with TV written to the left."
            src={tv}
            webp={tvWebp}
          ></Image>
          <div class="overlay">
            <div class="text">
              <a
                className="text-inside"
                href="https://www.youtube.com/channel/UCJIBEeFEjR_DSvW43wBQmbQ/featured"
              >
                {Language == 1
                  ? "לחצו כאן למעבר לערוץ"
                  : "Click here to go to the Channel"}
              </a>
            </div>
          </div>
        </div>
      </section>
      <ContactFooter
        btn1Action={() => {
          window.location.href = "/contact";
        }}
        btn2Action={() => {
          window.href.location = "tel:03-624-5555";
        }}
        title={
          Language == 1
            ? "?תרצו לקבוע איתי פגישה"
            : "Want to Schedule a meeting with me?"
        }
        btn1={Language == 1 ? "צרו איתי קשר" : "Contact Me"}
        btn2={Language == 1 ? "התקשרו עכשיו" : "Call Now"}
      />
    </>
  );
};

export default TV;
