import React, { useContext } from "react";
import Context from "../../../Context";
import "./CollegeOfGraphologyHP.css";

import Flippy, { FrontSide, BackSide } from "react-flippy";

import paperTexture from "../../../media/img/paper-wall-texture.jpg";
import paperTextureWebp from "../../../media/webp/paper-wall-texture.webp";

import { isWebpSupported } from "react-image-webp/dist/utils";

const CollegeOfGraphologyHP = () => {
  const Language = useContext(Context);
  return (
    <div
      id="hp-college-container"
      style={{
        backgroundImage: `linear-gradient(rgba(255,255,255,0.6), rgba(255,255,255,0.6)),url(${
          isWebpSupported() ? paperTextureWebp : paperTexture
        })`,
      }}
    >
      <h2
        className={
          Language == 1
            ? "college-graphology-h2 hebrew"
            : "college-graphology-h2"
        }
      >
        {Language == 1 ? "המכללה לגרפולוגיה" : "College Of Graphology"}
      </h2>
      <div className="flippy-cards-container">
        <Flippy
          data-aos="fade-up"
          isFlipped={false}
          flipOnHover={true}
          flipOnClick={false}
          flipDirection="horizontal"
        >
          <FrontSide animationDuration={1000}>
            <div className="graphology-basics-container conteiner">
              <div className="course-footer-container">
                <h3
                  className={
                    Language == 1
                      ? "course-footer-title hebrew"
                      : "course-footer-title"
                  }
                >
                  {Language == 1 ? "יסודות הגרפולוגיה" : "Graphology Basics"}
                </h3>
              </div>
            </div>
          </FrontSide>
          <BackSide animationDuration={1000}>
            <div className="graphology-basics-container-back conteiner">
              <div className="course-back-text-container">
                <h3 className="course-back-title">
                  {Language == 1
                    ? "קורס יסודות הגרפולוגיה"
                    : "Graphology Fundamentals Course"}
                </h3>
                <p
                  id="course-basics"
                  className={
                    Language == 1
                      ? "course-back-content hebrew"
                      : "course-back-content"
                  }
                >
                  {Language == 1
                    ? `קורס אינטראקטיבי מרתק וייחודי בן 10 מפגשים, בני שתי יח' אקדמיות, בהם נלמדים יסודות הגרפולוגיה ומעניק לבוגריו כלים פרקטיים בסיסיים לגרפולוגיה שימושית המאפשרת להם לערוך אבחון לעצמם או לאחרים על בסיס יומיומי`
                    : `An interactive, fascinating and unique 10-session course, two
academic units, teaching the basics of graphology; the course
provides its graduates with basic practical tools for applied
graphology, allowing them to diagnose themselves or other
people on a daily basis.`}
                </p>
              </div>
              <div className="course-back-btn-container">
                <button
                  className="course-back-btn"
                  onClick={() => {
                    window.location.href = "/college-graphology-fundementals";
                  }}
                >
                  {Language == 1 ? "לפרטים והרשמה" : "Details and Registration"}
                </button>
              </div>
            </div>
          </BackSide>
        </Flippy>
        <Flippy
          data-aos="fade-up"
          data-aos-delay="100"
          isFlipped={false}
          flipOnHover={true}
          flipOnClick={false}
          flipDirection="horizontal"
        >
          <FrontSide animationDuration={1000}>
            <div className="intuition-container conteiner">
              <div className="course-footer-container">
                <h3
                  className={
                    Language == 1
                      ? "course-footer-title hebrew"
                      : "course-footer-title"
                  }
                >
                  {Language == 1 ? "אינטואיציה" : "Intuition"}
                </h3>
              </div>
            </div>
          </FrontSide>
          <BackSide animationDuration={1000}>
            <div className="intuition-container-back conteiner">
              <div className="course-back-text-container">
                <h3 className="course-back-title">
                  {Language == 1 ? "קורס אינטואיציה" : "Intuition Course"}
                </h3>
                <p
                  id="course-intuition"
                  className={
                    Language == 1
                      ? "course-back-content hebrew"
                      : "course-back-content"
                  }
                >
                  {Language == 1
                    ? `
                    קורס ייחודי ומרתק בן 10 מפגשים של שתי יחידות אקדמיות, בהנחיית אילון בן יוסף, המעניק לבוגריו יכולת לעשות שימוש משמעותי באינטואיציה

                  `
                    : `
                A unique and fascinating 10-session course granting two
                  academic units, under the direct guidance of Eilon Ben Yosef,
                  providing its graduates with the ability to make significant
                  use of their intuition`}
                </p>
              </div>
              <div className="course-back-btn-container">
                <button
                  className="course-back-btn"
                  onClick={() => {
                    window.location.href = "/college-graphology-advanced";
                  }}
                >
                  {Language == 1 ? "לפרטים והרשמה" : "Details and Registration"}
                </button>
              </div>
            </div>
          </BackSide>
        </Flippy>
        <Flippy
          data-aos="fade-up"
          data-aos-delay="200"
          isFlipped={false}
          flipOnHover={true}
          flipOnClick={false}
          flipDirection="horizontal"
        >
          <FrontSide animationDuration={1000}>
            <div className="drawing-container conteiner">
              <div className="course-footer-container">
                <h3
                  className={
                    Language == 1
                      ? "course-footer-title hebrew"
                      : "course-footer-title"
                  }
                >
                  {Language == 1 ? "אבחון ציורים" : "Drawing Analysis"}
                </h3>
              </div>
            </div>
          </FrontSide>
          <BackSide animationDuration={1000}>
            <div className="drawing-container-back conteiner">
              <div className="course-back-text-container">
                <h3 className="course-back-title">
                  {Language == 1
                    ? "קורס אבחון ציורים"
                    : "Drawing Analysis Course"}
                </h3>
                <p
                  id="course-drawing"
                  className={
                    Language == 1
                      ? "course-back-content hebrew"
                      : "course-back-content"
                  }
                >
                  {Language == 1
                    ? `
                    קורס אבחון ציורים מכשיר את תלמידיו לאבחן את האישיות, הכישורים, המצוקות והתקוות. הקורס הוא כלי פרקטי מצוין להורים, למעסיקים, בני זוג וכו' לשימוש בחיי היום יום `
                    : `
                    Just as handwriting is the mirror of the soul, so is drawing.
                  It doesn’t mean professional drawings or highly-invested
                  drawings.In other words, just as letters and signs pave the
                  way to the depths of the soul – so does every drawing,
                  scribble or sketch, whether...
                    `}
                </p>
              </div>
              <div className="course-back-btn-container">
                <button
                  className="course-back-btn"
                  onClick={() => {
                    window.location.href = "/college-drawing-analysis";
                  }}
                >
                  {Language == 1 ? "לפרטים והרשמה" : "Details and Registration"}
                </button>
              </div>
            </div>
          </BackSide>
        </Flippy>
        <Flippy
          data-aos="fade-up"
          data-aos-delay="300"
          isFlipped={false}
          flipOnHover={true}
          flipOnClick={false}
          flipDirection="horizontal"
        >
          <FrontSide animationDuration={1000}>
            <div className="advanced-container conteiner">
              <div className="course-footer-container">
                <h3
                  className={
                    Language == 1
                      ? "course-footer-title hebrew"
                      : "course-footer-title"
                  }
                >
                  {Language == 1 ? "גרפולוגיה מתקדמת" : "Advanced Graphology"}
                </h3>
              </div>
            </div>
          </FrontSide>
          <BackSide animationDuration={1000}>
            <div className="advanced-container-back conteiner">
              <div className="course-back-text-container">
                <h3 className="course-back-title">
                  {Language == 1
                    ? "קורס גרפולוגיה מתקדמת"
                    : "Advanced Graphology Course"}
                </h3>
                <p
                  id="course-advanced"
                  className={
                    Language == 1
                      ? "course-back-content hebrew"
                      : "course-back-content"
                  }
                >
                  {Language == 1
                    ? `קורס עתיר ידע וכלים שימושיים בן 25 מפגשים של שתי יחידות אקדמיות, שבועיים המיועד לתלמידים מעמיקים ,המעוניינים ללמוד באופן יסודי את תורת הכתב ובו בעת להרחיב ברכישת כלים נוספים`
                    : `
                    This course of two weeks, 25 sessions, two academic units,
                    provides extensive knowledge and useful tools. It is intended
                    for in-depth students who are interested in thorough study of
                    graphology while acquiring additional tools.
                  `}
                </p>
              </div>
              <div className="course-back-btn-container">
                <button
                  className="course-back-btn"
                  onClick={() => {
                    window.location.href = "/college-intuition-course";
                  }}
                >
                  {Language == 1 ? "לפרטים והרשמה" : "Details and Registration"}
                </button>
              </div>
            </div>
          </BackSide>
        </Flippy>
      </div>
    </div>
  );
};

export default CollegeOfGraphologyHP;
