import React, { useContext } from "react";
import Context from "../../Context";
import "./ContactUs.css";

import ContactDetails from "../ContactDetails/ContactDetails";
import ContactFooter from "../ContactFooter/ContactFooter";
import ContactForm from "../ContactForm/ContactForm";
import NewHero from "../NewHero/NewHero";

import { isWebpSupported } from "react-image-webp/dist/utils";

import heroBackground from "../../media/img/hero-bg.png";
import heroBackgroundWebp from "../../media/webp/hero-bg.webp";

const ContactUs = () => {
  const Language = useContext(Context);
  return (
    <div className="contact-us-container">
      <NewHero
        engTitle={Language == 1 ? "צרו קשר" : "Contact Me"}
        background={isWebpSupported() ? heroBackgroundWebp : heroBackground}
      />
      <ContactForm />
      <ContactDetails />
      <ContactFooter
        btn1Action={() => {
          window.location.href = "/about";
        }}
        btn2Action={() => {
          window.location.href = "/";
        }}
        title={
          Language == 1 ? "?רוצים לדעת עוד" : "Want to know more about me?"
        }
        btn1={Language == 1 ? "אודות" : "About Me"}
        btn2={Language == 1 ? "לדף הבית" : "Home"}
      />
    </div>
  );
};

export default ContactUs;
