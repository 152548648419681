import "bootstrap/dist/css/bootstrap.min.css";
import MenuProvider from "react-flexible-sliding-menu";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Suspense, useEffect, useState } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import Header from "./components/Header";
import "./media/css/main.css";
import Context from "./Context";
import Footer from "./components/Footer/Footer";
import About from "./pages/about/About";
import ContactUsPage from "./pages/contactuspage/ContactUsPage";
import HomePage from "./pages/HomePage/HomePage";
import GraphologyCollegePage from "./pages/graphologycollege/GraphologyCollegePage";
import GraphologyInstitutePage from "./pages/GraphologyInstitutePage/GraphologyInstitutePage";
import MactubCafe from "./pages/Cafe/MactubCafe";
import LecturesAndShowsPage from "./pages/LecturesAndShowsPage/LecturesAndShowsPage";
import TV from "./pages/TV/TV";
import WrittenFromHeaven from "./pages/WrittenFromHeaven/WrittenFromHeaven";
import FundementalPage from "./pages/CoursesPages/FundementalPage/FundementalPage";
import AdvancedPage from "./pages/CoursesPages/AdvancedPage/AdvancedPage";
import DrawingPage from "./pages/CoursesPages/DrawingPage/DrawingPage";
import IntuitionPage from "./pages/CoursesPages/IntuitionPage/IntuitionPage";
import BurgerMenu from "./components/HamburgerMenu/BurgerMenu";
import PrivateSector from "./pages/GraphologyInstitutePage/PrivateSector/PrivateSector";
import BusinessSector from "./pages/GraphologyInstitutePage/BusinessSector/BusinessSector";
import AboutYou from "./pages/about/AboutYou";
import Reactions from "./pages/Reactions/Reactions";

const LANGUAGE_TEXT = "language";

const App = () => {
  const [Language, setLanguage] = useState(1);
  const [Loaded, setLoaded] = useState(false);

  useEffect(() => {
    Aos.init({ duration: 500 });
    if (localStorage.getItem(LANGUAGE_TEXT) == null) {
      console.log(localStorage.getItem(LANGUAGE_TEXT));
    } else {
      let lang = localStorage.getItem(LANGUAGE_TEXT);
      setLanguage(lang);
    }
  }, []);
  const BurgerMenuLinker = () => {
    return <BurgerMenu setLanguage={setLanguage} />;
  };

  return (
    <>
      <Router>
        <div className="App">
          <Context.Provider value={Language}>
            <MenuProvider width={"88vw"} MenuComponent={BurgerMenuLinker}>
              <Header setLanguage={setLanguage}></Header>
              <Suspense>
                <Switch>
                  <Route exact path="/">
                    <HomePage />
                  </Route>
                  <Route exact path="/institute">
                    <GraphologyInstitutePage />
                  </Route>
                  <Route exact path="/institute-private-audience">
                    <PrivateSector></PrivateSector>
                  </Route>
                  <Route exact path="/institute-business-sector">
                    <BusinessSector></BusinessSector>
                  </Route>

                  <Route exact path="/about">
                    <About></About>
                  </Route>
                  <Route exact path="/about-you">
                    <AboutYou />
                  </Route>
                  <Route exact path="/about-reactions">
                    <Reactions />
                  </Route>
                  <Route exact path="/institute-lectures-and-shows">
                    <LecturesAndShowsPage />
                  </Route>
                  <Route exact path="/college">
                    <GraphologyCollegePage />
                  </Route>
                  <Route exact path="/college-graphology-fundementals">
                    <FundementalPage />
                  </Route>
                  <Route exact path="/college-graphology-advanced">
                    <AdvancedPage />
                  </Route>
                  <Route exact path="/college-drawing-analysis">
                    <DrawingPage />
                  </Route>
                  <Route exact path="/college-intuition-course">
                    <IntuitionPage />
                  </Route>
                  <Route exact path="/projects">
                    <About></About>
                  </Route>
                  <Route exact path="/mactub-cafe">
                    <MactubCafe />
                  </Route>
                  <Route exact path="/mactub-tv">
                    <TV />
                  </Route>
                  <Route exact path="/written-from-heaven">
                    <WrittenFromHeaven></WrittenFromHeaven>
                  </Route>
                  <Route exact path="/contact">
                    <ContactUsPage />
                  </Route>
                </Switch>
              </Suspense>
              <Footer setLoaded={setLoaded} Loaded={Loaded}></Footer>
            </MenuProvider>
          </Context.Provider>
        </div>
      </Router>
    </>
  );
};

export default App;
