import React from "react";
import { useContext } from "react";
import Context from "../../../Context";
import "./WhoIsEilon.css";
import "../../../media/fonts/antipasto/style.css";

import Image from "react-image-webp";
import { isWebpSupported } from "react-image-webp/dist/utils";

import paperTexture from "../../../media/img/paper-wall-texture.jpg";
import eilonImg from "../../../media/img/Reactions/img/img1.jpg";

import paperTextureWebp from "../../../media/webp/paper-wall-texture.webp";
import eilonImgWebp from "../../../media/webp/Reactions/img/img1.webp";

import NormalCarousel from "../../NormalCarousel/NormalCarousel";

const WhoIsEilon = (props) => {
  const Language = useContext(Context);
  return (
    <div
      id="who-is-eilon-container"
      style={{
        backgroundImage: `linear-gradient(rgba(255,255,255,0.9), rgba(255,255,255,0.9)),url(${
          isWebpSupported() ? paperTextureWebp : paperTexture
        })`,
      }}
    >
      {Language == 1 ? (
        <>
          <h3 className="pre-title hebrew">אודות אילון בן יוסף</h3>
          <h2 data-aos="fade-up" className="title hebrew">
            ?אז מי אני
          </h2>
        </>
      ) : (
        <h2 data-aos="fade-up" className="title">
          Who Is Eilon Ben Yosef?
        </h2>
      )}
      <div className="about-p-img-container">
        {props.carousel ? (
          <></>
        ) : (
          <Image
            alt="Eilon Ben Yosef sitting on stairs with a no sleeve open shirt, sunglasses and hands together."
            data-aos="fade-up"
            src={eilonImg}
            webp={eilonImgWebp}
          ></Image>
        )}
        <p
          data-aos="fade-up"
          data-aos-delay="100"
          className="content"
          style={{
            marginLeft: props.carousel ? "5vw" : "0vw",
            marginRight: props.carousel ? "5vw" : "0vw",
          }}
        >
          {Language == 1 ? (
            <div className="hebrew">
              כמתבגר ראיתי שליחות בעזרה לבני נוער ובכל עסקתי. ככל שלמדתי והעמקתי
              הבנתי שהשליחות האמיתית שלי היא לסייע לזולת להיחלץ ממשברים, להסיר
              מהמורות מדרכו, למצוא את מקומו וייעודו ולסייע לו לשפר את איכות חייו
              וחיי סביבתו
              <br />
              מאז אני עסוק מידי יום במשימה שהיצבתי לעצמי ואני מתבונן בגאווה
              ובנות על עשרות אלפי האנשים בהם פגשתי בדרכי המקצועית ואשר הצלחתי
              ולו במעט לסייע להם בהם אמנים, פוליטיקאים, אנשי עסקים לאנשי מפתח
              העשייה הציבורית בארץ ובעולם
            </div>
          ) : (
            <>
              As a teenager, I felt it was just another vocation to assist young
              people. The more I studied and delved deeper into Graphology; I
              realized my true mission was to help others. <br />
              With the aid of Graphology, I can help you get out of crises,
              overcome obstacles, find your place and vocation, improve the
              quality of your lives, and guide them in finding their purpose in
              terms of profession, couple relationship and wellbeing, essence
              and happiness.
            </>
          )}
        </p>
      </div>
      {props.carousel ? (
        <h2
          data-aos="fade-up"
          className={Language == 1 ? "sub-title hebrew" : "sub-title"}
        >
          {Language == 1
            ? "הנה כמה מהאינטראקציות שהיו לי"
            : "Here are some of the interactions I had with"}
          <br></br>
          {Language == 1
            ? "עם אנשי תרבות ובידור"
            : "publicly well known figures"}
        </h2>
      ) : (
        <></>
      )}
      {props.carousel ? <NormalCarousel /> : <></>}
    </div>
  );
};

export default WhoIsEilon;
