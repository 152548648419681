import React, { useEffect, useContext } from "react";
import Context from "../../Context";
import "./style.css";

import engLogo from "../../media/img/english-logo.png";
import hebLogo from "../../media/img/footerhebrew.png";

import engLogoWebp from "../../media/webp/english-logo.webp";
import hebLogoWebp from "../../media/webp/footerhebrew.webp";

import Image from "react-image-webp";

import phoneCallIcon from "../../media/img/footer/phone-call.svg";
import telegramAppIcon from "../../media/img/footer/telegram-app.svg";
import whiteBoardIcon from "../../media/img/footer/white-board.svg";

const Footer = (props) => {
  const Language = useContext(Context);
  useEffect(() => {
    if (!props.Loaded) {
      props.setLoaded(true);
    }
  }, []);
  return (
    <>
      <div className="footer-container">
        <div className={Language == 1 ? "footer hebrew" : "footer"}>
          <div className="footer-logo-container">
            <Image
              alt="Hand writing Eilon Ben Yosef with underline inside circle."
              src={Language == 1 ? hebLogo : engLogo}
              webp={Language == 1 ? hebLogoWebp : engLogoWebp}
              className={Language == 1 ? "hebrew-logo" : "english-logo"}
            />
            <div className="footer-social-icons-container">
              <a href="https://www.facebook.com/eilonby/">
                <i className="fab social fa-facebook"></i>
              </a>
              <a href="https://www.youtube.com/channel/UCJIBEeFEjR_DSvW43wBQmbQ">
                <i className="fab social fa-youtube"></i>
              </a>
              <a href="https://www.instagram.com/eilon.benyosef/">
                <i className="fab social fa-instagram"></i>
              </a>
              <a href="https://il.linkedin.com/in/eilon-ben-yosef">
                <i className="fab social fa-linkedin"></i>
              </a>
              <a href="https://twitter.com/eilonbenyosef">
                <i className="fab social fa-twitter"></i>
              </a>
            </div>
          </div>
          <div className="footer-nav-contact-container">
            <div className="footer-nav-contact-logos-container">
              <div
                className={
                  Language == 1
                    ? "footer-nav-logo-contact-container hebrew"
                    : "footer-nav-logo-contact-container"
                }
              >
                <img
                  className="footer-contact-icon"
                  alt="Phone with arrow out icon."
                  src={phoneCallIcon}
                  onClick={() => {
                    window.location.href = "tel:03-624-5555";
                  }}
                ></img>
                {Language == 1 ? (
                  <p>
                    9:00 - ראשון - חמישי 17:30
                    <br />
                    03-6245555
                    <br />
                    050-900044
                  </p>
                ) : (
                  <p>
                    Sunday - Thursday 9:00 - 17:30 GMT +2 <br />
                    03-6245555
                    <br />
                    050-900044
                  </p>
                )}
              </div>
              <div
                className={
                  Language == 1
                    ? "footer-nav-logo-contact-container hebrew"
                    : "footer-nav-logo-contact-container"
                }
              >
                <img
                  className="footer-contact-icon"
                  alt="Marker drawing on blackboard icon."
                  src={whiteBoardIcon}
                ></img>
                <p>
                  {Language == 1 ? "?יש לכם שאלות" : "Do You Have a Question?"}{" "}
                  <br></br> eilonby@gmail.com
                </p>
              </div>
              <div
                className={
                  Language == 1
                    ? "footer-nav-logo-contact-container hebrew"
                    : "footer-nav-logo-contact-container"
                }
              >
                <img
                  className="footer-contact-icon"
                  alt="Paper airplane icon"
                  src={telegramAppIcon}
                ></img>
                {Language == 1 ? (
                  <p>
                    כתובת <br></br> דרך מנחם בגין 80 <br></br> תל אביב
                  </p>
                ) : (
                  <p>
                    Address <br></br> Derech Menachem Begin 80 <br></br> Tel
                    Aviv
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="footer-nav-lectures-container">
            <ul
              className={
                Language == 1 ? "footer-nav-ul hebrew" : "footer-nav-ul"
              }
            >
              {Language == 1 ? (
                <>
                  <li>
                    <a href="/institute">המכללה לגרפולוגיה</a>
                  </li>
                  <li>
                    <a href="/#hp-projects-container">פרוייקטים</a>
                  </li>
                  <li>
                    <a href="/institute-lectures-and-shows">הרצאות ומופעים</a>
                  </li>

                  <li>
                    <a href="/about-reactions">מדיה ותקשורת</a>
                  </li>
                  <li>
                    <a href="/contact">צרו קשר</a>
                  </li>
                  <li>
                    <a href="/about">אודות</a>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <a href="/institute">Institute Of Graphology</a>
                  </li>
                  <li>
                    <a href="/#hp-projects-container">Projects</a>
                  </li>
                  <li>
                    <a href="/institute-lectures-and-shows">
                      Lectures and Shows
                    </a>
                  </li>

                  <li>
                    <a href="/about-reactions">Media and Press</a>
                  </li>
                  <li>
                    <a href="/contact">Contact Me</a>
                  </li>
                  <li>
                    <a href="/about">About Me</a>
                  </li>
                </>
              )}
            </ul>
          </div>
          <div className="footer-nav-courses-container">
            <ul
              className={
                Language == 1 ? "footer-nav-ul hebrew" : "footer-nav-ul"
              }
            >
              {Language == 1 ? (
                <>
                  <li>
                    <a className="footer-nav-header" href="/college">
                      קורסים
                    </a>
                  </li>
                  <li>
                    <a href="/college-graphology-fundementals">
                      יסודות הגרפולוגיה
                    </a>
                  </li>
                  <li>
                    <a href="/college-drawing-analysis">אבחון ציורים</a>
                  </li>
                  <li>
                    <a href="/college-intuition-course">קורס אינטואיציה</a>
                  </li>
                  <li>
                    <a href="/college-graphology-advanced">גרפולוגיה מתקדמת</a>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <a className="footer-nav-header" href="/college">
                      Courses
                    </a>
                  </li>
                  <li>
                    <a href="/college-graphology-fundementals">
                      Graphology Fundamentals Course
                    </a>
                  </li>
                  <li>
                    <a href="/college-drawing-analysis">
                      Drawing Diagnosis Course
                    </a>
                  </li>
                  <li>
                    <a href="/college-intuition-course">Intuition Course</a>
                  </li>
                  <li>
                    <a href="/college-graphology-advanced">
                      Graphology Advanced Course
                    </a>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
        <div className="sub-footer">
          <p className="sub-footer-text">
            © All rights reserved | Eilon Ben Yosef Created By Somnisis
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
