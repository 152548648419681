export const TextsBank = {
  header: {
    t01: ["MENTORING", "מנטורינג", "MENTORIA"],
    t01c01: ["PRIVATE CLIENTS", "לקוחות פרטיים", "PRIVATE CLIENTES"],
    t01c02: ["WORRIED PARENTS", "הורים מודאגים", "WORRIED PARENTES"],
    t01c03: ["BUSINESS PEOPLE", "אנשי עסקים", "BUSINESSE PEOPLE"],
    t01c04: ["RELATIONSHIP PROBLEMS", "מציאת זוגיות", "RELACIONE PROBLEME"],
    t01c05: [
      "COMPANIES HR GUIDANCE",
      "יעוץ לחברות בנושא כא",
      "COMPANIE HR GUIDANCO",
    ],
    t02: ["ABOUT", "אודות", "SOBRE"],
    t02c01: ["ABOUT ME", "על עצמי", "SOBRE MIM"],
    t02c02: ["EILON IN THE PRESS", "אילון בתקשורת", "EILON DA PRESSA"],
    t02c03: ["REVIEWS AND EXPERIENCES", "ביקורות וחוויות", "REVIEW DA EXP"],
    t03: ["EVENTS", "אירועים", "EVENTOS"],
    t03c01: ["LECTURES", "הרצאות", "PALESTRAS"],
    t03c02: ["SHOWS", "מופעים", "SHOW"],
    t03c03: ["SEMINARS", "סמינרים", "SEMINÁRIOS"],
    t04: ["COURSES", "קורסים", "CURSOS"],
    t04c01: [
      "GRAPHOLOGY FUNDAMENTALS",
      "יסודות הגרפולוגיה",
      "PRIVATE CLIENTES",
    ],
    t04c02: ["GRAPHOLOGY ADVANCED", "גרפולוגיה מתקדמת", "WORRIED PARENTES"],
    t04c03: ["DRAWING ANALYSIS", "אבחון ציורים", "BUSINESSE PEOPLE"],
    t04c04: ["INTUITION COURSE", "קורס אינטואיציה", "RELACIONE PROBLEME"],
    t05: ["PROJECTS", "פרוייקטים", "PROJETOS"],
    t05c01: ["MACTUB CAFÉ", "קפה מכתוב", "MACTUB CAFÉ"],
    t05c02: ["MACTUB TV", "TV מכתוב", "MACTUB TV"],
    t05c03: ["WRITTEN FROM HEAVEN", "כתובה משמיים", "ESCRITO DO CÉU"],
    t06: ["SHOP", "חנות", "LOJA"],
    t07: ["CONTACT", "צור קשר", "CONTATO"],
    t08: ["MEMBERS AREA", "איזור אישי", "Área Pessoal"],
    t09: ["LANGUAGE", "שפה", "LÍNGUA"],
  },
  home: {
    mainTitle: [
      "Institute and College of Graphology",
      "המכון והמכללה לגרפולוגיה",
      "",
    ],
    subTitle: ["by Eilon Ben Yosef", "על שם אילון בן יוסף", ""],
    button: ["SIGN UP NOW!", "!הרשמו עכשיו", "!"],
    features: ["FEATURED IN", "", ""],
  },
  about: {
    aboutTitle: ["How did it all start?", "?איך הכל התחיל", ""],
    aboutP1: [
      `As a schoolboy, I used to speculate on the characters of teachers
            while they were writing on the blackboard. The letters and marks
            they were writing gave me a glimpse into the depths of their
            personalities and mindset. However, I perceived all the information
            revealed to me like a game and nothing more. One fateful day during
            class, one of my classmates was called to the blackboard to answer a
            question, and I immediately knew she had a serious illness. I
            verbalized what I had learned and caused quite a stir as a child
            that day. Regrettably, I was right. That girl was ill and passed
            away prematurely. Naturally, since then, both teachers and friends
            have consulted with me. Yet, I did not consider it a profession, at
            least as long as I had not thoroughly studied the wonders of
            Graphology.`,
      `כילד בבית הספר נהגתי לשער השערות על תכונותיהם של המורים שכתבו על הלוח. מתוך האותיות והסימנים הצצתי לנבכי אישיותם ומה שמתחולל בנפשם ואולם, כל המידע הזה שנגלה לי היה מבחינתי שעשוע ולא יותר, עד לאותו יום שאחת הבנות בכיתה הוזמנה לכתוב על הלוח ואני אבחנתי אצלה מחלה קשה.
            כילד, אמרתי את שראיתי ועוררתי מהומה.
            לצערי, צדקתי.. הילדה אכן היתה חולה והלכה לעולמה בטרם עת.`,
      "",
    ],
    aboutP2: [
      `As a teenager, I felt it was just another vocation to assist young
            people. The more I studied and delved deeper into Graphology; I
            realized my true mission was to help others. With the aid of
            Graphology, I can help you get out of crises, overcome obstacles,
            find your place and vocation, improve the quality of your lives, and
            guide them in finding their purpose in terms of profession, couple
            relationship and wellbeing, essence and happiness.`,
      `מכאן, הפכתי ליועץ המורים והחברים. ואולם, עדיין לא ראיתי בכך מקצוע.. לפחות כל עוד לא למדתי לעומק את נפלאות תורת כתב היד.
            כמתבגר, ראיתי שליחות בעזרה לבני נוער ובכך עסקתי. ככל שלמדתי והעמקתי הבנתי שהשליחות האמיתית שלי היא לסייע לזולת להיחלץ ממשברים, להסיר מהמורות מדרכו, למצוא את מקומו וייעודו, לסייע לאדם לשפר את איכות חייו, וכוונו למצוא את דרכו ואת ייעודו המקצועי, הזוגי, והבריאותי וזאת כדי להביא את הכותב לדרכי חיים שישפרו את מהותו ואת אושרו.`,
      "",
    ],
    aboutP3: [
      `Since my childhood, I have been engaged daily in the task I set for
            myself. And I watch with pride and satisfaction the hundreds of
            thousands of people I’ve met on my professional way and managed,
            even in the slightest, to assist. Among them are numerous artists,
            politicians, business people, kings, presidents, and key figures in
            public activity, both in Israel and worldwide.`,
      `מאז, אני עוסק מדי יום במשימה שהצבתי לעצמי ואני מתבונן בגאווה ובסיפוק על עשרות אלפי האנשים בהם פגשתי בדרכי המקצועית, אשר הצלחתי ולו במעט לסייע להם. בהם מלכים, נשיאים, פוליטיקאים, אנשי עסקים, אמנים, ואנשי מפתח בעשייה הציבורית בארץ ובעולם.`,
      "",
    ],
  },
  services: {
    mentoring: {
      name: "mentoring",
      title: [`Institute Of Graphology`, ``, ``],
      subTitle: [`Diagnoses, Consultation and Guidance`, ``, ``],
      text: [
        [
          `Diagnosis, consultation and guidance to private clients`,
          `Diagnosis, consultation and guidance to concerned parents by deciphering children’s drawings`,
          `Diagnosis, consultation, guidance and escort to business people`,
          `Diagnosis, consultation and guidance to people who wish to be in a relationship`,
          `Diagnosis, consultation and guidance to companies on personnel placement and employment`,
        ],
        [
          `אבחון ייעוץ והכוונה ללקוחות פרטיים`,
          `אבחון ייעוץ והכוונה להורים מוטרדים באמצעות פענוח ציורי ילדים`,
          `אבחון ,ייעוץ הכוונה וליווי לאנשי עסקים`,
          `אבחון ייעוץ והכוונה לאנשים המתקשים למצוא זוגיות`,
          `אבחון ייעוץ והכוונה לחברות בנושא השמת כא והעסקת עובדים`,
        ],
        [
          `Diagnosis, consultation and guidance to private clients`,
          `Diagnosis, consultation and guidance to concerned parents by deciphering children’s drawings`,
          `Diagnosis, consultation, guidance and escort to business people`,
          `Diagnosis, consultation and guidance to people who wish to be in a relationship`,
          `Diagnosis, consultation and guidance to companies on personnel placement and employment`,
        ],
      ],
      action: [``, ``, ``],
    },
    courses: {
      name: "courses",
      title: [`College Of Graphology`, ``, ``],
      subTitle: [`Courses, Workshops and Trainings`, ``, ``],
      text: [``, ``, ``],
      action: [``, ``, ``],
    },
    lectures: {
      name: "lectures",
      title: [`Lectures and Shows`, ``, ``],
      subTitle: [`Lectures in social events, seminars and training`, ``, ``],
      text: [``, ``, ``],
      action: [``, ``, ``],
    },
    writtenFromHeaven: {
      name: "written-from-heaven",
      title: [`Written from Heaven`, ``, ``],
      subTitle: [`A new and unique dating path`, ``, ``],
      text: [
        `A database of singles with the quality standard of those looking for a reliable and stable partner who is built for a relationship – singles whose handwriting has been thoroughly diagnosed by Eilon Ben Yosef.
 
Together with Eilon, a realistic profile is formulated for the purpose of finding a correct and worthy spouse who is individually suitable for your personal lifestyle and who will create a collaborative interaction to hold the relationship together for years to come.
 
Come to meet and learn about your partner who is suitable not only for sexual purposes but also for your destiny and life.`,
        ``,
        ``,
      ],
      action: [``, ``, ``],
    },
    mactubTV: {
      name: "mactub-TV",
      title: [`MactubTV`, `TV מכתוב`, ``],
      subTitle: [`Your new spirit channel`, ``, ``],
      text: [``, ``, ``],
      action: [``, ``, ``],
    },
    matubCafe: {
      name: "matub-cafe",
      title: [`Mactub Café`, ``, ``],
      subTitle: [`Providing personal diagnosis alongside great coffee`, ``, ``],
      text: [``, ``, ``],
      action: [``, ``, ``],
    },
  },
  mentoring: {
    privateClients: {
      title: ['Diagnosis, consultation and guidance to private clients', `אבחון ייעוץ והכוונה ללקוחות פרטיים`, ``],
    },
    worriedParents: {
      title: [`Diagnosis, consultation and guidance to worried parents by deciphering children's drawings`, `אבחון ייעוץ והכוונה להורים מוטרדים באמצעות פענוח ציורי ילדים`, ``]
    },
    businessPeople: {
      title: ['Diagnosis, consultation and guidance to businesspersons', `אבחון ,ייעוץ הכוונה וליווי לאנשי עסקים`, ``]
    },
    relationshipProblems: {
      title: [`Diagnosis, consultation and guidance to people who find it difficult to find a spouse`, `אבחון ייעוץ והכוונה לאנשים המתקשים למצוא זוגיות`, ``]
    },
    companiesHR: {
      title: [`Diagnosis, consultation and guidance to companies on the placement of human resources and employment of employeess`, `אבחון ייעוץ והכוונה לחברות בנושא השמת כא והעסקת עובדים`, ``]
    },
  }
};

export default {
  TextsBank,
};
