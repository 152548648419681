import React, { useContext } from "react";
import Context from "../../Context";
import "./VideoModal.css";

import { Modal } from "react-bootstrap";

import Image from "react-image-webp";

const VideoModal = (props) => {
  const handleClose = () => props.close(-1);
  const Language = useContext(Context);
  if (props.isMobile == false) {
    return (
      <Modal
        style={{ alignItems: "center", pointerEvents: "none" }}
        animation={false}
        show={props.show}
        size="xl"
        fullscreen={true}
        aria-labelledby="contained-modal-title-vcenter"
        onHide={handleClose}
      >
        <Modal.Header>
          <div
            className="modal-title-container"
            onClick={() => props.close(-1)}
          >
            <Modal.Title
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div
                className="video-modal-title"
                onClick={() => props.close(-1)}
              >
                <div className="title-profile-container">
                  <Image
                    className="profile-pic"
                    alt={props.engTitle + "closeup profile picture"}
                    src={props.pic}
                    webp={props.webp}
                  ></Image>
                  <div className="title-profile-text-container">
                    <p
                      className={
                        Language == 1
                          ? "title-profile-name hebrew"
                          : "title-profile-name"
                      }
                    >
                      {props.engTitle}
                    </p>
                    <p
                      className={
                        Language == 1
                          ? "title-profile-desc hebrew"
                          : "title-profile-desc"
                      }
                    >
                      {" "}
                      {props.engDesc}
                    </p>
                  </div>
                </div>
              </div>
              <i
                onClick={() => props.close(-1)}
                className="fas fa-times modal-close-btn"
              ></i>
            </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          <video
            className="normal-size-video"
            autoPlay
            controls
            src={props.src}
          ></video>
        </Modal.Body>
      </Modal>
    );
  } else {
    return (
      <Modal
        style={{ alignItems: "center" }}
        animation={false}
        show={props.show}
        size="xl"
        fullscreen={true}
        aria-labelledby="contained-modal-title-vcenter"
        onHide={handleClose}
      >
        <Modal.Header>
          <div className="modal-title-container">
            <Modal.Title
              style={{
                justifyContent: "space-between",
                width: "100%",
                display: "flex",
              }}
            >
              <div className="video-modal-title">
                {" "}
                <div className="title-profile-container">
                  <Image
                    className="profile-pic-mobile"
                    alt={props.engTitle + "closeup profile picture"}
                    src={props.pic}
                    webp={props.webp}
                  ></Image>
                  <div className="title-profile-text-container-mobile">
                    <p className="title-profile-name-mobile">
                      {props.engTitle}
                    </p>
                    <p className="title-profile-desc-mobile">
                      {" "}
                      {props.engDesc}
                    </p>
                  </div>
                </div>
              </div>
              <i
                onClick={() => props.close(-1)}
                className="fas fa-times modal-close-btn"
              ></i>
            </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          <video
            className="mobile-video"
            autoPlay
            controls
            src={props.src}
          ></video>
        </Modal.Body>
      </Modal>
    );
  }
};

export default VideoModal;
