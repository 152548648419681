import React from "react";
import { useContext } from "react";
import Context from "../../../Context";
import "./GraphologyInstituteContent.css";

import Image from "react-image-webp";

import privateSectorImg from "../../../media/img/InstitutePage/img-private.png";
import businessSectorImg from "../../../media/img/InstitutePage/img-business.png";
import lecturesSectorImg from "../../../media/img/InstitutePage/img-lectures-and-shows.png";

import privateSectorImgWebp from "../../../media/webp/InstitutePage/img-private.webp";
import businessSectorImgWebp from "../../../media/webp/InstitutePage/img-business.webp";
import lecturesSectorImgWebp from "../../../media/webp/InstitutePage/img-lectures-and-shows.webp";

const GraphologyInstituteContent = () => {
  const Language = useContext(Context);
  return (
    <div id="institute-page-container">
      <div className="institute-page-section-container">
        <div
          onClick={() => {
            window.location.href = "/institute-private-audience";
          }}
          data-aos="fade-up"
          className="section-container"
        >
          <h2 className="institute-title">
            {Language == 1 ? "לקוחות פרטיים" : "Private Audience"}
          </h2>
          {Language == 1 ? (
            <p className="institute-content">
              מחפשים את הקריירה הבאה? לא יודעים מה ללמוד? אולי עיצה למערכת
              יחסים? אתם הורים מודאגים שרוצים להבין מה ילדיכם מנסה להגיד? הגעתם
              למקום הנכון
            </p>
          ) : (
            <p className="institute-content">
              Looking for your next career? What do you want to study next?
              Maybe a relationship advice or even a new spouse? Worried parents
              and want to figure out what your child is trying to tell you? This
              is for you!
            </p>
          )}
          <Image
            className="section-image"
            src={privateSectorImg}
            webp={privateSectorImgWebp}
            alt="Eilon Ben Yosef hugging a big black fluffy dog."
          />
        </div>
        <div
          onClick={() => {
            window.location.href = "/institute-business-sector";
          }}
          data-aos="fade-up"
          className="section-container"
        >
          <h2 className="institute-title">
            {Language == 1 ? "לקוחות עסקיים" : "Business Sector"}
          </h2>
          {Language == 1 ? (
            <p className="institute-content">
              האם אתה איש עסקים בתחילת דרכו שמחפש עיצה לעסק? לא יודע איפה
              להשקיע? מחפש לדעת אם הרעיון לסטארט-אפ שיש לך יוכל להצליח בעולם
              העסקים הקשוח? לחץ כאן
            </p>
          ) : (
            <p className="institute-content">
              Are you an aspiring business person looking for a good business
              advice? don’t know where to invest next? want to know if your new
              startup idea can make it in the tough business world? You should
              click here!
            </p>
          )}
          <Image
            className="section-image"
            src={businessSectorImg}
            webp={businessSectorImgWebp}
            alt="Eilon Ben Yosef standing infront of Panorama Hotel next to the hotel manager."
          />
        </div>
        <div
          onClick={() => {
            window.location.href = "/institute-lectures-and-shows";
          }}
          data-aos="fade-up"
          className="section-container"
        >
          <h2 className="institute-title">
            {Language == 1 ? "הרצאות ומופעים" : "Lectures & Shows"}
          </h2>
          {Language == 1 ? (
            <p className="institute-content">
              רוצים לקבוע מופע לאולם חברה? הרצאה לחברים הקרובים? האם תרצו לארח
              את אילון בתוכנית או בפודקאסט? לחצו כאן ומלאו את הטופס
            </p>
          ) : (
            <p className="institute-content">
              Want to book a show for a company venue? A Lecture for your closed
              group? Would you like host Eilon for your show or podcast? <br />
              Click here and fill the request
            </p>
          )}
          <Image
            className="section-image"
            src={lecturesSectorImg}
            webp={lecturesSectorImgWebp}
            alt="Eilon Ben Yosef holding a drawing of a lock on a morning talk show."
          />
        </div>
      </div>
    </div>
  );
};

export default GraphologyInstituteContent;
