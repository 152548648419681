import React, { useContext } from "react";
import Context from "../../Context";
import "./GraphologyCollegePage.css";

import GraphologyCollegeDescription from "../../components/GraphologyCollege/GraphologyCollegeDescription/GraphologyCollegeDescription";
import CollegeOfGraphologyHP from "../../components/HomePage/CollegeOfGraphologyHP/CollegeOfGraphologyHP";
import NewHero from "../../components/NewHero/NewHero";
import ContactFooter from "../../components/ContactFooter/ContactFooter";

import { isWebpSupported } from "react-image-webp/dist/utils";

import heroBackground from "../../media/img/CoursesPage/header-bg.png";
import heroBackgroundWebp from "../../media/webp/CoursesPage/header-bg.webp";

const GraphologyCollegePage = () => {
  const Language = useContext(Context);
  return (
    <div id="graphology-college-page">
      <NewHero
        engTitle={Language == 1 ? "המכללה לגרפולוגיה" : "College of Graphology"}
        background={isWebpSupported() ? heroBackgroundWebp : heroBackground}
      />
      <GraphologyCollegeDescription />
      <CollegeOfGraphologyHP />
      <ContactFooter
        btn1Action={() => {
          window.location.href = "/contact";
        }}
        btn2Action={() => {
          window.href.location = "tel:03-624-5555";
        }}
        title={"Want to learn more about the courses?"}
        btn1={Language == 1 ? "צרו קשר" : "Contact Me"}
        btn2={Language == 1 ? "התקשרו עכשיו" : "Call Now"}
      />
    </div>
  );
};

export default GraphologyCollegePage;
