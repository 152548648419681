import React from "react";
import "./HomePage.css";
import HomePageHero from "../../components/HomePage/HomePageHero/HomePageHero";
import WhoIsEilon from "../../components/HomePage/WhoIsEilon/WhoIsEilon";
import CollegeOfGraphologyHP from "../../components/HomePage/CollegeOfGraphologyHP/CollegeOfGraphologyHP";
import InstituteOfGraphologyHP from "../../components/HomePage/InstituteOfGraphologyHP/InstituteOfGraphologyHP";
import HowItAllStarted from "../../components/HomePage/HowItAllStarted/HowItAllStarted";
import ProjectsHP from "../../components/HomePage/ProjectsHP/ProjectsHP";

const HomePage = () => {
  return (
    <div id="#homepage-container">
      <HomePageHero />
      <WhoIsEilon carousel={true} />
      <CollegeOfGraphologyHP />
      <InstituteOfGraphologyHP />
      <HowItAllStarted />
      <ProjectsHP />
    </div>
  );
};

export default HomePage;
