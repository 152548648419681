import React from "react";
import { useContext } from "react";
import Context from "../../Context";
import "./ContactDetails.css";

const ContactDetails = () => {
  const Language = useContext(Context);
  return (
    <div className="contact-details-container">
      <div data-aos="fade-up" className="contact-details-googlemaps-container">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13524.263495318057!2d34.78566!3d32.067467!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151d4b77830b1321%3A0xd7995d7bb275c686!2sDerech%20Menachem%20Begin%2080%2C%20Tel%20Aviv-Yafo%2C%20Israel!5e0!3m2!1sen!2sus!4v1644348373233!5m2!1sen!2sus"
          height="450"
          allowfullscreen=""
          loading="lazy"
        ></iframe>
      </div>
      {Language == 1 ? (
        <div className="contact-details-info-container hebrew">
          <div className="contact-details-info">
            <div
              data-aos="fade-up"
              data-aos-duration="600"
              className="contact-details-info-part"
            >
              <h3 className="hebrew">המכללה לגרפולוגיה</h3>
              <p className="hebrew">טלפון: 03-6245555 / 050-9000444</p>
              <p className="hebrew">כתובת: דרך מנחם בגין 80, תל אביב</p>
              <p className="hebrew">eilonby@gmail.com :אימייל</p>
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="800"
              className="contact-details-info-part"
            >
              <h3 className="hebrew">שעות פתיחה</h3>
              <p className="hebrew">ראשון-חמישי 9:00-17:30</p>
            </div>
          </div>
          <div className="contact-details-info">
            <div
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="700"
              className="contact-details-info-part"
            >
              <h3 className="hebrew">קפה מכתוב</h3>
              <p className="hebrew">טלפון: 03-6245555</p>
              <p className="hebrew">כתובת: דרך מנחם בגין 80, תל אביב</p>
              <p className="hebrew">eilonby@gmail.com :אימייל</p>
            </div>
            <div className="contact-details-info-part">
              <h3
                data-aos="fade-up"
                data-aos-delay="200"
                data-aos-duration="800"
                className="hebrew"
              >
                מדיה חברתית
              </h3>
              <div className="contact-footer-social-icons-container hebrew">
                <div
                  data-aos="fade-up"
                  data-aos-delay="300"
                  data-aos-duration="800"
                >
                  <a href="https://www.facebook.com/eilonby/">
                    <i className="fab social fa-facebook"></i>
                  </a>
                </div>
                <div
                  data-aos="fade-up"
                  data-aos-delay="400"
                  data-aos-duration="800"
                >
                  <a href="https://www.youtube.com/channel/UCJIBEeFEjR_DSvW43wBQmbQ">
                    <i className="fab social fa-youtube"></i>
                  </a>
                </div>
                <div
                  data-aos="fade-up"
                  data-aos-delay="500"
                  data-aos-duration="800"
                >
                  <a href="https://www.instagram.com/eilon.benyosef/">
                    <i className="fab social fa-instagram"></i>
                  </a>
                </div>
                <div
                  data-aos="fade-up"
                  data-aos-delay="600"
                  data-aos-duration="800"
                >
                  <a href="https://www.facebook.com/eilonby/">
                    <i className="fab social fa-linkedin"></i>
                  </a>
                </div>
                <div
                  data-aos="fade-up"
                  data-aos-delay="700"
                  data-aos-duration="800"
                >
                  <a href="https://twitter.com/eilonbenyosef">
                    <i className="fab social fa-twitter"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="contact-details-info-container">
          <div className="contact-details-info">
            <div
              data-aos="fade-up"
              data-aos-duration="600"
              className="contact-details-info-part"
            >
              <h3>INSTITUTE OF GRAPHOLOGY</h3>
              <p>Phone: 03-6245555 / 050-9000444</p>
              <p>Address: Derech Menachem Begin 80 Tel Aviv</p>
              <p>Email: eilonby@gmail.com</p>
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="800"
              className="contact-details-info-part"
            >
              <h3>OPENING HOURS</h3>
              <p>Sunday-Thursday 9:00-17:30</p>
            </div>
          </div>
          <div className="contact-details-info">
            <div
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="700"
              className="contact-details-info-part"
            >
              <h3>"MACTUB" CAFE</h3>
              <p>Phone: 03-6245555</p>
              <p>Address: Derech Menachem Begin 80 Tel Aviv</p>
              <p>Email: eilonby@gmail.com</p>
            </div>
            <div className="contact-details-info-part">
              <h3
                data-aos="fade-up"
                data-aos-delay="200"
                data-aos-duration="800"
              >
                SOCIAL MEDIA
              </h3>
              <div className="contact-footer-social-icons-container">
                <div
                  data-aos="fade-up"
                  data-aos-delay="300"
                  data-aos-duration="800"
                >
                  <a href="https://www.facebook.com/eilonby/">
                    <i className="fab social fa-facebook"></i>
                  </a>
                </div>
                <div
                  data-aos="fade-up"
                  data-aos-delay="400"
                  data-aos-duration="800"
                >
                  <a href="https://www.youtube.com/channel/UCJIBEeFEjR_DSvW43wBQmbQ">
                    <i className="fab social fa-youtube"></i>
                  </a>
                </div>
                <div
                  data-aos="fade-up"
                  data-aos-delay="500"
                  data-aos-duration="800"
                >
                  <a href="https://www.instagram.com/eilon.benyosef/">
                    <i className="fab social fa-instagram"></i>
                  </a>
                </div>
                <div
                  data-aos="fade-up"
                  data-aos-delay="600"
                  data-aos-duration="800"
                >
                  <a href="https://www.facebook.com/eilonby/">
                    <i className="fab social fa-linkedin"></i>
                  </a>
                </div>
                <div
                  data-aos="fade-up"
                  data-aos-delay="700"
                  data-aos-duration="800"
                >
                  <a href="https://twitter.com/eilonbenyosef">
                    <i className="fab social fa-twitter"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactDetails;
