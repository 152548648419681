import React, { useContext } from "react";
import Context from "../../Context";
import "./MactubCafe.css";

import NewHero from "../../components/NewHero/NewHero";
import CafeAbout from "../../components/MactubCafe/CafeAbout";
import CafeLocation from "../../components/MactubCafe/CafeLocation";
import ContactFooter from "../../components/ContactFooter/ContactFooter";

import { isWebpSupported } from "react-image-webp/dist/utils";

import heroBackground from "../../media/img/Cafe/header-bg.jpg";
import heroBackgroundWebp from "../../media/webp/Cafe/header-bg.webp";

const MactubCafe = () => {
  const Language = useContext(Context);
  return (
    <>
      <section id="cafeSection">
        <NewHero
          engTitle={Language == 1 ? "קפה מכתוב" : "Mactub Café"}
          background={isWebpSupported() ? heroBackgroundWebp : heroBackground}
        />
        <CafeAbout></CafeAbout>
        <CafeLocation></CafeLocation>
      </section>
      <ContactFooter
        btn1Action={() => {
          window.location.href = "/contact";
        }}
        btn2Action={() => {
          window.href.location = "tel:03-624-5555";
        }}
        title={
          Language == 1
            ? "?תרצו לקבוע איתי פגישה"
            : "Want to Schedule a meeting with me?"
        }
        btn1={Language == 1 ? "צרו איתי קשר" : "Contact Me"}
        btn2={Language == 1 ? "התקשרו עכשיו" : "Call Now"}
      />
    </>
  );
};

export default MactubCafe;
