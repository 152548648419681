import React from "react";
import { useContext } from "react";
import "./LecturesAndShowsDescription.css";
import Context from "../../../Context";
const LecturesAndShowsDescription = () => {
  const Language = useContext(Context);
  return (
    <div id="lectures-and-shows-description-container">
      {Language == 1 ? (
        <p className="content hebrew">
          מפגש חווייתי דינאמי ומרתק בן 75 דקות, בהשתתפות קהל בו אילון מקנה
          למשתתפים, על קצה המזלג מידע פרקטי מעולם הגרפולוגיה ואבחון ציורים. זהו
          מפגש אינטראקטיבי, בידורי ומשעשע המתאים לאירועי חברה ולאירועים פרטיים.
          זהו ללא ספק אירוע ייחודי שמשאיר את משתתפיו פעורי פה ובמקביל מצייד אותם
          בכמה כלים בסיסיים פרקטיים לאבחון ומאפשר להם התנסות מעשית
        </p>
      ) : (
        <p className="content">
          An experiential, dynamic, and riveting 75-minute meeting with audience
          participation. Where Eilon Ben Yosef provides participants – in a
          sharp and humorous way – practical information from the world of
          graphology and analysis of drawings, this interactive, fun, and
          amusing encounter is suitable for social events and private events.
          Undoubtedly, this unique jaw-dropping Event leaves its participants
          with several basic tools for diagnosis and enables their practical
          application of graphology.
        </p>
      )}
      <div className="event-includes-container">
        {Language == 1 ? (
          <h2 className="sub-header hebrew">:האירוע יכלול</h2>
        ) : (
          <h2 className="sub-header">The Event Includes:</h2>
        )}
        {Language == 1 ? (
          <ul className="lectures-ul hebrew">
            <li> יסודות הגרפולוגיה</li>
            <li>פענוח בסיסי של ציורים וסמלים</li>
            <li>ניתוח מעשי של כתב ידם של משתתפים מתנדבים מקהל המשתתפים</li>
            <li>סדנה מעשית של אבחון ופיענוח</li>
            <li>ניתוח כתב וחתימות של מנהיגים</li>
          </ul>
        ) : (
          <ul className="lectures-ul">
            <li>Fundamentals of Graphology</li>
            <li>Basic decipherment of drawings and symbols</li>
            <li>
              Practical analysis of handwritings of participants, <br></br>{" "}
              volunteers from the audience
            </li>
            <li>Practical workshop of diagnosis and deciphering</li>
            <li>Analysis of leaders’ handwritings and signatures</li>
          </ul>
        )}
      </div>
    </div>
  );
};

export default LecturesAndShowsDescription;
