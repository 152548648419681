import React from "react";
import { useContext } from "react";
import Context from "../../Context";
import "./Cafe.css";

import Image from "react-image-webp";

import coffeeCup from "../../media/img/Cafe/coffeeCup.svg";
import cafeSign from "../../media/img/Cafe/cafeSign.png";

import cafeSignWebp from "../../media/webp/Cafe/cafeSign.webp";

const CafeAbout = () => {
  const Language = useContext(Context);
  return (
    <div className="cafe-about-container">
      {Language == 1 ? (
        <div className="cafe-about-head hebrew">
          <div className="cafe-text-container">
            <p data-aos="fade-right" className="cafe-about-title hebrew">
              <mark>מכתוב</mark> - המקום שבו הכתוב שלך פוגש את טעם החיים!
            </p>
            <p data-aos="fade-up" className="cafe-about-title hebrew">
              <b>
                קפה בוטיק איכותי ויוקרתי חדש! מבית היוצר של הגרפולוג אילון בן
                יוסף, הממוקם לצידו של המכון והמכללה לגרפולוגיה
              </b>
            </p>
          </div>
          <img data-aos="fade-left" src={coffeeCup} alt=""></img>
        </div>
      ) : (
        <div className="cafe-about-head">
          <div className="cafe-text-container">
            <p data-aos="fade-right" className="cafe-about-title">
              <mark>Mactub</mark> - the place where your text meets the taste of
              life! A new quality and prestigious boutique café!
            </p>
            <p data-aos="fade-up" className="cafe-about-title">
              <b>
                Of the house of Eilon Ben Yosef, located next to the Graphology
                Institute and College.
              </b>
            </p>
          </div>
          <img data-aos="fade-left" src={coffeeCup} alt=""></img>
        </div>
      )}
      {Language == 1 ? (
        <div className="cafe-second-block hebrew">
          <Image
            data-aos="fade-right"
            className="cafe-sign"
            src={cafeSign}
            webp={cafeSignWebp}
            alt="Coffee cup logo drawn on striped paper with maktun coffee house logo on the bottom and Eilon Ben Yosef's name."
          ></Image>
          <div className="second-block-text hebrew">
            <p>
              ?על מה מעידה האות <b>“א ”</b> בכתב ידכם
            </p>
            <p>
              ?מה אומרת האות <b>“נ ”</b> על הקשר שלכם עם אמא
            </p>
            <p>
              ?מה הקשר בין האות <b>“ע ”</b> לבין המיניות שלכם
            </p>
            <p>
              ?איך קשורה האות <b>“פ ”</b> לצד הרגשי שלכם?
            </p>
          </div>
        </div>
      ) : (
        <div className="cafe-second-block">
          <Image
            data-aos="fade-right"
            className="cafe-sign"
            src={cafeSign}
            webp={cafeSignWebp}
            alt="Coffee cup logo drawn on striped paper with maktun coffee house logo on the bottom and Eilon Ben Yosef's name."
          ></Image>
          <div className="second-block-text">
            <p>
              What is indicated by the letter <b>“A”</b> in your handwriting?
            </p>
            <p>
              What is told from the letter <b>“N”</b> about your relationship
              with mom?
            </p>
            <p>
              What is the connection between the letter <b>“G”</b> and your
              sexuality?
            </p>
            <p>
              How is the letter <b>“P”</b> connected to your emotional side?
            </p>
          </div>
        </div>
      )}
      <div data-aos="fade-up" className="cafe-p-container">
        {Language == 1 ? (
          <p className="cafe-about-p hebrew">
            את כל התשובות לשאלות הכי אינטימיות אודותיכם, תוכלו לקבל עם כוס קפה
            ארומטי, לצד מאפים ריחניים ונימוחים ובין שלל קישים מעולים, העשויים
            ממרכיבים איכותיים ,הרצאות וייעוצי בזק ליושבים בבית הקפה "מכתוב"
            ,מציע לכם חוויה ייחודית על חשבון הבית-מגוון כלים שימושיים לאבחון
            גרפולוגי אישי
          </p>
        ) : (
          <p className="cafe-about-p">
            You can receive all the answers to the most intimate questions about
            you at Mactub Café, with a cup of aromatic coffee, fragrant and
            mellow pastries and variety of excellent quiches made of quality
            ingredients, as well as quick lectures and consultations for those
            sitting in “Mactub” Café, where a unique experience, on the house,
            offers you useful tools for personal graphological diagnosis.
          </p>
        )}
      </div>
    </div>
  );
};

export default CafeAbout;
