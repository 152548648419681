import React, { useContext } from "react";
import Context from "../../../Context";
import "./IntuitionPage.css";
import syllabus from "../../../media/img/Courses/intuition/Intuition_course.pdf";

import NewHero from "../../../components/NewHero/NewHero";
import ContactFooter from "../../../components/ContactFooter/ContactFooter";

import Image from "react-image-webp";
import { isWebpSupported } from "react-image-webp/dist/utils";

import heroBackgroundWebp from "../../../media/webp/CoursesPage/header-bg.webp";
import courseImageWebp from "../../../media/webp/HomePage/Intuition.webp";

import heroBackground from "../../../media/img/CoursesPage/header-bg.png";
import courseImage from "../../../media/img/HomePage/Intuition.png";

const IntuitionPage = () => {
  const Language = useContext(Context);
  return (
    <div id="intuition-course-container">
      <NewHero
        engTitle={Language == 1 ? "המכללה לגרפולוגיה" : "College of Graphology"}
        background={isWebpSupported() ? heroBackgroundWebp : heroBackground}
      />
      {Language == 1 ? (
        <h1 data-aos="fade-up" className="course-title hebrew">
          קורס אינטואיציה
        </h1>
      ) : (
        <h1 data-aos="fade-up" className="course-title">
          Intuition course
        </h1>
      )}
      <Image
        data-aos="fade-up"
        className="course-img"
        src={courseImage}
        webp={courseImageWebp}
        alt="Woman with dark hair looking through a string of LED lights covering one eye with her hand."
      />
      {Language == 1 ? (
        <div data-aos="fade-up" className="course-text-container hebrew">
          <p className="course-text padding-bot-s hebrew">
            קורס ייחודי ומרתק בן 10 מפגשים של שתי יחידות אקדמיות, בהנחיית אילון
            בן יוסף, המעניק לבוגריו יכולת לעשות שימוש משמעותי באינטואיציה. כל
            אחד מאתנו ניחן באינטואיציה. אצל חלקינו זהו ערוץ מפותח שאנו עושים בו
            שימוש יומיומי, אחרים נדהמים מידי פעם מאירועים בלתי מוסברים, אחרים
            פועלים רק באופן לוגי מבלי לערבב את מה שמכונה תחושת בטן
          </p>

          <p className="course-text padding-bot-s hebrew">
            קורס אינטואיציה נועד לאנשים אינטואיטיביים ,או לאלו המעוניינים לרכוש
            ארגז כלים פרקטיים לשיפור היכולות האינטואיטיביות ,ללמוד כיצד להשתמש
            בהן בתבונה בחיים היומיומיים בכל זירות החיים
          </p>
          <p className="course-text padding-bot-s hebrew">
            אינטואיציה מפותחת היא חלק בלתי נפרד, מרמת אינטליגנציה רגשית גבוהה
            שהיא הכלי החשוב ביותר לתפקוד נכון בעולם דינאמי ומשתנה
          </p>
          <p className="course-text padding-bot-s hebrew">
            הקורס מקנה לבוגריו בין היתר כלים להקשבה ל"אני" הפנימי המכוון
          </p>
          <ul className="course-ul hebrew">
            <li data-aos="fade-up"> תחושות ולמסרים המועברים מהתודעה</li>
            <li data-aos="fade-up" data-aos-delay="100">
              פיתוח חשיבה יצירתית באמצעות דמיון מודרך והקניית כלים לגירוי הדמיון
            </li>
            <li data-aos="fade-up" data-aos-delay="200">
              פיענוח מסרים המועברים באמצעות חלום
            </li>
            <li data-aos="fade-up" data-aos-delay="300">
              פיענוח מסרים מהיקום
            </li>
            <li data-aos="fade-up" data-aos-delay="400">
              ?גורל או תת מודע? מי מנהל באמת את חיינו
            </li>
            <li data-aos="fade-up" data-aos-delay="500">
              כיצד ניתן להבדיל בין אינטואיציה לחרדה
            </li>
          </ul>

          <p
            data-aos="fade-up"
            data-aos-delay="700"
            className="course-text padding-bot-s hebrew"
          >
            הקורס מתקיים במכללה לגרפולוגיה - אילון בן יוסף
          </p>
        </div>
      ) : (
        <div data-aos="fade-up" className="course-text-container">
          <p className="course-text padding-bot-s">
            A unique and fascinating 10-session course granting two academic
            units, under the direct guidance of Eilon Ben Yosef, providing its
            graduates with the ability to make significant use of their
            intuition.
          </p>

          <p className="course-text padding-bot-s">
            Each of us has been endowed with intuition. For some of us, it is a
            developed channel we use on a daily basis. Others are amazed once in
            a while by unexplained events. Others yet operate only logically
            without mixing it with what is called “gut feeling.”
          </p>
          <p className="course-text padding-bot-s">
            The course is designed for intuitive people or those interested in
            acquiring a practical toolkit to improve their intuitive abilities
            and study how to use them wisely in every day and all areas of life.
            Developed intuition is an integral part of a high level of emotional
            intelligence, which is the most important tool for proper
            functioning in a dynamic and changing world.
          </p>
          <p className="course-text padding-bot-s">
            The course provides its graduates, among other things, with the
            ability to listen to:
          </p>
          <ul className="course-ul">
            <li data-aos="fade-up">To better tune to the inner self</li>
            <li data-aos="fade-up" data-aos-delay="100">
              Feelings and messages conveyed from intuition
            </li>
          </ul>
          <p className="course-text padding-bot-s">It also includes:</p>
          <ul className="course-ul">
            <li data-aos="fade-up" data-aos-delay="200">
              Developing creative thinking by guided imagery and acquiring tools
              for stimulating the imagination
            </li>
            <li data-aos="fade-up" data-aos-delay="300">
              Deciphering messages conveyed by dreams
            </li>
            <li data-aos="fade-up" data-aos-delay="400">
              Deciphering messages from the universe
            </li>
            <li data-aos="fade-up" data-aos-delay="500">
              Fate or subconscious? Who runs our lives?
            </li>
            <li data-aos="fade-up" data-aos-delay="600">
              How can we distinguish between intuition and anxiety?
            </li>
          </ul>
          <p
            data-aos="fade-up"
            data-aos-delay="700"
            className="course-text padding-bot-s"
          >
            The course takes place at the Graphology College – Eilon Ben Yosef
          </p>
        </div>
      )}
      <ContactFooter
        btn1Action={() => {
          window.location.href = "/contact";
        }}
        btn2Action={() => {
          window.location.href = syllabus;
        }}
        title={
          Language == 1
            ? "?תרצו לשמוע עוד על קורס אינטואיציה"
            : "Want to hear more about Intuition Course?"
        }
        btn1={Language == 1 ? "צרו איתי קשר" : "Contact Me"}
        btn2={Language == 1 ? "להורדת הסילבוס" : "Download Syllabus"}
      ></ContactFooter>
    </div>
  );
};

export default IntuitionPage;
