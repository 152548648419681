import Context from "../../../Context";
import React, { useContext } from "react";
import "./DrawingPage.css";

import syllabus from "../../../media/img/Courses/drawing/Drawing_Analysis_course.pdf";

import NewHero from "../../../components/NewHero/NewHero";
import ContactFooter from "../../../components/ContactFooter/ContactFooter";

import Image from "react-image-webp";
import { isWebpSupported } from "react-image-webp/dist/utils";

import heroBackgroundWebp from "../../../media/webp/CoursesPage/header-bg.webp";
import courseImageWebp from "../../../media/webp/HomePage/drawing-analysis.webp";

import heroBackground from "../../../media/img/CoursesPage/header-bg.png";
import courseImage from "../../../media/img/HomePage/drawing-analysis.png";

const DrawingPage = () => {
  const Language = useContext(Context);
  return (
    <div id="drawing-course-container">
      <NewHero
        engTitle={Language == 1 ? "המכללה לגרפולוגיה" : "College of Graphology"}
        background={isWebpSupported() ? heroBackgroundWebp : heroBackground}
      />
      {Language == 1 ? (
        <h1 data-aos="fade-up" className="course-title hebrew">
          קורס אבחון ציורים
        </h1>
      ) : (
        <h1 data-aos="fade-up" className="course-title">
          Drawing Analysis <br /> Course
        </h1>
      )}
      <Image
        data-aos="fade-up"
        className="course-img"
        src={courseImage}
        webp={courseImageWebp}
        alt="Hands drawing eye with black pen on a notebook with a background of wooden table."
      />
      {Language == 1 ? (
        <div data-aos="fade-up" className="course-text-container hebrew">
          <p className="course-text padding-bot-s hebrew">
            כשם שכתב היד הוא ראי הנפש כך גם ציור. אין הכוונה לציור מקצועי או
            מושקע במיוחד. כשם שהאותיות והסימנים מהווים סוללים את הדרך אל נבכי
            הנפש כך גם כל ציור, כל שירבוט או שירטוט בין אם נעשה עי מבוגר ובין אם
            נעשה ע"י ילד
          </p>
          <p className="course-text padding-bot-s hebrew">
            קורס אבחון ציורים מכשיר את תלמידיו לאבחן את האישיות, הכישורים
            ,המצוקות והתקוות. הקורס הוא כלי פרקטי מצוין להורים, למעסיקים, בני
            זוג וכו' לשימוש בחיי היום יום. הקורס עוסק בין היתר
          </p>
          <ul className="course-ul hebrew">
            <li data-aos="fade-up">משמעות שימוש בצבעים בציור</li>
            <li data-aos="fade-up" data-aos-delay="100">
              איתור סימני מצוקה
            </li>
            <li data-aos="fade-up" data-aos-delay="200">
              אבחון יחסי הורים וילדים
            </li>
            <li data-aos="fade-up" data-aos-delay="300">
              מצבים נפשיים בעייתיים
            </li>
            <li data-aos="fade-up" data-aos-delay="400">
              לקויות והפרעות
            </li>
            <li data-aos="fade-up" data-aos-delay="500">
              משמעותם של אלמנטים מרכזיים (בית , נופים ,בני אדם וכו' )
            </li>
            <li data-aos="fade-up" data-aos-delay="600">
              פרופורציות בציור
            </li>
          </ul>
        </div>
      ) : (
        <div data-aos="fade-up" className="course-text-container">
          <p className="course-text padding-bot-s">
            Just as handwriting is the mirror of the soul, so is drawing. It
            doesn’t mean professional drawings or highly-invested drawings.In
            other words, just as letters and signs pave the way to the depths of
            the soul – so does every drawing, scribble or sketch, whether made
            by an adult or a child.
          </p>
          <p className="course-text padding-bot-s">
            The Drawing Analysis course teaches its students to diagnose
            personalities, skills, distresses, and hopes. This course is an
            excellent practical tool for parents, employers, spouses, etc, to be
            used in daily life. The course includes, among other things:
          </p>
          <ul className="course-ul">
            <li data-aos="fade-up">
              The meaning of using colors in the drawing
            </li>
            <li data-aos="fade-up" data-aos-delay="100">
              Detection of distress signs
            </li>
            <li data-aos="fade-up" data-aos-delay="200">
              Diagnosis of parent-child relationships
            </li>
            <li data-aos="fade-up" data-aos-delay="300">
              Problematic mental states
            </li>
            <li data-aos="fade-up" data-aos-delay="400">
              Disabilities and disorders
            </li>
            <li data-aos="fade-up" data-aos-delay="500">
              The meaning of key elements (house, view, people, etc.)
            </li>
          </ul>
        </div>
      )}
      <ContactFooter
        btn1Action={() => {
          window.location.href = "/contact";
        }}
        btn2Action={() => {
          window.location.href = syllabus;
        }}
        title={
          Language == 1
            ? "?תרצו לשמוע עוד על קורס אבחון ציורים"
            : "Want to hear more about Drawing Analysis Course?"
        }
        btn1={Language == 1 ? "צרו איתי קשר" : "Contact Me"}
        btn2={Language == 1 ? "להורדת הסילבוס" : "Download Syllabus"}
      />
    </div>
  );
};

export default DrawingPage;
