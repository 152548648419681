import React from "react";
import { useContext } from "react";
import Context from "../../../Context";
import "./HomePageHero.css";

import Image from "react-image-webp";
import { isWebpSupported } from "react-image-webp/dist/utils";

import heLogoWebp from "../../../media/webp/eilonbenyosef-he.webp";
import newLogoWebp from "../../../media/webp/eilonbenyosef-new.webp";
import heroImgWebp from "../../../media/webp/hero.webp";

import heLogo from "../../../media/img/eilonbenyosef-he.png";
import newLogo from "../../../media/img/eilonbenyosef-new.png";
import heroImg from "../../../media/img/hero.png";

import heroBackground from "../../../media/img/HomePage/hero-bg2.png";
import heroBackgroundWebp from "../../../media/webp/HomePage/hero-bg2.webp";

const HomePageHero = () => {
  const Language = useContext(Context);
  const pageTitle =
    Language == 1 ? (
      <div className="title-logo-container hebrew">
        <h1>
          המכון ומכללה
          <br />
          <span>לגרפולוגיה</span>
        </h1>
        <Image
          data-aos-delay="200"
          alt="Eilon Ben Yosef written in caligraphy in hebrew."
          src={heLogo}
          webp={heLogoWebp}
        ></Image>
        <button
          className="lead-button transition-lead"
          onClick={() => {
            window.location.href = "/institute-lectures-and-shows";
          }}
        >
          <b>לקביעת הרצאה</b>
        </button>
      </div>
    ) : (
      <div className="title-logo-container">
        <h1>
          Graphology
          <br />
          <span>College & Institute</span>
        </h1>
        <Image
          data-aos-delay="200"
          alt="Eilon Ben Yosef written in caligraphy in english."
          src={newLogo}
          webp={newLogoWebp}
        ></Image>
        <button
          className="lead-button transition-lead"
          onClick={() => {
            window.location.href = "/institute-lectures-and-shows";
          }}
        >
          <b>BOOK A LECTURE</b>
        </button>
      </div>
    );
  return (
    <div
      style={{
        backgroundImage: `linear-gradient(rgba(255,255,255,0.6), rgba(255,255,255,0.6)),url(${
          isWebpSupported() ? heroBackgroundWebp : heroBackground
        })`,
      }}
      id="homepage-hero-container"
    >
      {pageTitle}
      <div className="hero-img-container">
        <Image
          alt="Eilon Ben Yosef standing with his hands together in a black buttoned shirt."
          src={heroImg}
          webp={heroImgWebp}
        ></Image>
      </div>
    </div>
  );
};

export default HomePageHero;
