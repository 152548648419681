import React, { useContext } from "react";
import Context from "../../../Context";
import "./PrivateSector.css";

import NewHero from "../../../components/NewHero/NewHero";
import ContactFooter from "../../../components/ContactFooter/ContactFooter";

import Image from "react-image-webp";
import { isWebpSupported } from "react-image-webp/dist/utils";

import heroBackgroundWebp from "../../../media/webp/InstitutePage/private-bg.webp";
import malchikWebp from "../../../media/webp/InstitutePage/malchik.webp";
import writingPenWebp from "../../../media/webp/InstitutePage/writingPen.webp";
import writingPen2Webp from "../../../media/webp/InstitutePage/writingPen.webp";

import heroBackground from "../../../media/img/InstitutePage/private-bg.jpg";
import malchik from "../../../media/img/InstitutePage/malchik.jpg";
import writingPen from "../../../media/img/InstitutePage/writingPen.png";
import writingPen2 from "../../../media/img/InstitutePage/writingPen.jpeg";

const PrivateSector = () => {
  const Language = useContext(Context);
  return (
    <div id="private-sector-container">
      <NewHero
        engTitle={Language == 1 ? "לקוחות פרטיים" : "Private Audience"}
        background={isWebpSupported() ? heroBackgroundWebp : heroBackground}
      />
      <h3 data-aos="fade-up" className="hebrew">
        {Language == 1
          ? "גרפולוגיה ללקוחות פרטיים"
          : "Graphology For Private Audience"}
      </h3>
      <div>
        {Language == 1 ? (
          <h4 data-aos="fade-up" className="hebrew">
            אבחון ייעוץ והכוונה ללקוחות פרטיים
          </h4>
        ) : (
          <h4 data-aos="fade-up">
            Diagnosis, consultation and guidance to private clients
          </h4>
        )}

        {Language == 1 ? (
          <div data-aos="fade-up" className="first-container hebrew">
            <p>
              מפגש פיזי או וירטואלי, אחד על אחד, בן שישים דקות בין אילון ללקוח
              ובו ניתוח מעמיק של כתב היד, יעניק לכם חוות דעת מקיפה הכוללת בין
              היתר ניתוח אישיות מעמיק הכולל תכונות ,כישורים, יכולות והכוונה
              לאפיקים מקצועיים נכונים. ניתוח כישורים חברתיים וייעוץ כיצד ניתן
              לשפר את יכולת האינטראקציה עם אנשים, שותפים, בני משפחה וכו'. פענוח
              של מעצורים וחסימות מודעים או לא, וייעוץ פרקטי להסרתם. ניתוח
              אירועים טראומטיים וייעוץ טיפולי. אבחון מצב בריאותי. עצות פרקטיות
              לשיפור איכות החיים, הבריאות, יחסים ופרנסה
            </p>
            <Image
              alt="Black and white and with cursive letters written on it holding a pen and writing in cursive on a white paper."
              src={writingPen}
              webp={writingPenWebp}
            ></Image>
          </div>
        ) : (
          <div data-aos="fade-up" className="first-container">
            <p>
              A sixty-minute one-on-one physical or virtual session between
              Eilon and the client for an in-depth analysis of the handwriting;
              it will provide you with a comprehensive diagnosis that includes,
              among other things, a thorough personality analysis of
              characteristics, skills, abilities, and guidance to suitable
              professional channels. Analysis of social skills and advice how to
              improve the ability to interact with people, partners, family
              members, etc. Decoding obstructions and blockages, conscious or
              unconscious, and practical advice on removing them. Analysis of
              traumatic events and therapeutic counseling. Diagnosis of health
              condition. Practical tips for improving the quality of life,
              health, relationships and livelihood.
            </p>
            <Image
              alt="Black and white and with cursive letters written on it holding a pen and writing in cursive on a white paper."
              src={writingPen}
              webp={writingPenWebp}
            ></Image>
          </div>
        )}
      </div>
      {Language == 1 ? (
        <div>
          <h4 data-aos="fade-up" className="hebrew">
            אבחון ייעוץ והכוונה להורים מוטרדים באמצעות פענוח ציורי ילדים
          </h4>
          <div
            data-aos="fade-up"
            className="first-container left-container hebrew"
          >
            <p>
              מפגש פיזי או וירטואלי בן שישים דקות עם אילון ובו פענוח ציורים של
              ילדכם וחוות דעת מקיפה הכוללת בין היתר פענוח מצבי מצוקה אצל ילדים
              וייעוץ כיצד לטפל בהם, ניתוח של כישורים ויכולות של הילד, במטרה לטפח
              כישרונות ייחודיים ולטפל בקשיים ניתוח יחסי ההורים והילד ומתן עיצות
              פרקטיות להעצמת הקשר אבחון בריאותי כולל קשיים פיזיים ומנטליים
            </p>
            <Image
              alt="Young boy in a stripped sweater drawing with purple crayon on a canvas"
              src={malchik}
              webp={malchikWebp}
            ></Image>
          </div>
        </div>
      ) : (
        <div>
          <h4 data-aos="fade-up">
            Diagnosis, consultation and guidance to worried parents by
            deciphering children's drawings
          </h4>
          <div data-aos="fade-up" className="first-container left-container">
            <p>
              A sixty-minute physical or virtual session with Eilon for
              deciphering your child’s drawing for a comprehensive opinion
              including, among other things, decoding stress situations in
              children and advising how to handle them. Analysis of the child’s
              skills and abilities, for the purpose of nurturing unique talents
              and handling difficulties. Analyzing the child-parents
              relationship and providing practical advice to empower and
              strengthen the connection. Health diagnosis including physical and
              mental difficulties.
            </p>
            <Image
              alt="Young boy in a stripped sweater drawing with purple crayon on a canvas"
              src={malchik}
              webp={malchikWebp}
            ></Image>
          </div>
        </div>
      )}
      {Language == 1 ? (
        <div>
          <h4 data-aos="fade-up" className="hebrew">
            אבחון ייעוץ והכוונה לאנשים המתקשים למצוא זוגיות
          </h4>
          <div data-aos="fade-up" className="first-container hebrew">
            <p>
              מפגש פיזי או וירטואלי בן שישים דקות עם אילון בין היתר במטרה לאתר
              מוקדי קושי וחסימות בדרך ליצור זוגיות, הכוללים עצות להסרתם, ניתוח
              דפוסי פעולה חוזרים, המחבלים ביכולת למצוא אהבת אמת, כמו כן יצביע
              אילון על לקויים וחוויות מהעבר שהיום רועי לתקיעות או לחוסר שלמות
              ועצות פרקטיות כיצד להתגבר עליהם. ניתוח קווים לדמותו של בן/בת הזוג
              האידיאלי, תיאום ציפיות ויצירת פרופיל מציאותי ורלוונטי של הזוגיות
              וכמוה איך ניצור היכרויות מתאימות
            </p>
            <Image
              alt="Girl hand holding a black pen above a notebook with a list and checkboxes on it."
              src={writingPen2}
              webp={writingPen2Webp}
            ></Image>
          </div>
        </div>
      ) : (
        <div>
          <h4 data-aos="fade-up">
            Diagnosis, consultation and guidance to people who find it difficult
            to find a spouse
          </h4>
          <div data-aos="fade-up" className="first-container">
            <p>
              A sixty-minute physical or virtual session with Eilon in order to
              identify, among other things, the sources of difficulty and
              obstacles in creating spousal relationship, including advice how
              to remove them. Analysis of recurring and repetitive patterns of
              actions, which damage the ability to find true love. Eilon will
              also: Indicate past deficiencies and experiences resulting in
              currently being “stuck” or “incomplete”, and provide practical
              advice how to overcome them. Provide analysis of the ideal
              spouse’s lines of character. Assist in coordination of
              expectations and creation of a realistic and relevant profile of
              the spousal relationship. Advise how to generate suitable dating.
            </p>
            <Image
              alt="Girl hand holding a black pen above a notebook with a list and checkboxes on it."
              src={writingPen2}
              webp={writingPen2Webp}
            ></Image>
          </div>
        </div>
      )}
      <ContactFooter
        btn1Action={() => {
          window.location.href = "/contact";
        }}
        btn2Action={() => {
          window.href.location = "tel:03-624-5555";
        }}
        title={
          Language == 1 ? "?לא יודעים איפה להתחיל" : "DONT KNOW WHERE TO START?"
        }
        btn1={Language == 1 ? "צרו איתי קשר" : "Contact Me"}
        btn2={Language == 1 ? "התקשרו עכשיו" : "Call Now"}
      ></ContactFooter>
    </div>
  );
};

export default PrivateSector;
