import React from "react";
import { useContext } from "react";
import Context from "../../Context";
import "./WrittenFromHeaven.css";

import NewHero from "../../components/NewHero/NewHero";
import ContactFooter from "../../components/ContactFooter/ContactFooter";

import Image from "react-image-webp";
import { isWebpSupported } from "react-image-webp/dist/utils";

import logoWebp from "../../media/webp/WrittenFromHeaven/logo.webp";
import heartWebp from "../../media/webp/WrittenFromHeaven/heart.webp";
import heroBackgroundWebp from "../../media/webp/WrittenFromHeaven/header-bg.webp";

import logo from "../../media/img/WrittenFromHeaven/logo.png";
import heart from "../../media/img/WrittenFromHeaven/heart.png";
import heroBackground from "../../media/img/WrittenFromHeaven/header-bg.png";

const WrittenFromHeaven = () => {
  const Language = useContext(Context);
  return (
    <>
      <section id="written-from-heaven-section">
        <NewHero
          engTitle={Language == 1 ? "כתובה משמיים" : "Written from Heaven"}
          background={isWebpSupported() ? heroBackgroundWebp : heroBackground}
        />
        {Language == 1 ? (
          <h3 data-aos="fade-up" className="written-title">
            <span>
              <mark className="hebrew">כתובה משמיים</mark>
            </span>{" "}
            <span>– מסלול הכרויות מותאם לפנויים ופנויות</span>
          </h3>
        ) : (
          <h3 data-aos="fade-up" className="written-title">
            <span>
              <mark>Written from Heaven</mark>
            </span>{" "}
            <span>– a new and unique dating path adapted for singles</span>
          </h3>
        )}
        {Language == 1 ? (
          <div className="written-about-container hebrew">
            <div
              data-aos="fade-up"
              data-aos-duration="1000"
              className="written-logo hebrew"
            >
              <Image
                alt="Drawing of a red heart with black wings, inside the word Heaven is written and above it says Written From in cursive writing."
                src={logo}
                webp={logoWebp}
              ></Image>
            </div>
            <div className="about-text">
              <h4 data-aos="fade-right" className="about-title-written hebrew">
                חדש ובלעדי למכון והמכללה לגרפולוגיה
              </h4>
              <p
                data-aos="fade-right"
                data-aos-delay="100"
                className="about-p-written hebrew"
              >
                אילון בן יוסף <b>“כתובה משמיים”</b> מסלול ייחודי של הכרויות
                לפנויים ופנויות במכללה
              </p>
              <p
                data-aos="fade-right"
                data-aos-delay="200"
                className="about-p-written hebrew"
              >
                מאגר פנויים פנויות, בתו תקן איכותי של המחפשים פרטנר אמין, יציב
                ובנוי למערכת יחסים
              </p>
              <p
                data-aos="fade-right"
                data-aos-delay="300"
                className="about-p-written hebrew"
              >
                פנויים ופנויות ש<b>כתב ידם אובחן ע״י אילון בן יוסף</b> לעומק
              </p>
              <p
                data-aos="fade-right"
                data-aos-delay="400"
                className="about-p-written hebrew"
              >
                ויחד עם אילון נבנה פרופיל מציאותי וריאלי לבן / בת זוג נכון
                וראוי, המתאים אינדיבידואלי לצורך האישי
              </p>
            </div>
          </div>
        ) : (
          <div className="written-about-container">
            <div className="about-text">
              <h4 data-aos="fade-right" className="about-title-written">
                New and exclusive to the Graphology Institute and College
              </h4>
              <p
                data-aos="fade-right"
                data-aos-delay="100"
                className="about-p-written"
              >
                Eilon Ben Yosef <b>“Written from Heaven”</b> a unique dating
                path for singles at the College.
              </p>
              <p
                data-aos="fade-right"
                data-aos-delay="200"
                className="about-p-written"
              >
                A database of singles with the quality standard of those looking
                for a reliable and stable partner who is built for a
                relationship
              </p>
              <p
                data-aos="fade-right"
                data-aos-delay="300"
                className="about-p-written"
              >
                Singles{" "}
                <b>
                  whose handwriting has been thoroughly diagnosed by Eilon Ben
                  Yosef
                </b>
              </p>
              <p
                data-aos="fade-right"
                data-aos-delay="400"
                className="about-p-written"
              >
                Together with Eilon, a realistic profile is formulated to find
                the right and worthy spouse who is individually suitable for
                your personal needs.
              </p>
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="1000"
              className="written-logo"
            >
              <Image
                alt="Drawing of a red heart with black wings, inside the word Heaven is written and above it says Written From in cursive writing."
                src={logo}
                webp={logoWebp}
              ></Image>
            </div>
          </div>
        )}
        <hr />
        {Language == 1 ? (
          <div className="written-bottom-container hebrew">
            <div className="about-text-bottom hebrew">
              <h4 data-aos="fade-up" className="about-title-written hebrew">
                הצטרפות למאגר מבטיחה מפגשים רלוונטיים, בטוחים ואמינים בלבד
              </h4>
              <p
                data-aos="fade-up"
                data-aos-delay="100"
                className="about-p-written hebrew"
              >
                מה שמבטיח הכרויות מתאימות ובעלות פוטנציאל מימוש
              </p>
              <p
                data-aos="fade-up"
                data-aos-delay="200"
                className="about-p-written hebrew"
              >
                למאגר מתקבלים רק פנויים ופנויות אמינים ורציניים שמחפשים זוגיות
                ואהבת אמת. באמצעות הגרפולוגיה נבחנים המועמדים בשורה של פרמטרים
                הכוללים מרכיבי אישיות, יכולות וכישורים, מצב בריאותי פיזי ונפשי
              </p>
            </div>
            <br />
            <div data-aos="fade-up" className="written-page-section">
              <h3
                style={{ paddingBottom: "1em" }}
                data-aos="fade-right"
                className="written-title hebrew"
              >
                <span>
                  <mark className="hebrew">כתובה משמיים</mark>
                </span>
              </h3>
              <h4
                data-aos="fade-up"
                data-aos-delay="100"
                className="about-title-maktubmishamaim hebrew"
              >
                מופע במה חוויתי ייחודית שהוא מסע אישי אינטראקטיבי לנבכי הנפש
                והתודעה
              </h4>
              <p
                style={{
                  padding: "1.5vw",
                  paddingLeft: "4.8vw",
                  paddingRight: "4.8vw",
                }}
                data-aos="fade-up"
                data-aos-delay="200"
                className="about-p-written hebrew"
              >
                כתב ידינו הוא מראה מדויקת המשקפת נאמנה את המתחולל עמוק בנבכי
                הנפש והתודעה שלנו. האופן בו נכתבות האותיות, עוצמת הכתיבה, מבנה
                השורות ומקומם על הדף, כל אלה יחד רוקמים פסיפס שלם של אישיותנו על
                כל תכונותינו, יכולותינו, מכלול רגשותינו ואף ניתן ללמוד מתוכם על
                טראומות ופחדים המשפיעים על חיינו. גם אופן תפקודינו בחברה כהורים,
                ילדים, בני זוג ואנשי מקצוע מתבטא היטב בין השורות. כתב היד מסגיר
                את סודותינו הכמוסים שלעיתים אפילו לא סיפרנו לעצמנו ומדגיש את
                החלקים הדומיננטיים והפוטנציאל הטמונים בנו
              </p>
              <p
                style={{
                  padding: "1.5vw",
                  paddingLeft: "4.8vw",
                  paddingRight: "4.8vw",
                }}
                data-aos="fade-up"
                data-aos-delay="300"
                className="about-p-written hebrew"
              >
                <b>כתובה משמיים</b> לוקחת את משתתפיה למסע ייחודי, מרגש ומרתק
                לעצמם. מסע מעצים לעבר החוזקות בצד החולשות, ההצלחות כמו גם
                הכישלונות ומאפשרת למשתתפיה להתבונן עמוק לתוך התודעה ותת מודע
                ולזהות תהליכים הדורשים שינוי אל מול התהליכים להם מתבקשת העצמה
              </p>
              <p
                style={{
                  padding: "1.5vw",
                  paddingLeft: "4.8vw",
                  paddingRight: "4.8vw",
                }}
                data-aos="fade-up"
                data-aos-delay="400"
                className="about-p-written hebrew"
              >
                הביטוי התמים שלנו לכאורה על גבי הנייר בשרבוטים לכאורה חסרי
                משמעות או ציורים באופן בלתי מודע או בחתימתנו אף הם מעידים באופן
                ברור על תהליכים רגשיים, יכולות מוטוריות, תכונות אופי ואינטלקט
              </p>
              <p
                style={{
                  padding: "1.5vw",
                  paddingLeft: "4.8vw",
                  paddingRight: "4.8vw",
                }}
                data-aos="fade-up"
                data-aos-delay="400"
                data-aos-duration="600"
                className="about-p-written hebrew"
              >
                <b>כתובה משמיים</b> מציידת את משתתפיה בכלים בסיסיים המאפשרים להם
                להפוך למומחים לרגע ולהתנסות בחווית פיענוח כתב ידם ויתרה מכך
                לזהות דפוסים, התנהגויות, תכונות ויכולות אצל הסובבים אותם
              </p>
              <p
                style={{
                  padding: "1.5vw",
                  paddingLeft: "4.8vw",
                  paddingRight: "4.8vw",
                }}
                data-aos="fade-up"
                data-aos-delay="400"
                data-aos-duration="800"
                className="about-p-written hebrew"
              >
                רגעים מרגשים של הפנמה בצד רגעים של תדהמה חוברים יחד לרגעים
                מרגשים של שמחה וסיפוק בצד כאב ותיסכול.מכלול הרגשות המציף את
                משתתפיה יוצר חוויה חד פעמית ייחודית ששכרה בצידה. זוהי חוויה
                המקנה את היכולת לזהות את המעצורים והגורמים המעכבים וללכת קדימה,
                את היכולת לגשר על פערים, ללמוד להבחין בין טוב לרע ולהתפייס עם
                החלקים הדורשים פיוס
              </p>
              <div className="heart">
                <Image
                  alt="Red wax regal stamp of a red heart shape inside a circle."
                  src={heart}
                  webp={heartWebp}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="written-bottom-container">
            <div className="about-text-bottom">
              <h4 data-aos="fade-up" className="about-title-written">
                Joining the database guarantees relevant, safe and reliable
                encounters only
              </h4>
              <p
                data-aos="fade-up"
                data-aos-delay="100"
                className="about-p-written"
              >
                *Only reliable and serious single persons who are looking for
                spousal relations and true love are accepted to the database.
                With the help of graphology, the candidates are tested by a
                series of parameters that include elements of personality,
                abilities, skills, physical and mental health conditions
              </p>
            </div>
            <br />
            <div data-aos="fade-up" className="written-page-section">
              <h3
                style={{ paddingBottom: "1em" }}
                data-aos="fade-right"
                className="written-title"
              >
                <span>
                  <mark>Written from Heaven</mark>
                </span>{" "}
              </h3>
              <h4
                data-aos="fade-up"
                data-aos-delay="100"
                className="about-title-maktubmishamaim"
              >
                A unique experiential stage show. An interactive personal
                journey into the secrets of one's soul and awareness Our
                handwriting is an exact mirror, genuinely reflecting what is
                going on deep in the depths of our minds and consciousness.
              </h4>
              <p
                style={{
                  padding: "1.5vw",
                  paddingLeft: "4.8vw",
                  paddingRight: "4.8vw",
                }}
                data-aos="fade-up"
                data-aos-delay="200"
                className="about-p-written"
              >
                The way letters are written on a page, the power applied in
                writing, the structure of the lines and their place on the page
                – all create an entire mosaic of our personality, traits,
                characteristics, abilities, and the range of our emotions. They
                also indicate traumas and fears affecting our lives. As parents,
                children, spouses, and professionals, the way we function in
                society is well expressed between the lines. Our handwriting
                reveals hidden secrets we have not even told ourselves and
                highlights dominant parts and their potential.
              </p>
              <p
                style={{
                  padding: "1.5vw",
                  paddingLeft: "4.8vw",
                  paddingRight: "4.8vw",
                }}
                data-aos="fade-up"
                data-aos-delay="300"
                className="about-p-written"
              >
                <b>Written from Heaven</b> takes its participants on a unique,
                exciting, and fascinating journey into their selves; this
                journey clarifies the strengths alongside weaknesses, successes
                and failures, enabling its participants to take a deep look into
                the conscious and subconscious, and identify processes that both
                require a change and need empowerment.
              </p>
              <p
                style={{
                  padding: "1.5vw",
                  paddingLeft: "4.8vw",
                  paddingRight: "4.8vw",
                }}
                data-aos="fade-up"
                data-aos-delay="400"
                className="about-p-written"
              >
                The so-called innocent self-expression on paper by seemingly
                meaningless scribbles or unaware drawings, as well as our
                signature, also indicates – in a very clear way – our emotional
                processes, motoric abilities, characteristics and intellect.
              </p>
              <p
                style={{
                  padding: "1.5vw",
                  paddingLeft: "4.8vw",
                  paddingRight: "4.8vw",
                }}
                data-aos="fade-up"
                data-aos-delay="400"
                data-aos-duration="600"
                className="about-p-written"
              >
                <b>Written from Heaven</b> provides its participants with basic
                tools allowing them to become momentary experts and experience
                the decoding of their handwriting, and even more so – identify
                patterns, behaviors, traits and abilities of people around them.
              </p>
              <p
                style={{
                  padding: "1.5vw",
                  paddingLeft: "4.8vw",
                  paddingRight: "4.8vw",
                }}
                data-aos="fade-up"
                data-aos-delay="400"
                data-aos-duration="800"
                className="about-p-written"
              >
                Exciting moments of internalization alongside moments of awe,
                jointly create moving moments of joy and satisfaction but also
                of pain and frustration. The range of emotions sweeping through
                the participants creates a unique one-time experience, from
                which they can only benefit. This is an experience that provides
                the ability to identify blocks, inhibitions and hurdles, to move
                forward, to bridge over gaps, learn to distinguish between good
                and evil, and make peace with the parts that require
                reconciliation.
              </p>
              <div className="heart">
                <Image
                  src={heart}
                  webp={heartWebp}
                  alt="Red wax regal stamp of a red heart shape inside a circle."
                />
              </div>
            </div>
          </div>
        )}
      </section>
      <ContactFooter
        btn1Action={() => {
          window.location.href = "/contact";
        }}
        btn2Action={() => {
          window.href.location = "tel:03-624-5555";
        }}
        title={
          Language == 1
            ? "?תרצו לקבוע איתי פגישה"
            : "Want to Schedule a meeting with me?"
        }
        btn1={Language == 1 ? "צרו איתי קשר" : "Contact Me"}
        btn2={Language == 1 ? "התקשרו עכשיו" : "Call Now"}
      />
    </>
  );
};

export default WrittenFromHeaven;
